.customOffers {
	
	background: @light_color;
	position: relative;
	float: none;
	clear: both;
	width: 100%;
	
	@media @mobile {
		overflow: hidden;
	}
	
	.offersWrapper {
		padding: 100px 0;
		
		@media @mobile {
			padding: 65px 0;
		}
		
		& > .wrapper {
			position: relative;
			z-index: 2;
			max-width: 954px;

		}
	}
	
	.offersBoxWrap {
		
	}
	
	
	
	.cardList {
		display: flex;
		width: 100%;
		gap: 0 25px;

		@media @mobile {
			padding: 0;
		}
		
		&:before, &:after {
			display: none;
		}

		.splide__track {
			width: 100%;
			overflow: visible;
		}

		.splide__list {
			width: 100%;
			display: flex;
			gap: 0 25px;

			@media @mobile {
				gap: 0;
			}

		}
		
		li, mtc-slide {
			flex: 0 0 33.333333%;
			
			@media @mobile {
				flex: 1 1 301px;
				min-width: 301px;
			}
			
			@media all and (max-width: 430px) {
				flex: 1 1 250px;
				min-width: 250px;
			}
		}
		
		a {
			display: block;
			width: 100%;
			height: auto;
			border-radius: 20px;
			overflow: hidden;
			
			.imageWrap {
				
				position: relative;
				padding-bottom: 100%;
				width: 100%;
				background: transparent;
				overflow: hidden;
				display: block;
				
				img {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%) scale(1);
					transform-origin: center;
					width: 100%;
					height: auto;
					transition: transform 400ms;
					backface-visibility: hidden;
				}
			}
			
			&:hover, &:active {
				.imageWrap {
					img {
						transform: translate(-50%,-50%) scale(1.1);
						transform-origin: center;
						transition: transform 400ms;
					}
				}
			}
		}
	}
	
	.background {
		
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		color: @primary_color;
		z-index: 1;
		pointer-events: none;
		opacity: 0.1;
		overflow: hidden;
		
		@media @mobile {
			display: none;
		}
		
		.backgroundLeft {
			
			width: 495px;
			height: 594px;
			position: absolute;
			left: -70px;
			bottom: 0;
			color: inherit;
			
		}
		
		.backgroundRight {
			width: 694px;
			height: 778px;
			position: absolute;
			right: -136px;
			bottom: 0;
			color: inherit;
		}
		
		svg {
			width: 100%;
			height: 112%;
		}
		
	}
	
}
