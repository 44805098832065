.reviewCarousels {

    padding: 74px 0;
    background: #fff;
    float: none;
    clear: both;

    @media all and (max-width: 1118px) {
        padding: 40px 0;
    }

    @media @mobile {
        padding: @top_bottom_gutter_mobile 0;
    }

    .innerReviews {

        @media all and (max-width: 1118px) {
            display: flex;
            width: ~"calc(100% + 48px)";
            margin: 0 -24px;
        }

        @media all and (max-width: 730px) {
            width: ~"calc(100% + 32px)";
            margin: 0 -16px;
            flex-wrap: wrap;
        }

    }

    .feefoWrap {

        display: flex;
        justify-content: center;
        width: 100%;

        @media all and (max-width: 1118px) {
            padding: 0 24px;
            width: 50%;
            justify-content: flex-end;
        }

        @media all and (max-width: 730px) {
            padding: 0 16px;
            width: 100%;
            justify-content: center;
        }


        & + .trustPilotWrap {
            padding-top: 54px;
            margin-top: 32px;
            border-top: 1px solid @border_color;

            @media all and (max-width: 1118px) {
                margin-top: 0;
                border-top: 0;
                padding: 0 24px;
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }

            @media all and (max-width: 730px) {
                padding: 16px 16px 0 16px;
                width: 100%;
                align-items: flex-start;
                justify-content: center;
            }

            .trustpilot-widget {
                @media all and (max-width: 1118px) {
                    width: auto;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }
            }
        }


        .feefo-review-carousel-widget-service {
            width: auto;
            flex: 0 0 auto;
            max-width: 1150px;

            @media all and (max-width: 730px) {
                max-width: 100%;
            }
        }

        feefowidget-container-main {
            width: 100%;

            .feefowidget-standard {
                width: 100%;
            }
        }

        feefowidget-carousel-summary {
            width: 282px;
            padding: 0 16px !important;

            @media all and (max-width: 1118px) {
                width: auto;
                padding: 0 !important;
            }


            .feefowidget-carousel-summary {
                padding: 0 !important;
                width: 100%;

                @media @mobile {
                    width: 288px !important;
                }
            }
        }

        feefowidget-carousel-body {
            width: ~"calc(100% - 282px)";
            padding: 0 16px !important;
            display: flex;
        }

    }

    .trustPilotWrap {

        display: flex;
        justify-content: center;

        .trustpilot-widget {
            max-width: 1150px;
            width: 100%;
        }

    }

    .feefowidget-carousel-snapshot-body {
        display: flex;
    }


}
