.addressBox {

    padding: 52px 0 0;

    @media @mobile {
        padding: 0;
    }

    .menuGroupTitle.addressBoxTitle {

        .footer & {
            margin: 0 0 24px;

            @media @mobile {
                margin: 0;
            }
        }
    }

    .addressRow {

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 24px;

        .addressTitle {
            .rems(14);
            line-height: 16px;
            color: @primary_color;
            font-weight: normal;
            margin: 0 0 12px;
            width: 100%;
        }


        &:last-of-type {
            margin-bottom: 0;
        }

        a {
            text-decoration: none;
            color: @primary_color;
            transition: color 200ms linear;

            &:hover {
                text-decoration: none;
                color: @highlight_color;
                transition: color 200ms linear;
            }

            &:active {
                text-decoration: none;
                color: darken(@highlight_color, 10%);
                transition: color 200ms linear;
            }
        }


        .addressContent {

            width: 100%;

            .emailLink {

                .fullEmail {
                    display: block;

                }

                .shortLink {
                    display: none;

                }

            }
        }

    }

}
