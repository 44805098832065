.infobox {
    padding: 10px 10px;
    border-radius: 2px;
    border: 1px solid #aaa;
    background: #fff;

    h3 {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 10px;
    }

    p {
        line-height: 1.5;
        margin-bottom: 0;
        font-size: 12px;
    }

    &:after, &:before {
        content: "";
        position: absolute;
        left: 50%;
        margin-left: -5px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 5px 0 5px;
    }

    &:before {
        bottom: -11px;
        border-color: #aaa transparent transparent transparent;
        z-index: 10;
    }

    &:after {
        bottom: -10px;
        border-color: #fff transparent transparent transparent;
        z-index: 20;
    }

    button {
        font-size: 20px;
        position: absolute;
        top: 5px;
        right: 5px;
        background: none;
        box-shadow: none;
        line-height: 20px;
        height: 20px;
        width: 20px;
        text-align: center;
        padding: 0;
        margin: 0;
        color: #aaa;
        border: none;
    }
}

/*
 * directions output
 */

.mapOutputPanel {
    
    > div {
        margin: 0 0 40px;
    }
    
    .adp-placemark, table {
        margin: 0;
        padding: 0;
        background: none;
        border: 0;
        width: 100%;
        height: auto;
        min-width: 1px;
        
        th, td {
            margin: 0;
            padding: 0;
            background: none;
            border: 0;
        }
        
        img {
            margin: 0;
            display: inline;
        }
    }
    
    .adp-placemark {
        background: @light_color;
        
        td {
            .headingStyle(h4);
            width: 42px;
            padding: 10px;
            
            &.adp-text {
                width: 100%;
            }
        }
    }
    
    .adp-summary {
        padding: 10px;
        border-bottom: 1px solid @border_color;
    }
    
    .adp-directions {
        
        td {
            padding: 10px;
            border-bottom: 1px solid @border_color;
        }
    }
}
