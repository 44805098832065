
//Spacing
@usp_banner_padding: 12px 0;
@usp_banner_mobile_padding: 8px 0;



//Font
@usp_banner_font_size: 14;
@usp_banner_line_height: 1.2em;

@usp_banner_icon_font_size: 14;
@usp_banner_icon_line_height: 14px;

//Grid
@usp_gutter_width: 56px;
@usp_gutter_space: @usp_gutter_width / 2;

//Sizing
@usp_banner_height: 40px;
@usp_banner_flyout_height: 29px;
@usp_banner_mobile_height: 29px;

@usp_slide_count: 4; //Match number of li elements
@usp_slide_anim_speed: 5000;

//Icons
@usp_icon_icon_weight: 400;


//Keyframes
.fadeSliderKeyframes(@usp_slide_count);


.uspBanner {

    background: @usp_banner_bg_color;
    color: @usp_banner_color;
    float: none;
    clear: both;
    position: relative;
    height: @usp_banner_height;

	@media @flyout {
		height: @usp_banner_flyout_height;
	}

    @media @mobile {
		height: @usp_banner_mobile_height;
    }

    .wrapper {
		
		height: 100%;
		
        &:before, &:after {
            display: none;
        }
    }

    .uspList {

        float: none;
        clear: both;
        display: flex;
        flex-wrap: nowrap;
        color: inherit;
        width: ~"calc(100% + @{usp_gutter_width})";
        margin: 0 (0 - @usp_gutter_space);
        position: relative;
        padding: @usp_banner_padding;
		height: 100%;

        &:before, &:after {
            display: none;
        }
		

        @media @mobile {
            padding: @usp_banner_mobile_padding;
        }

        & > li {
            flex: 1 1 auto;
            color: @usp_banner_color;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 @usp_gutter_space;
			position: relative;

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 0 0 auto;
                width: auto;
                .rems(@usp_banner_font_size);
                line-height: @usp_banner_line_height;
				font-weight: 600;
				text-transform: none;

                i {
                    .rems(@usp_banner_icon_font_size);
                    line-height: @usp_banner_icon_line_height;
					font-weight: @usp_icon_icon_weight;
					color: @primary_color;
					
                    width: 6px;
                    height: 6px;
					
                    display: @usp_banner_icon_display;
                    align-items: center;
                    justify-content: center;
                    margin-right: 0;
					
					position: absolute;
					left: 100%;
					top: 50%;
					transform: translate(0, -50%);
					margin-left: -3px;
					
					svg {
						width: 100%;
						height: 100%;
					}
					
					@media @flyout {
						display: none;
					}
					
                }
            }
			
			&:last-child {
				
				span {
					i {
						display: none;
					}
				}
			}

            @media @flyout {
                width: 100%;
                height: 100%;
                flex: 1 0 100%;
                white-space: nowrap;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                opacity: 0;
                visibility: hidden;

                //Animations
                .fadeSlider(@usp_slide_count,@usp_slide_anim_speed);


            }

        }


    }

}
