@members_breakpoint: @sidebar_breakpoint;
@members_mobile: ~"all and (max-width: @{members_breakpoint})";

/*
* Public side of the members area
*/
.contentPageWrap.membersWrap.publicMembersArea {

    padding: 0 64px 64px 64px;
    max-width: 700px;
    margin: 0 auto;

    @media @members_mobile {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    @media @mobile {
        padding-left: 0;
        padding-right: 0;
    }

    &.fullWidth {
        max-width: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .innerPageContent {
        max-width: 1080px;
        margin: 0 auto;
        padding-bottom: 0;
        border-bottom: none;

        @media @members_mobile {
            max-width: 100%;
        }

        .pageHeading {
            &:before {
                width: 100%;
            }
        }

        form {
            width: 100%;
        }
    }

}



/*
 * Register / Login
 */
.memberLogin, .memberRegister {
    float: none;
    clear: both;
    margin: 0;


    h4 {
        margin-top: 0;
        margin-bottom: 16px;
        .rems(24);
        line-height: 32px;
    }

    .button {
        min-width: 115px;
    }
}

.memberRegister {
    margin-right: 0;
    float: none;
    clear: both;
    width: 100%;
    position: relative;

    .iconList {
        margin: 0 0 24px;

        li {
            padding-left: 32px;
            margin-bottom: 4px;
            position: relative;

            &:last-child {
                margin-bottom: 0;
            }


            &:before {
                .fa('f00c');
                position: absolute;
                top: 0;
                left: 0;
                color: @primary_color;
                display: flex;
                justify-content: flex-start;
                width: 24px;
            }
        }
    }

    .buttonAlt, .button {
        margin-top: 16px;
    }
}



.registerMember {

    h3 {
        color: @text_color;
        text-align: left;
        .rems(16);
        line-height: 18px;
        margin: 0 0 24px;
        float: none;
        clear: both;
    }



    .chosen-container {
        max-width: none;

        .chosen-search {
            margin: 7px;

            input {
                display: none;
            }

            &:before, &:after {
                display: none;

            }
        }

        &.chosen-container-single-nosearch {
            //display: none;
        }
    }

}

.memberFormWrap {

    fieldset {
        h3 {
            color: @text_color;
            text-align: left;
            .rems(16);
            line-height: 18px;
            margin: 0 0 24px;
            float: none;
            clear: both;
        }
    }
}

.loginSubmitFooter {

    button {
        margin: 0;

        @media @mobile {
            width: 100%;
        }

    }

    .formLink {

        position: relative;
        .rems(12);
        line-height: 14px;

        .formLinkText {
            color: @text_color !important;
        }
    }

}

.memberLogin.sidebarLogin {




}

/*
 * Member Links on welcome screen
 */

.memberLinks {
    .clearfix();

    float: none;
    clear: both;
    max-width: 1000px;

    @media @members_split {
        border-top: 1px solid @border_color;
        padding-top: 24px;
        margin-top: 24px;
        padding-bottom: 16px;
    }


    .memberLink {
        float: none;
        clear: both;
        width: 100%;
        padding: 0;
        margin: 0 auto 24px auto;


        a {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            text-decoration: none;

            &:hover {

                h6 {
                    color: @text_color;
                }

            }

        }


        .titleContainer {

            float: none;
            clear: both;
            width: ~"calc(100% - 260px)";

            @media @mobile {
                width: 100%;
            }


            h6 {
                float: none;
                clear: both;
                padding: 0;
                margin: 0;
                font-weight: 500;
                color: @text_color;

                @media @members_split {
                    text-align: center;
                    padding: 0;
                    padding-top: 16px;
                    margin-bottom: 8px;
                    display: flex;
                }

                @media @mobile {
                    justify-content: center;
                }


                i {
                    .rems(32);
                    line-height: 32px;
                    padding-right: 16px;
                }

                display: flex;
                align-items: center;
            }
        }

        .buttonContainer {

            width: 260px;
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;

            @media @mobile {
                width: 100%;
                justify-content: flex-start;
                padding-top: 16px;
            }

            span {
                .button();
                padding: 12px 16px;
                float: none;
                clear: both;
                width: 100%;
                margin-bottom: 0;
                max-width: 250px;

                @media @mobile {
                    width: 100%;
                    max-width: none;
                }

            }
        }
    }
}

/*
 * Register page
 */

.registerYourAddress, .registerYourDetails {


    float: none;
    clear: both;
    width: 100%;
    margin: 0 0 40px;

    .row {
        float: none;
        clear: both;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        &:before, &:after {
            display: none;
        }


    }
}




.registerYourAddress {
    margin-right: 0;


    .inputWrap {




        input:read-only {
            opacity: 0.5;

            &:focus {
                border-color: @border_color;
            }
        }

        #billingPostcodeSearch {
            opacity: 1 !important;
        }
    }
}

.registrationSubmit {
    clear: both;
    width: 100%;
}



/*
 * Edit Your Details
 */
.detailsGrid {
    display: flex;
    flex-wrap: wrap;
    width: ~"calc(100% + 32px)";
    margin: 0 -16px -24px -16px;
    float: none;
    clear: both;

    @media all and (max-width: 1160px) {
        width: 100%;
        margin: 0;
    }

    &:before, &:after {
        display: none;
    }

    .detailsCol {
        width: 50%;
        padding: 0 16px 24px 16px;
        float: none;
        clear: both;

        @media all and (max-width: 1160px) {
            width: 100%;
            margin-bottom: 24px;
            padding: 0;
            padding-bottom: 16px;
            border-bottom: 1px solid @border_color;

            &:last-of-type {
                margin-bottom: 0;
                border-bottom: none;
                padding-bottom: 0;
            }
        }

        fieldset {
            min-width: 0;
            max-width: 100%;
            width: 100%;

            h6 {
                .rems(24);
                line-height: 32px;
                margin: 0 0 8px;
                font-weight: normal;
            }

            p {
                margin-bottom: 24px;
                .rems(14);
                line-height: 20px;
                opacity: 0.64;
            }
        }
    }

    #ship-state {
        display: none;
    }


    .row {
        .row {
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }


}

.membersFormActions {
    float: none;
    clear: both;
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 8px;
    flex-wrap: wrap;

    &:before, &:after {
        display: none;
    }

    .button {
        float: none;
        clear: both;
        display: flex;
        align-items: center;
        margin: 0;
        min-width: 124px;
        text-align: center;
        justify-content: center;

        @media @mobile {
            width: 100%;
        }

        i {
            @media @mobile {
                margin-left: auto;
            }
        }
    }

    .buttonAlt {

        margin: 0;
        min-width: 124px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        @media @mobile {
            width: 100%;
            margin-top: 16px;
            order: 2;
        }

        & + .button {

            margin-left: auto;

            @media @mobile {
                order: 1;
                margin-left: 0;
            }
        }
    }
}

#changePasswordCustomerDetails {
    margin-right: 0;
}



/*
 * Forgot your password
 */

form.reminder {
    max-width: 600px;

    .button {
        @media @members_split {
            width: 100%;
            max-width: none;
            margin-bottom: 16px;
        }
    }

    .row {
        a:not(.button) {
            @media @members_split {
                padding: 0;
                width: 100%;
                text-align: center;
                display: block;
            }
        }
    }
}



/*
* Log out
*/
.logoutText {
    padding: 80px 0;
    max-width: 640px;
    margin: 0 auto;

    @media @members_mobile {
        padding: 0;
    }

    h4 {
        .rems(24);
        line-height: 32px;
        margin-bottom: 24px;
    }
}


/*
* Reset Password
*/
.resetPasswordWrap {
    max-width: 640px;
    margin: 0 auto;

    @media all and (max-width: 840px) {
        max-width: 100%;
        width: 100%;
    }

    .introBlock {
        text-align: left;
        margin-bottom: 24px;

        @media all and (max-width: 840px) {
            margin-bottom: 16px;
        }
    }

    form.reminder {
        @media all and (max-width: 840px) {
            max-width: 100%;
            width: 100%;
            margin: 0;
        }
    }

    .resetSubmit {
        .button {
            @media all and (max-width: 840px) {
                width: 100%;
                max-width: 100%;
            }
        }
    }
}


/*
* Alerts
*/
.membersAlerts {

    float: none;
    clear: both;
    width: 100%;

    & > div {
        margin-bottom: 24px;
    }

}

/*
* Members layout
*/
.membersAreaWrap {

    .membersAreaContentWrap {

        display: flex;

        @media @members_mobile {
            flex-wrap: wrap;
        }

        .memberAreaMenuWrap {
            width: 15%;
            flex: 0 0 15%;
            padding: 0 0 67px;
            position: relative;
            max-width: 300px;
            min-width: 170px;
            background: @light_color;
            z-index: 10;

            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                right: 100%;
                bottom: 0;
                width: 100vw;
                background: @light_color;
            }


            @media @members_mobile {
                width: 100%;
                flex: 1 0 100%;
                padding: 24px 0;
                max-width: 100%;
                min-width: 100%;
                background: #fff;

                &:before {
                    background: #fff;
                }

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 100%;
                    bottom: 0;
                    width: 100vw;
                    background: #fff;
                }
            }
        }

        .membersAreaContent {
            flex: 1 1;
            width: 85%;
            flex-basis: 85%;
            padding: 0 0 67px 84px;


            @media @members_mobile {
                width: 100%;
                flex-basis: 100%;
                padding: 40px 0;
            }

            @media @mobile {
                padding: 24px 0 40px;
            }

            .introBlock {

                text-align: left;
                margin-bottom: 24px;

                &.dashboardIntro {
                    margin-bottom: 16px;
                }

                .innerIntro {
                    max-width: 100%;
                }

                .introContentWrap {
                    max-width: 100%;
                }

                .introTitle {

                    .rems(25);
                    line-height: 25px;
                    font-weight: normal;
                    letter-spacing: normal;

                    & + .introContentWrap {
                        margin-top: 12px;
                    }

                    &.smallTitle {
                        .rems(24);
                        line-height: 32px;

                        @media all and (max-width: 440px) {
                            .rems(24);
                            line-height: 32px;
                        }
                    }

                }

                .introContentWrap {
                    .rems(12);
                    line-height: 16px;
                    letter-spacing: normal;
                }

                &.membersProgress {
                    .buttonWrap {
                        .button {
                            background: @primary_gradient;
                            color: #ffffff;
                        }
                    }
                }

            }

            .progressTrackingWrap {
                background: #F7F0EE;
                padding: 50px;
                border-radius: 15px;
                overflow: hidden;
                margin: 0 0 75px;
            }

            .nhsMessage {
                padding: 32px 40px;
                background: @primary_color;
                color: #fff;
                margin: 0 0 40px;
                float: none;
                clear: both;
                border-radius: @border_radius;
            }
        }

    }


    /*
    * Previous orders
     */
    .previousOrder {

        .orderBasket {
            margin-bottom: 40px;
            padding: 16px 24px 24px;
            background: @light_color;
            border-radius: @border_radius;

            .bagItemsHeader {
                margin-bottom: 8px;
            }

            .orderItems {
                .rems(14);
                line-height: 16px;
            }

            .multiPriceBlock {
                strong {
                    font-weight: normal !important;
                }
            }

            strong {
                .multiPriceBlock {
                    strong {
                        font-weight: bold !important;
                    }
                }
            }
        }

        #totals {
            border-collapse: collapse;
            margin-top: 16px;

            td {
                border-radius: 0 !important;
                background-color: transparent !important;
            }
        }

    }



    .editDetailsWrap {

        .successMessage {
            margin-bottom: 24px;
        }

    }

    /*
    * Action buttons
     */
    .actionButtons {
        padding: 40px 0 0;
        margin: 40px 0 0;
        border-top: 1px solid @border_color;
        display: flex;
        float: none;
        clear: both;
        width: 100%;

        @media all and (max-width: 500px) {
            padding: 24px 0 0;
            margin: 24px 0 0;
            flex-wrap: wrap;
        }

        .actionsLeft {
            width: 50%;
            flex: 1 1 50%;
            padding-right: 8px;
            text-align: left;

            @media all and (max-width: 500px) {
                width: 100%;
                flex: 0 0 100%;
                padding: 0;
            }


            & + .actionsRight {
                @media all and (max-width: 500px) {
                    margin-top: 16px;
                }
            }

        }

        .actionsRight {
            width: 50%;
            flex: 1 1 50%;
            padding-left: 8px;
            text-align: right;

            @media all and (max-width: 500px) {
                width: 100%;
                flex: 0 0 100%;
                padding: 0;
            }
        }

        a, button {
            min-width: 160px;
            margin: 0;
            vertical-align: middle;

            @media all and (max-width: 500px) {
                max-width: 100%;
                width: 100%;
            }
        }
    }


    /*
    * Dashboard grid
     */
    .membersContentGrid {

        display: flex;
        padding: 0;

        @media all and (max-width: 1140px) {
            padding: 0;
        }

        @media @members_mobile {
            padding: 0;
        }

        @media all and (max-width: 850px) {
            flex-wrap: wrap;
        }

        @media all and (max-width: 560px) {
            padding: 0;
        }

        .contentLeft {
            flex: 1 1;
            width: ~"calc(100% - 271px)";
            flex-basis: ~"calc(100% - 271px)";
            padding-right: 0;

            @media all and (max-width: 1140px) {
                width: "calc(100% - 220px)";
                flex-basis: ~"calc(100% - 220px)";
            }

            @media @members_mobile {
                width: ~"calc(100% - 271px)";
                flex-basis: ~"calc(100% - 271px)";
            }

            @media all and (max-width: 850px) {
                width: 100%;
                flex: 0 0 100%;
                padding: 0;
                margin: 0 0 40px;
            }

        }

        .contentRight {
            width: 271px;
            flex: 0 0 271px;

            @media all and (max-width: 1140px) {
                width: 220px;
                flex: 0 0 220px;
            }

            @media @members_mobile {
                width: 271px;
                flex: 0 0 271px;
            }

            @media all and (max-width: 850px) {
                width: 100%;
                flex: 0 0 100%;
            }
        }
    }


    /*
   * Dashboard Sidebar
   */
    .dashboardSidebar {

        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding-top: 0;

        @media all and (max-width: 1140px) {
            padding-top: 0;
        }

        @media all and (max-width: 850px) {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: ~"calc(100% + 24px)";
            margin: 0 -12px -24px -12px;
            padding-top: 0;

        }

        @media @mobile {
            width: ~"calc(100% + 16px)";
            margin: 0 -8px -16px -8px;
        }

        @media all and (max-width: 420px) {
            width: 100%;
            margin: 0 0 -16px;
        }

        & > li {

            width: 100%;
            margin-bottom: 0;

            @media all and (max-width: 850px) {
                padding: 0 12px;
                width: 50%;
                margin-bottom: 24px;
            }

            @media @mobile {
                padding: 0 8px;
                margin-bottom: 16px;
            }

            @media all and (max-width: 850px) {
                padding: 0;
                width: 100%;
            }

        }
    }

    .sidebarBox {

        margin: 0 0 24px;
        padding: 32px 24px;
        border-radius: @border_radius;
        text-align: center;
        background: #fff;
        color: @text_color;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        @media all and (max-width: 850px) {
            margin: 0;
        }

        @media @mobile {
            padding: 24px 16px;
        }


        .iconWrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 0 16px;
            height: 56px;

            svg {
                height: 100%;
                width: auto;

                @media @mobile {
                    height: 80%;
                }
            }
        }

        h4 {
            color: inherit;
            .rems(16);
            line-height: 18px;
            letter-spacing: 0;
            margin: 0 0 8px;

            @media @mobile {
                .rems(14);
                line-height: 20px;
            }
        }

        p {
            .rems(14);
            line-height: 20px;
            margin: 0;
            letter-spacing: 0;
        }

        .patientBadges {

            color: inherit;
            padding: 16px 0 0;
            display: flex;
            justify-content: center;
            width: 100%;

            @media all and (max-width: 850px) {
                display: none;
            }

            .patientBadge {
                .rems(16);
                line-height: 18px;
                letter-spacing: 0;
                font-weight: normal;
                color: inherit;
                background: @light_color;
                padding: 12px;
                width: 44px;
                height: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                margin: 0 8px;
            }

        }

        .bottomLink {

            color: @secondary_color;
            padding: 24px 0 0;

            @media all and (max-width: 850px) {
                margin-top: auto;
            }

            span {
                text-align: center;
                width: 100%;
                .rems(14);
                line-height: 16px;
                letter-spacing: 0;
                font-weight: 500;
                color: inherit;

                i {
                    padding: 0;
                    margin: 0 0 0 10px;
                    color: inherit;
                }
            }

        }

    }


    .patientCtaBox, .healthcareCtaBox, .nhsCtaBox {

        padding: 24px 24px 32px 24px;
        border: 1px solid @border_color;
        transition: border-color 200ms linear;

        &:hover {

            border-color: @secondary_color;
            transition: border-color 200ms linear;

        }

        &:active {
            border-color: darken(@secondary_color, 10%);
            transition: border-color 200ms linear;
        }
    }

    .ordersCtaBox {

        background: @secondary_color;
        color: #fff;
        transition: background 200ms linear;
        display: none;

        @media all and (max-width: 850px) {
            display: flex;
        }

        .bottomLink {
            color: #fff;
        }

        &:hover {
            background: darken(@secondary_color, 4%);
            transition: background 200ms linear;
        }

        &:active {
            background: darken(@secondary_color, 2%);
            transition: background 200ms linear;
        }

    }

    /*
    * Dashboard Intro Boxes
    */
    .membersWelcome {

        margin: 0 0 80px;
        padding: 16px 0 0;

        @media all and (max-width: 1140px) {
            padding: 0;
            margin: 0 0 40px;
        }

        @media @members_mobile {
            padding: 0;
        }

        @media all and (max-width: 560px) {
            padding: 0;
        }


        .greetingBox {

            padding: 40px;
            background: @primary_color;
            border-radius: @border_radius;
            height: 100%;
            color: #fff;
            min-height: 212px;
            position: relative;
            overflow: hidden;

            @media all and (max-width: 1200px) {
                padding: 40px 24px;
            }

            @media @members_mobile {
                padding: 40px;
            }

            @media all and (max-width: 560px) {
                padding: 40px 24px;
            }

            @media all and (max-width: 450px) {
                min-height: 0;
            }


            .innerGreetingsBox {
                max-width: 100%;
                position: relative;
                z-index: 2;

                @media all and (max-width: 1320px) {
                    width: 100%;
                    max-width: 100%;
                }

                @media all and (max-width: 1100px) {
                    width: 100%;
                    max-width: 281px;
                }

                @media @mobile {
                    max-width: 100%;
                    width: 100%;
                }
            }

            .greetingGraphic {

                position: absolute;
                right: -100px;
                width: 345px;
                height: auto;
                z-index: 1;
                display: block;
                top: 50%;
                transform: translateY(-50%);
                opacity: 0.24;

                @media all and (max-width: 1500px) {
                    right: 24px;
                    bottom: 8px;
                    width: 160px;
                }

                @media all and (max-width: 1320px) {
                    display: none;
                }


                @media @members_mobile {
                    display: block;
                    right: 40px;
                    bottom: -26px;
                    width: 216px;
                }

                @media all and (max-width: 1100px) {
                    right: 9px;
                    bottom: -4px;
                    width: 180px;
                }

                @media all and (max-width: 850px) {
                    display: block;
                    right: 40px;
                    bottom: -26px;
                    width: 216px;
                }

                @media @mobile {
                    display: none;
                }

                img {
                    width: auto;
                    height: 100%;
                }

            }

            .greetingTitle {
                color: inherit;
                .rems(25);
                line-height: 29px;
                font-weight: normal;
                margin: 0 0 8px;
                letter-spacing: 0.01px;
            }

            p {
                .rems(14);
                line-height: 20px;
                margin: 0;
                letter-spacing: 0;
            }

            .buttonWrapper {
                padding: 24px 0 0;

                a {
                    margin: 0 0 -6px;
                    padding: 11px 24px;

                    @media all and (max-width: 350px) {
                        width: 100%;
                    }
                }
            }

        }

        .ordersCtaBox {
            margin: 0;

            display: flex;

            @media all and (max-width: 850px) {
                display: none;
            }
        }

        .contentLeft {
            @media all and (max-width: 850px) {
                margin-bottom: 0;
            }
        }

        .contentRight {
            @media all and (max-width: 850px) {
                display: none;
            }
        }

    }




}


/*
* Chat Area
*/
.newThreadContainer {
    padding: 24px 0 0;

    .button {
        @media all and (max-width: 500px) {
            width: 100%;
        }
    }
}

.emailListWrap {
    padding: 32px 0 0;
    margin: 32px 0 0;
    border-top: 1px solid @border_color;
    float: none;
    clear: both;

    @media @mobile {
        margin: 16px 0 0;
    }

    .membersEmailSection {

        .emailBoxHeader {
            margin-bottom: 24px;

            p {
                opacity: 0.64;
            }
        }

    }
}

.messageListWrap {
    padding: 30px;
    background: #F7F0EE;
    border-radius: 15px;
}

.messageList {
    width: 100%;
    float: none;
    clear: both;

    & > li {

        //border-bottom: 1px solid @border_color;
        display:block;
        width: 100%;
        .rems(16);
        line-height: 24px;
        background: #fff;
        border-radius: 10px;
        padding: 0 20px;
        margin: 0 0 15px;

        @media all and (max-width: 500px) {
            .rems(14);
            line-height: 20px;
        }

        & > span {
            padding: 16px 0;
            display: block;
            width: 100%;
        }

        a {
            text-decoration: none;
            cursor: pointer;
            font-weight: 500;
            position: relative;
            display: block;
            width: 100%;
            padding: 16px 56px 16px 0;
            color: @text_color;
            transition: color 200ms linear;

            .fa, .fal, .far, .fas, i {
                float: none;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                pointer-events: none;
            }

            .unreadCount {
                float: none;
                position: absolute;
                right: 27px;
                top: 50%;
                transform: translateY(-50%);
                pointer-events: none;
                border-radius: 50%;
                background: @secondary_color;
                color: #fff;
                width: 16px;
                height: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 2px;
                .rems(10);
                line-height: 10px;
                transition: background 200ms linear;
            }

            &:hover {
                color: @secondary_color;
                transition: color 200ms linear;
            }

            &:active {
                color: darken(@secondary_color, 2%);
                transition: color 200ms linear;
            }
        }

        //&:first-child {
        //    border-top: 1px solid @border_color;
        //}

        &.notRead {

            & > a {
                color: @secondary_color;

                &:hover {
                    color: darken(@secondary_color, 4%);
                    transition: color 200ms linear;

                    .unreadCount {
                        background: darken(@secondary_color, 4%);
                        transition: background 200ms linear;
                    }
                }

                &:active {
                    color: darken(@secondary_color, 2%);
                    transition: color 200ms linear;

                    .unreadCount {
                        background: darken(@secondary_color, 2%);
                        transition: background 200ms linear;
                    }
                }
            }
        }
    }

    &.smallList {

        & > li {
            .rems(14);
            line-height: 20px;
        }
    }


}

.chatLogContainer {
    border: 1px solid @border_color;
    border-radius: @border_radius;
    overflow: hidden;
    padding: 0;
    width: 100%;
    float: none;
    clear: both;

    h3 {
        color: @primary_color;
        font-size: 24px;
        line-height: 32px;
    }
}

.chatLog {

    float: none;
    clear: both;
    margin: 0 0 25px;

    & > li {

        display: block;
        width: 100%;
        border-bottom: 1px solid @border_color;
        background: @light_color;

        &:nth-child(even) {
            background: #fff;
        }

        .messageBox {
            display: flex;
            width: 100%;
            padding: 24px;

            @media @mobile {
                padding: 16px;
            }

            .boxLeft {
                width: 32px;
                flex: 0 0 32px;
                display: flex;
                align-items: flex-start;
                justify-content: center;

                @media @mobile {
                    width: 40px;
                    flex: 0 0 40px;
                }

                i {
                    .rems(24);
                    line-height: 32px;

                    @media @mobile {
                        .rems(24);
                        line-height: 32px;
                    }
                }
            }

            .boxRight {

                width: ~"calc(100% - 64px)";
                flex: 1 1;
                flex-basis: ~"calc(100% - 64px)";
                padding: 0 0 0 24px;

                @media @mobile {
                    padding: 0 0 0 16px;
                    width: ~"calc(100% - 40px)";
                    flex: 1 1;
                    flex-basis: ~"calc(100% - 40px)";
                }

            }

            .boxHeader {
                display: flex;

                @media all and (max-width: 480px) {
                    flex-wrap: wrap;
                }

                .boxHeaderLeft {
                    width: 80%;
                    flex: 1 1 80%;
                    padding: 0 24px 0 0;

                    @media @mobile {
                        width: ~"calc(100% - 110px)";
                        flex: 1 1;
                        flex-basis: ~"calc(100% - 110px)";
                        padding: 0 16px 0 0;
                    }

                    @media all and (max-width: 480px) {
                        width: 100%;
                        flex: 0 0 100%;
                        padding: 0;
                    }
                }

                .boxHeaderRight {
                    text-align: right;
                    width: 20%;
                    flex: 1 1 20%;

                    @media @mobile {
                        width: 110px;
                        flex: 1 1 110px;
                    }

                    @media all and (max-width: 480px) {
                        width: 100%;
                        flex: 1 1 100%;
                        text-align: left;
                    }
                }

                h4 {
                    margin: 0;
                    .rems(20);
                    line-height: 28px;
                    font-weight: 500;

                    @media @mobile {
                        .rems(18);
                        line-height: 24px;
                    }
                }

                .messageTime {
                    .rems(12);
                    line-height: 28px;
                    opacity: 0.64;

                    @media @mobile {
                        line-height: 24px;
                    }

                    @media all and (max-width: 480px) {
                        line-height: 16px;
                    }
                }
            }

            .boxMessage {
                padding: 0;
                .rems(14);
                line-height: 20px;

                @media @mobile {
                    padding: 0;
                }
            }
        }

        &.admin {

            .messageBox {

                .boxLeft {
                    i {
                        color: @secondary_color;
                    }
                }

                .boxHeader {
                    h4 {
                        color: @secondary_color;
                    }
                }

            }


        }

    }

    .userComments {

        padding: 16px 0 0;

        form {
            display: none;
            width: 100%;
            float: none;
            clear: both;
            padding: 16px 0 0;

            .row {
                margin: 0 0 16px 0;

                &:last-of-type {
                    margin: 0;
                }
            }

            .button, .buttonAlt {
                margin: 0;
            }
        }

    }
}

.chatReplyBox {

    float: none;
    clear: both;
    width: 100%;
    display: block;
    padding: 32px 24px 24px 24px;
    background: #F7F0EE;
    border-radius: 15px;

    h2 {
        font-size: 24px;
        line-height: 32px;
    }

    form {

        textarea {
            resize: none;
            height: 240px;
            color: #878787;
            .rems(14);
            padding: 20px;
            line-height: 15px;
        }

        .actionButtons {
            margin: 0;
            padding: 0;
            border: none;

            .actionsLeft {
                @media all and (max-width: 500px) {
                    order: 2;
                    margin-top: 16px;

                    & + .actionsRight {
                        margin: 0;
                    }
                }


            }

            .actionsRight {
                @media all and (max-width: 500px) {
                    order: 1;
                }
            }
        }

    }
}


/*
* Member Tabs
*/
.ctaTabs.membersTabs {

    background: #fff;
    padding: 0;



    .tabContentWrapper {
        padding: 16px 0 0;
    }

    &.dashboardTabs {

        .tabContentWrapper {
            min-height: 362px;


            @media @members_mobile {
                min-height: 352px;
            }

            @media all and (max-width: 1140px) {
                min-height: 290px;
            }


            @media all and (max-width: 850px) {
                min-height: 200px;
            }
        }

    }

    .orderList.ordersFull {

        & > ul {

            width: ~"calc(100% + 16px)";
            margin: 0 -8px -16px -8px;
            display: flex;
            flex-wrap: wrap;

            & >li {
                padding: 0 8px;
                width: 100%;

                @media all and (max-width: 1080px) {
                    width: 100%;
                }

                @media @members_mobile {
                    width: 50%;
                }

                @media all and (max-width: 720px) {
                    width: 100%;
                }
            }

        }

    }

    .paginationWrap {
        margin-top: 16px;
    }

    .emptyOrders {
        padding: 40px 0;
        text-align: center;
        .rems(16);
        line-height: 24px;
        font-weight: 500;
        opacity: 0.64;
    }


    /*
   *  Dashboard Orders area and nhs cta
   */
    .orderFooterCta {

        text-align: center;
        margin: 0;
        .rems(12);
        line-height: 14px;
        letter-spacing: 0px;
        font-weight: normal;

        .iconWrapper {
            margin-bottom: 13px;
        }

        .fullLink {
            padding: 40px 32px 32px 32px;
            width: 100%;
            height: auto;
            border: 1px dashed @border_color;
            border-radius: @border_radius;
            display: block;

        }
    }


}

.orderList {

    margin-bottom: 40px;

    &> ul {

        margin-bottom: -16px;

        & >li {
            width: 100%;
            margin-bottom: 16px;
        }
    }

    .orderBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        .orderHeader {
            width: 100%;
            flex: 0 0 auto;
            background: @light_color;
            border-radius: @border_radius @border_radius 0 0;
            padding: 12px 24px;

        }

        .innerOrderHeader {
            width: ~"calc(100% + 24px)";
            margin: 0 -12px;
            display: flex;
            flex-wrap: wrap;

            .headerCol {

                padding: 0 12px;

                .headerLabel {
                    display: block;
                    width: 100%;
                    .rems(10);
                    line-height: 12px;
                    font-weight: normal;
                    letter-spacing: 0.01px;
                    opacity: 0.64;
                }

                .headerData {
                    display: block;
                    width: 100%;
                    .rems(12);
                    line-height: 14px;
                    font-weight: normal;
                    letter-spacing: 0;
                }

            }

            .orderDate {
                span {
                    min-width: 90px;
                }
            }

            .orderPostcode {
                flex: 0 0 auto;

                @media all and (max-width: 360px) {
                    display: none;
                }
            }

            .orderNumber {
                text-align: right;
                flex: 1 1 auto;

            }
        }

        .orderBody {
            width: 100%;
            flex: 1 1 auto;
            border: 1px solid @border_color;
            border-radius: 0 0 @border_radius @border_radius;
            padding: 19px;
            background: #fff;

            .orderBodyWrap {

                width: 100%;
                display: flex;


                .orderBodyLeft {
                    width: 74px;

                    & + .orderBodyRight {
                        width: ~"calc(100% - 74px)";
                        padding: 0 0 0 24px;
                    }
                }

                .orderBodyRight {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    padding: 0;
                }

                .imageWrap {
                    width: 74px;
                    height: 74px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        position: absolute;
                        width: 100%;
                        height: auto;
                    }
                }

                .orderContent {

                    .rems(12);
                    line-height: 14px;
                    font-weight: normal;
                    letter-spacing: 0;
                    width: 100%;

                    .orderTitle {
                        .rems(14);
                        line-height: 16px;
                        font-weight: 500;
                        letter-spacing: 0;
                        width: 100%;
                        margin: 0;
                    }

                    .orderTotal {
                        width: 100%;
                        padding: 4px 0 0;
                        opacity: 0.64;
                    }

                    .orderStatus {
                        width: 100%;
                        padding: 4px 0 0;
                        opacity: 0.64;
                    }

                    .reorderWrap {
                        width: 100%;
                        padding: 4px 0 0;
                        font-weight: 500;
                    }

                    .orderList {

                        margin-top: 16px;
                        padding-top: 16px;
                        border-top: 1px solid @border_color;

                        .itemsTitle {
                            .rems(14);
                            line-height: 24px;
                            margin-bottom: 16px;
                        }

                        ol, ul {
                            margin-left: 20px;
                            margin-top: 0;
                            max-width: 90%;
                        }
                    }

                    &.prescriptionContent {

                        .orderTitle:not(.itemsTitle) {
                            margin-bottom: 8px;
                        }

                        .buttonWrapper {

                            padding-top: 24px;
                            border-top: 1px solid @border_color;
                            float: none;
                            clear: both;
                            width: 100%;

                            .button, .buttonAlt {
                                margin: 0;
                            }

                        }
                    }

                }
            }
        }


    }

    &.prescriptionList {

        float: none;
        clear: both;
        display: flex;
        flex-wrap: wrap;
        width: ~"calc(100% + 24px)";
        margin: 0 -12px;

        &:before, &:after {
            display: none;
        }

        .orderBox {
            height: 100%;
            display: flex;
            flex-direction: column;

            .orderBody {
                flex: 1 1 auto;

                .orderBodyWrap {
                    height: 100%;

                    .orderBodyRight {
                        height: 100%;

                        .orderContent {
                            height: 100%;
                            display: flex;
                            flex-direction: column;

                            .buttonWrapper {
                                margin-top: auto;
                            }
                        }
                    }
                }
            }
        }

        & > li {
            width: 50%;
            padding: 0 12px;
            margin: 0 0 24px;

            @media @tablet, @members_mobile {
                width: 100%;
            }
        }
    }

}


/*
* Intro
*/
.membersIntro {

    .introContentWrap {
        opacity: 0.64;
        .rems(14) !important;
        line-height: 24px !important;
    }

}


.healthProfileWrap {
    & > .successMessage, & > .errorMessage {
        display: block;
        float: none;
        clear: both;
        width: 100%;
        margin-bottom: 32px;
    }

    .gpSearchWrap {
        float: none;
        clear: both;
        width: 100%;

        & + div {
            float: none;
            clear: both;
            width: 100%;
        }
    }
}

/*
* NHS intro
 */
.nhsRegistrationIntro {

    position: relative;
    float: none;
    clear: both;
    max-width: 780px;
    margin: 0 auto;


    @media all and (max-width: 1300px) {
        max-width: 100%;
    }

    @media all and (max-width: 1200px) {
        max-width: 566px;
    }

    .introBlock {

        margin: 0 0 124px;

        @media all and (max-width: 1300px) {
            margin: 0 0 80px;
        }

        @media @mobile {
            margin: 0 0 40px;
        }

        .introTitle {
            .rems(35);
            line-height: 40px;

            @media @mobile {
                .rems(24);
                line-height: 32px;
            }
        }


    }

    .nhsGridWrap {
        position: relative;
        width: 100%;
        float: none;
        clear: both;
    }

    .nhsIconGrid {

        display: flex;
        justify-content: space-between;
        width: ~"calc(100% + 48px)";
        margin: 0 -24px;


        @media all and (max-width: 1300px) {
            width: ~"calc(100% + 24px)";
            margin: 0 -12px;
        }

        @media all and (max-width: 1200px) {
            min-height: 214px;
            opacity: 0;
            transition: opacity 200ms linear;
        }

        &.slick-initialized {
            opacity: 1 !important;
            transition: opacity 200ms linear;
        }

        .iconWrapper {

            padding: 0 24px;
            display: flex;
            flex-direction: column;
            align-items: center;

            @media all and (max-width: 1300px) {
                padding: 0 12px;
            }

            @media all and (max-width: 1200px) {
                display: none;
            }

            &.slick-slide {
                @media all and (max-width: 1200px) {
                    display: flex;
                }
            }

            .iconBox {
                width: 100%;
                margin: 0 0 48px;
                flex: 1 0 auto;
                display: flex;
                align-items: flex-end;
                justify-content: center;

                svg {
                    flex: 1 1 100%;
                    width: auto;
                    height: auto;
                    max-width: 156px;
                    max-height: 88px;
                }
            }

            .iconTitle {
                flex: 0 0 auto;
                color: @text_color;
                width: 100%;
                text-align: center;
                .rems(14);
                line-height: 24px;
            }

        }

    }

    .nhsSectionCta {
        padding: 106px 0 0;
        max-width: 434px;
        margin: 0 auto;
        text-align: center;

        @media all and (max-width: 1300px) {
            padding: 64px 0 0;
        }

        @media all and (max-width: 1200px) {
            padding: 40px 0 0;
        }

        p {
            margin: 0;
            .rems(16);
            line-height: 24px;
            color: @text_color;
        }

        .buttonWrapper {
            margin-top: 24px;
            text-align: center;
            justify-content: center;

            .button, .buttonAlt {
                margin: 0;
            }
        }
    }

}


.ordersPaymentAction {
    padding: 32px 0;
    margin-bottom: 40px;
    border-bottom: 1px solid @border_color;
    text-align: center;
    background: @light_color;
    margin-top: -16px;

    @media @members_mobile {
        margin-top: 0;
        border-top: 1px solid @border_color;
    }

    .buttonWrapper {
        text-align: center;

        .button, .buttonAlt {
            margin: 0;
        }
    }
}


.doctorMembersSection {
    padding-top: 32px;
    margin-top: 40px;
    border-top: 1px solid @border_color;
    float: none;
    clear: both;
}

.healthProfileFieldWrap {
    float: none;
    clear: both;

    & > .row {
        float: none;
        clear: both;
        width: 100%;
    }
}


/*
* Recent orders
*/

.membersContentWrap {

	.recentOrders {

		& > li {
			margin: 0 0 24px;
		}

        .orderSummaryWrap {
            width: 100%;
            background: #F7F0EE;
            border-radius: 15px;
            height: 100%;
            padding: 50px;
            margin-right: unset;
            margin: 0 0 25px;

            .orderHeader {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                gap: 37px;
                margin: 0 0 35px;

                .headTitle {
                    display: block;
                    .rems(10);
                    line-height: 12px;
                    margin: 0 0 10px;
                }

                .headValue {
                    display: block;
                    .rems(18);
                    line-height: 25px;
                    color: @primary_color;
                }
            }

            .orderBody {
                display: flex;
                flex-flow: column nowrap;
                gap: 15px;
            }

            .orderButton {
                flex: 1 0;
                text-align: right;

                .button {
                    margin: 0;
                }
            }

            @media all and (max-width: 680px) {
                padding: 30px 25px;

                .orderHeader {
                    flex-flow: row wrap;
                    justify-content: space-between;
                    gap: 20px;
                }

                .orderButton {
                    flex: 1 0 100%;

                    .button {
                        width: 100%;
                    }
                }
            }

            .orderItem {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                gap: 25px;
                background: rgba(255, 255, 255, 0.35);
                padding: 0 36px 0 0;

                .imageWrap {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 10px;
                    background: #ffffff;
                    aspect-ratio: 1 / 1;
                    height: 101px;

                    > img {
                        max-height: 65px;
                        max-width: 65px;
                        height: auto;
                        width: auto;
                    }
                }

                .itemDetails {
                    flex: 1 0;
                    .rems(12);
                    line-height: 14px;
                    display: flex;
                    flex-flow: column nowrap;
                    gap: 12px;
                }

                .itemPrice {
                    flex: 0 0;
                    text-align: right;

                    .itemQty {
                        display: block;
                        .rems(10);
                        line-height: 12px;
                        letter-spacing: 0.2px;
                        margin: 0 0 10px;
                    }

                    .multiPrice {
                        .rems(14);
                        line-height: 18px;
                    }
                }

                @media all and (max-width: 680px) {
                    flex-flow: column nowrap;
                    padding: 0;
                    text-align: center;

                    .imageWrap {
                        width: 100%;
                        height: unset;

                        > img {
                            max-width: 200px;
                            max-height: 200px;
                        }
                    }

                    .itemDetails {
                        padding: 0 20px;
                    }

                    .itemPrice {
                        padding: 0 20px 20px;
                    }
                }
            }
        }

		.innerorderWrap {

			border-radius: @border_radius;
			overflow: hidden;
			width: 100%;
			display: block;
			border: 1px solid @border_color;

			.row {
				float: none;
				clear: both;
				width: 100%;
				margin: 0 0 8px;
			}

			.orderLabel {
				font-weight: 600;
			}

			.orderHeader {
				background: @light_color;
				border-bottom: 1px solid @border_color;
				padding: 16px;

				.orderTitle {
					margin: 0;
					width: 100%;
					display: flex;
					.rems(16);
					line-height: 24px;
					font-weight: 600;

					@media @mobile {
						flex-wrap: wrap;
					}

					.orderID {
						width: 70%;
						text-align: left;

						@media @mobile {
							width: 100%;
						}
					}

					.orderRef {
						width: 30%;
						text-align: right;

						@media @mobile {
							width: 100%;
							text-align: left;
							margin-top: 4px;
						}
					}
				}

			}

			.orderContent {
				padding: 16px;

				.orderListRow, .subscriptionsArea {

					.orderLabel {
						display: block;
						width: 100%;
						margin: 0 0 8px;
					}

					.orderList, .subscriptionList {
						margin-bottom: 8px;
						padding-bottom: 0;

					}

				}

			}

			.orderFooter {
				padding: 16px;
				border-top: 1px solid @border_color;

				.buttonWrapper {
					margin-bottom: -22px;

					.button {
						margin-bottom: 16px;
						margin-right: 16px;
						.rems(16);
						line-height: 28px;
						padding: 14px 24px;

						@media @mobile {
							width: 100%;
						}

						&:last-of-type {
							margin-right: 0;
						}
					}
				}
			}

		}

	}

}


.membersDashboardGrid {

	display: flex;
	width: ~"calc(100% + 16px)";
	margin: 0 -8px;
	flex-wrap: wrap;
	padding-top: 24px;
	padding-bottom: 48px;

	.dashBoardBox {

		display: block;
		width: 100%;
		padding: 0 8px;
		max-width: 33.333333%;
		margin: 0 0 16px;


		@media all and (max-width: 1111px) {
			max-width: 50%;
		}

		@media all and (max-width: 1000px) {
			max-width: 33.333333%;
		}

		@media all and (max-width: 750px) {
			max-width: 50%;
		}

		@media all and (max-width: 380px) {
			max-width: 100%;
		}

		& > a {
			display: block;
			position: relative;
			padding: 24px;
			border: 2px solid @border_color;
			border-radius: @border_radius;
			text-align: center;
			transition: all 200ms linear;
			height: 100%;

			&:hover, &:focus {
				border-color: @primary_color;
				transition: all 200ms linear;
			}

			.cardHeading {

				width: 100%;
				display: flex;
				justify-content: center;

				.iconWrapper {
					width: 40px;
					height: 40px;
					margin: 0 auto;
					position: relative;

					@media all and (max-width: 500px) {
						width: 40px;
						height: 40px;
					}

					svg {
						width: 100%;
						height: 100%;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
				}
			}

			& > h4 {
				width: 100%;
				margin: 16px 0 0;
				.rems(16);
				line-height: 24px;
				text-align: center;
				font-weight: 600;
			}


		}

	}

}

.checkupNoticeWrap {
	margin: 0 0 40px;
}

.logListNavWrap {
	background: @tertiary_gradient;
	padding: 20px;
	border-radius: 10px;
	margin: 0 0 20px;

	.logListNav {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		gap: 12px;
		margin: 0;
		padding: 0;

		&:before, &:after {
			display: none;
		}

		.button {
			margin: 0;
		}
	}

}
