.tabs {

    float: none;
    clear: both;
    margin: 0;

    .tabsNav {

        float: none;
        clear: both;
        width: 100%;
        padding: 0 24px 40px 24px;
        border: none;

        @media all and (max-width: 800px) {
            padding: 0 0 24px 0;
        }

        & > ul {
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;
            width: ~"calc(100% + 8px)";
            margin: 0 -4px;
            justify-content: center;

            &:before, &:after {
                display: none;
            }

            & > li {

                max-width: none;
                border-right: none;
                display: flex;
                height: 100%;
                margin: 0;
                padding: 0 4px;
                width: 20%;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    padding: 16px;
                    background: transparent;
                    color: @text_color;
                    border: none;
                    transition: all 200ms linear;
                    border-radius: @border_radius;

                    h4 {
                        .rems(16);
                        line-height: 24px;
                        margin: 0;
                        font-weight: 500;
                        color: inherit;
                        transition: color 200ms linear;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: @border_radius;
                    }

                    &:hover {

                        color: @primary_color;
                        transition: all 200ms linear;

                    }

                }

                &.active {

                    pointer-events: none;

                    a {

                        color: #fff;
                        background: @primary_color;
                        transition: all 200ms linear;

                    }
                }
            }
        }

        .mobileTabsTitle {
            display: none;
            position: relative;
            float: none;
            clear: both;
            width: 100%;
            .rems(16);
            line-height: 24px;
            font-weight: 500;
            color: #fff;
            margin-bottom: 0;
            padding: 16px;
            border-bottom: none;
            cursor: pointer;
            background: @primary_color;
            border-radius: @border_radius;
            transition: all 200ms linear;

            &.open {
                border-radius: @border_radius @border_radius 0 0;
                transition: all 200ms linear;
            }

            &:after {

                .fa(f107);
                .rems(18);
                line-height: 24px;
                width: auto;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: 16px;
                top: 50%;
                transform: translateY(-50%) rotate(0deg);
                transform-origin: center;
                transition: transform 200ms linear;
                color: inherit;

            }

            &.open {
                &:after {
                    transform: translateY(-50%) rotate(-180deg);
                    transition: transform 200ms linear;
                }
            }
        }

        .mobileTabsTitle.isClickable {
            display: flex;

            & + ul {
                width: 100%;
                margin: 0;
                float: none;
                clear: both;

                & > li {
                    width: 100%;
                    height: auto;
                    margin: 0;
                    padding: 0;

                    a {
                        height: auto;
                        width: 100%;
                        border-bottom: 1px solid @primary_color;
                        padding: 12px 16px;
                        text-align: left;

                        @media all and (max-width: 800px) {
                            border-radius: 0;
                            padding: 16px;

                            .title {
                                width: 100%;
                                text-align: left;
                                justify-content: flex-start;
                            }
                        }
                    }

                    &.active {
                        a {
                            color: @primary_color;
                            background: transparent;
                        }
                    }
                }
            }
        }
    }

    .tabsContentWrap {
        .rems(14);
        color: @text_color;
        line-height: 24px;
        font-weight: normal;
        padding: 0;
        border: none;
        margin: 0;


        .tabContent {

            padding: 0;

            .tabTitle {

                @media all and (max-width: 800px) {
                    display: block;
                }

                .title {
                    .rems(24);
                    line-height: 40px;
                    margin-bottom: 16px;
                    color: @text_color;
                    display: none;

                    @media all and (max-width: 800px) {
                        display: block;
                    }
                }
            }


        }

        table {
            tr:nth-child(even) {
                background-color: rgba(0,0,0,0.024);
            }
        }
    }

    /*
    .wysiwyg {
        & > h1,& > h2,& > h3,& > h4,& > h5 {
            .rems(24);
            line-height: 40px;
            margin-bottom: 22px;
            color: @text_color;
            font-weight: 500;
        }

        & > h4,& > h5 {
            .rems(20);
            line-height: 32px;
            margin-bottom: 16px;
        }

        ul,ol {
            display: inline-block;

            & > li {
                text-align: left;
            }
        }
    }
    */

}



.sidebarTabsWrap {

    padding: 100px 0;
    background: #fff;

	@media all and (max-width: 700px) {
		padding: 40px 0 64px;
	}


    .wrapper {
        @media all and (max-width: 700px) {
            padding: 0;
        }
    }

    .sidebarTabs {

        display: flex;
        max-width: 100%;
        margin: 0 auto;
        position: relative;
        z-index: 3;


        .tabNav {

            width: 280px;
            flex: 0 0 280px;
            margin: 0;
            z-index: 9;

            @media all and (max-width: 1200px) {
				width: 270px;
				flex: 0 0 270px;
            }

            @media all and (max-width: 700px) {
                display: none;
            }
        }

        .tabContent {
            width: 100%;
            flex: 1 1 100%;
            padding-left: 96px;

            @media all and (max-width: 1200px) {
                width: 68%;
                flex: 1 1 68%;
                padding-left: 64px;
            }

            @media @flyout {
                padding-left: 48px;
            }

            @media all and (max-width: 700px) {
                padding: 0 24px;
                width: 100%;
                flex: 0 0 100%;
            }
        }

    }

    .sideNav {

        display: block;
        height: 100%;
        margin-top: -8px;

        ul {

            margin: 0;
            display: block;
            width: 100%;

            li {
                margin: 0;

                @media @flyout {
                    margin: 0;
                }

                a {
                    .rems(14);
                    line-height: 1.2em;
                    color: @text_color;
                    opacity: 1;
                    transition: opacity 200ms linear;
                    padding: 10.5px 0 10.5px 11px;
                    display: inline-block;
                    width: 100%;
                    position: relative;
                    letter-spacing: 0;
                    font-weight: 700;
					text-transform: uppercase;
					letter-spacing: 0.1em;

                    @media @flyout {
                        padding: 13px;
                    }

                    &:before {
                        content: "";
                        display: block;
                        width: 2px;
                        height: 20px;
                        border-radius: 0;
                        position: absolute;
                        top: 50%;
                        bottom: 0;
                        left: 0;
                        transform: translateY(-50%);
                        opacity: 0;
                        transition: opacity 200ms linear;
                        z-index: 2;
                        background: @primary_color;


                        @media @flyout {
                            //width: 40px;
                            //height: 40px;
                        }
                    }

                    &:hover {
                        color: @text_color;
                        opacity: 0.64;
                        transition: opacity 200ms linear;
                    }

                    &:active {
                        color: @text_color;
                        opacity: 0.9;
                        transition: opacity 200ms linear;
                    }

                    &.active {
                        opacity: 1;
                        transition: opacity 200ms linear;

                        &:before {
                            opacity: 1;
                            transition: opacity 200ms linear;
                        }

                        &:hover {
                            opacity: 1;
                            transition: opacity 200ms linear;
                        }

                        &:active {
                            opacity: 1;
                            transition: opacity 200ms linear;
                        }
                    }
                }
            }

        }
    }

    .tabContentWrap {

        section {

            position: relative;
            display: block;


            @media all and (min-width: 701px) {

                margin: 0;
                padding: 0;
                background: transparent;
				display: none;
				
				&.active {
					display: block;
				}
				
            }

            @media all and (max-width: 700px) {
                margin: 0;
            }


            .sectionTitle {
                .rems(14);
                line-height: 1.2em;
				font-weight: 700;
                margin: 0 0 26px;
                position: relative;
				display: none;


				@media @product_breakpoint {
					.rems(14);
					line-height: 1.2em;
					margin: 0 0 16px;
				}

                @media all and (max-width: 700px) {
                    margin: 0;
                    padding: 33px 24px 13px 0;
                    border-bottom: 1px solid @primary_color;
                    cursor: pointer;
					display: block;
                }
				
				
				&.sideEffectsTitle {
					margin: 0 0 40px;

					@media all and (max-width: 700px) {
						margin: 0;
					}
				}


                &.isClickable {

                    .rems(14);
                    line-height: 24px;

					&:before {
						
						content: url("data:image/svg+xml; utf8,%3Csvg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='11' y='5' width='1' height='11' rx='0.5' transform='rotate(90 11 5)' fill='%231D1D1D'/%3E%3Crect x='6' y='11' width='1' height='11' rx='0.5' transform='rotate(-180 6 11)' fill='%231D1D1D'/%3E%3C/svg%3E");
						width: 11px;
						height: 11px;
						border-radius: 50%;
						padding: 0;
						text-indent: 0;
						border: none;
						background: transparent;
						color: @text_color;
						position: absolute;
						right: 16px;
						top: 50%;
						transform: translateY(-50%) rotate(0deg);
						display: flex;
						align-items: center;
						justify-content: center;
						transition: all 200ms linear;
						box-sizing: border-box;
						z-index: 2;
						margin-top: 11px;

						@media all and (max-width: 700px) {
							right: 0;
						}
	
						}

                    &.open {

                        &:before {

                            transform: translateY(-50%) rotate(180deg);
                            transition: all 200ms linear;

                        }

                    }

                }
            }

            .sectionContent {

                padding: 0;
                background: transparent;

                @media all and (max-width: 700px) {
                    display: none;
                    padding: 24px 0;
                    border-bottom: 1px solid @primary_color;
                    background: transparent;
                }

                &.wysiwyg {

					h1,h2,h3,h4 {
						.rems(18);
						line-height: 1.2em;
						font-weight: 700;

						@media @product_breakpoint {
							//.rems(14);
							//line-height: 1.2em;
							//margin: 0 0 16px;
						}
					}
					
					h5, h6 {
						.rems(16);
						line-height: 1.2em;
						font-weight: 700;

						@media @product_breakpoint {
							//.rems(12);
							//line-height: 1.2em;
							//margin: 0 0 16px;
						}
					}
					
					p, ul, ol {
						
						.rems(14);
						line-height: 22px;
						
						& + h1, & + h2, & + h3, & + h4, & + h5, & + h6 {
							padding-top: 14px;
						}
					}
                   
                }

                .ctaWrapper {
                    .button, .buttonAlt {
                        margin: 0;
                    }
                }

            }

            &:first-of-type {

                .sectionContent {
                    @media all and (max-width: 700px) {
                        display: block;
                    }
                }
            }
			
			

        }
    }


}

