@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    99.99% {
        transform: scale(1);
    }
}

.nhsPrescriptionsWrapper {

    position: relative;

    .prescriptionsLoading {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255,255,255, 1);
        z-index: 999;

        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: all 200ms linear;

        @media @flyout {
            position: fixed;
            width: 100vw;
            height: 100vh;
            background: #fff;
        }

        &.isLoading {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
        }

        .loadingIcon {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            width: auto;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            z-index: 9999;
            margin-left: 252px;

            @media all and (max-width: 1100px) {
                margin-left: 174px;
            }

            @media @flyout {
                margin-left: 0;
            }

            .imageWrap {
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                transform: scale(1);
                animation: pulse 4000ms infinite;
                transform-origin: center;
            }

        }

        .loadingText {
            width: 100%;
            .rems(16);
            line-height: 24px;
            color: @secondary_color;
            margin: 8px 0 0 ;
            text-align: center;
        }

    }


    .row {
        float: none;
        clear: both;
        margin: 0 0 24px;

        label.fieldLabel {
            display: none;
        }
    }



    .patientDetailsReveal {
        display: none;

        .innerPatientWrap {
            padding: 40px 0 0;
        }
    }

    .patientsWrap {
        float: none;
        clear: both;
        padding: 40px 0 0;
        margin: 16px 0 0;
        border-top: 1px solid @border_color;
    }

    .questionWrap {
        .question {
            max-width: 229px;
        }
    }

    .formTitle {
        .rems(16);
        line-height: 24px;
        font-weight: normal;
        margin: 0 0 24px;
    }

    .userFormBilling {
        padding-top: 24px;
    }

    .userFormDelivery {
        padding-top: 24px;
    }

    .highlightBox {
        background: @light_color;
        padding: 24px;

        &.highlightBoxMargin {
            margin-bottom: 24px;
        }

        .row {
            &:last-of-type {
                margin: 0;
            }
        }

        .highlightExtra {
            padding: 24px 0 0;
            border-top: 1px solid @border_color;

            p {
                .rems(14);
                line-height: 24px;
            }
        }

    }

    .additionalOptions {

        padding: 40px 0 0;
        margin: 40px 0 0;
        border-top: 1px solid @border_color;

    }

}

.medicationListForm {
    display: block;
    float: none;
    width: 100%;
    position: relative;

    &:before, &:after {
        display: none;
    }
}

.nhsPrescriptions {

    position: relative;

    input:-webkit-autofill {
        background-color: white !important;
    }



    .medicationList {

        float: none;
        clear: both;
        position: relative;

        .medicationBox {

            display: block;
            width: 100%;
            margin: 0 0 24px;
            border-radius: @border_radius;

            .innerBox {
                display: block;
                width: 100%;
                background: #fff;
                border-radius: @border_radius;
                border: 1px solid @border_color;
                margin: 0;
                transition: background 200ms linear, border-color 200ms linear;

                &.boxError {
                    border-color: @alert_color !important;
                }
            }

            &.removing {

                .innerBox {
                    pointer-events: none;
                    opacity: 0.4;
                    transition: opacity 200ms linear;
                }

            }

            &.hidden {
                display: none;
            }

            .medicationTitle {

                position: relative;
                margin: 0;
                padding: 24px;
                transition: all 200ms linear;
                .rems(21);
                line-height: 24px;
                font-weight: 500;
                cursor: pointer;

                &:before {

                    content: "";
                    background: rgba(164, 197, 58, 0.24);
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    display: inline-block;
                    position: absolute;
                    z-index: 1;
                    right: 24px;
                    top: 50%;
                    transform: translateY(-50%) scale(0);
                    opacity: 0;
                    transition: all 200ms linear;

                }

                &:after {
                    .fa(@faq_fa_icon);
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    padding: 5px 7px;
                    text-indent: 0;
                    border: 1px solid rgba(23, 39, 65, 0.4);
                    background: rgba(164, 197, 58, 0);
                    color: @primary_color;
                    position: absolute;
                    right: 24px;
                    top: 50%;
                    transform: translateY(-50%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .rems(14);
                    line-height: 14px;
                    transition: all 200ms linear;
                    box-sizing: border-box;
                    z-index: 2;
                    transition: all 200ms linear;

                }

                &:hover, &:active {

                    &:before {
                        transform: translateY(-50%) scale(1);
                        opacity: 1;
                        transition: all 200ms linear;
                    }
                    &:after {
                        background: rgba(164, 197, 58, 0);
                        transition: all 200ms linear;
                    }
                }

            }

            &.opening {
                .medicationTitle {
                    &:after {
                        content: "\@{faq_fa_icon_close}";
                    }
                }
            }

            .savedMed {
                .success, .error {
                    padding: 12px 24px 12px 40px !important;

                    &:after {
                        top: 18px;
                        left: 16px;
                    }
                }
            }

            .medicationFields {

                padding: 0 24px 24px;
                display: none;

                .buttonGroup {
                    float: none;
                    clear: both;
                    width: 100%;
                    justify-content: flex-end;
                    position: relative;

                    button {

                        outline: 0;
                        margin: 0;
                        color: @text_color;

                        &:last-of-type {
                            margin-left: 8px;
                        }

                        &.buttonOutlined {
                            border-color: @text_color;
                        }

                        &.buttonText {

                            &:hover {

                                color: @alert_color;

                                &:after {
                                    background: fade(@alert_color, 8%);
                                }

                            }

                            &:active, &:focus {
                                color: @alert_color;

                                &:after {
                                    background: fade(@alert_color, 0%);
                                }

                                &:before {
                                    background: fade(@alert_color, 16%);
                                }
                            }

                        }
                    }

                    .boxLoading {
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);

                        i {
                            color: @secondary_color;
                        }
                    }
                }

            }

            .row {
                float: none;
                clear: both;
                width: 100%;
                margin: 0 0 24px;
            }

            &.open {

                .medicationTitle {

                    &:after {
                        content: "\@{faq_fa_icon_close}";
                    }

                }

                &.closing {
                    .medicationTitle {
                        &:after {
                            content: "\@{faq_fa_icon}";
                        }
                    }
                }

                .medicationFields {

                    display: block;

                }

                &:not(.closing) {
                    .medicationFields {
                        height: auto !important;
                    }
                }

            }
        }

    }

    .medicationSearch {

        float: none;
        clear: both;
        padding: 16px 0 0;

        .row {
            float: none;
            clear: both;
            margin: 0;
            width: 100%;
            position: relative;

            &:before, &:after {
                display: none;
            }

            .inputWrap {
                float: none;
                clear: both;
                width: 100%;
            }

            .inputWrap.buttonInline {

                box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
                border-radius: 8px;
                overflow: hidden;
                background: @light_color;

                input[type="text"] {
                    padding: 30px 16px;
                    min-height: 80px;
                    border-radius: 8px 0 0 8px;
                    border-color: #fff;

                    .placeholder(fade(@text_color, 64%));
                    transition: border-color 200ms linear;

                    @media @mobile {
                        border-radius: 8px;
                    }

                }

                input:-webkit-autofill {
                    background-color: white !important;
                }

                button {
                    padding: 30px 16px;
                    width: 149px;
                    min-height: 80px;
                    border-radius: 0 8px 8px 0;

                    @media @mobile {
                        border-radius: 8px;
                        width: 100%;
                    }
                }

            }
        }

        .medicationResults {
            background: #fff;
            border-radius: 0 0 8px 8px;
            position: absolute;
            top: 100%;
            left: 0;
            width: ~"calc(100% - 149px)";
            //overflow: hidden;
            border: 1px solid #fff;
            border-top: 0;
            margin-top: -8px;
            z-index: 100;
            box-shadow: 0 16px 32px rgba(0, 0, 0, 0.08);
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            transition: border-color 200ms linear;

            @media @mobile {
                width: 100%;
                top: 81px;
            }

            &.resultsVisible {
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
                transition: border-color 200ms linear;

            }


            &:before {
                content: "";
                position: absolute;
                top: -16px;
                left: 0;
                right: 0;
                background: #fff;
                height: 16px;
                width: 100%;
                display: block;
                z-index: 4;
            }

            .scrollContain {
                width: 100%;
                height: 100%;
                overflow: hidden;
                display: block;
            }

            .innerResults {
                overflow-y: scroll;
                position: relative;
                z-index: 3;
                width: 100%;
                padding: 12px 16px 16px;
                max-height: 156px;
            }

            ul {
                li {
                    margin-bottom: 0;
                }
            }

            a {
                cursor: pointer;
                color: @primary_color;
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
                transition: color 200ms linear;
                display: inline-block;
                width: 100%;
                padding: 4px 0;

                &:hover {
                    color: @highlight_color;
                    transition: color 200ms linear;
                }

                &:active {
                    color: darken(@highlight_color,5%);
                    transition: color 200ms linear;
                }
            }

        }

        &.resultsActive {

            .row {

                .inputWrap.buttonInline {

                    input[type="text"] {
                        border-color: @primary_color;
                        transition: border-color 200ms linear;
                    }

                }

            }

            .medicationResults {
                border-color: @primary_color;
                transition: border-color 200ms linear;
            }

        }

    }

    .exemptionReasonsWrap {
        display: none;
        padding: 22px 0 32px 24px;

        @media @mobile {
            padding: 22px 0 32px 0;
        }

        .row {
            margin: 0 0 4px;
        }

        .error {
            margin: 16px 0 0 ;
        }
    }

    .userFormDelivery {
        display: none;
    }

    .deliveryAddressBox {

        padding: 24px 0 0;
    }

}

.questionWrap {

    display: flex;
    align-items: center;

    @media @mobile {
        flex-wrap: wrap;
    }

    .question {
        width: auto;
        flex: 1 1 auto;

        @media @mobile {
            width: 100%;
            flex: 0 0 100%;
        }
    }

    .questionFields {
        flex: 1 1 116px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media @mobile {
            width: 100%;
            flex: 0 0 100%;
            margin-top: 16px;
        }

        .row {
            margin: 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            @media @mobile {
                justify-content: flex-start;
            }
        }
    }


}


.selectSurgeryWrap {
    float: none;
    clear: both;
    width: 100%;
    margin-top: 40px;
    border-top: none;

    .textLink {

        position: relative;
        top: 0;
        left: 0;
        display: inline-block;
        margin: 4px 0 0;
        padding: 4px 0;
        .rems(14);
        line-height: 16px;
        font-weight: 500;
        color: fade(@primary_color, 48%);
        transition: color 200ms linear;

        &:hover {
            color: fade(@primary_color, 80%);
            transition: color 200ms linear;
        }

        &:active {
            color: fade(@primary_color, 100%);
            transition: color 200ms linear;
        }

    }

    .mapHeader {

        margin-bottom: 40px;

        .stockistSearch {
            display: none;
        }

        .stockistSearch, .stockistSearchVue {

            .row {
                margin: 0;
                float: none;
                clear: both;
                display: block;
                position: relative;

                &:before, &:after {
                    display: none;
                }

                .inputWrap {
                    width: 100%;
                    float: none;
                    clear: both;
                    margin: 0;
                    max-width: 100%;

                }

                .textLink {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    display: none !important;
                }

                .buttonInline {
                    .iconButton {
                        margin: 0 !important;
                    }
                }

            }
        }
    }


    .mapWrap {
        position: relative;
        float: none;
        clear: both;
        width: 100%;
        margin-bottom: 0;
        height: 320px;

        &.mapLoading {


            .mapLoader {

                opacity: 1;
                visibility: visible;
                pointer-events: auto;

            }
        }

        .mapLoader {
            position: absolute;
            display: block;
            left: 0;
            top: 0;
            width: 51.3vw;
            height: 320px;
            z-index: 3;
            background: #46bcec;
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            transition: all 200ms linear;

            @media all and (max-width: 1100px) {
                width: 100%;
            }

            .loadingIcon {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                width: auto;
                position: absolute;
                top: 50%;
                left: 25%;
                transform: translateY(-50%);
                z-index: 9999;

                @media all and (max-width: 1100px) {
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

            }

            .loadingText {
                width: 100%;
                .rems(14);
                line-height: 24px;
                color: #fff;
                margin: 0;
                text-align: center;
                animation: pulse 2000ms infinite;
                transform-origin: center;
            }
        }

        .map {
            float: none;
            clear: both;
            width: 51.3vw;
            height: 320px;
            border-radius: 0;
            overflow: hidden;
            position: absolute;
            left: 0;
            top: 0;
            background: @light_color;
            outline: 0 !important;
            border: none;
            z-index: 2;

            @media all and (max-width: 1100px) {
                width: 100%;
            }


        }

        .infobox {

            border: none;
            border-radius: @border_radius;
            overflow: hidden;
            padding: 16px;


            button {
                width: 30px;
                height: 30px;
                color: @text_color;
                cursor: pointer;
                outline: none;
            }

            .info_boxContent {
                .rems(14);
                line-height: 20px;
                color: @text_color;
                font-weight: normal;

                h3 {
                    margin: -4px 24px 0 0;
                    .rems(16);
                    line-height: 20px;
                    font-weight: 600;
                }

                .infoContent {
                    margin-bottom: 8px;
                    width: 100%;
                    position: relative;
                }

                .button {
                    float: none;
                    clear: both;
                    width: 100%;
                    margin: 16px 0 0;
                    padding: 8px 12px;
                    .rems(15);
                    line-height: 24px;
                }
            }

            &:after {
                display: none;
            }
        }


    }


    .stockistWrapper {

        position: relative;
        float: none;
        clear: both;
        width: 100%;



        .showResultsWrap {
            width: 100%;
            min-height: 40px;
            position: relative;
            padding-top: 0;
            display: flex;
            align-items: center;



            a {
                display: none;

                span {
                    color: @secondary_color;
                    transition: color 200ms linear;
                }

                &:hover {
                    span {
                        color: darken(@secondary_color, 8%);
                        transition: color 200ms linear;
                    }
                }

                &:active {
                    span {
                        color: darken(@secondary_color, 4%);
                        transition: color 200ms linear;
                    }
                }
            }
        }

        .results {
            position: relative;
            height: auto;
            border-radius: 0;
            overflow: hidden;
            padding: 0;
            display: none;

            & > p {
                padding: 24px;
                font-weight: 500;
                opacity: 0.7;
            }

            h4 {
                float: none;
                clear: both;
                width: 100%;
                background: @primary_color;
                margin: 0;
                padding: 24px;
                .rems(16);
                color: #fff;
                line-height: 24px;
                font-weight: 500;
                border-radius: 0;
                height: 72px;
            }

            .resultsForMap {
                position: relative;
                float: none;
                clear: both;
                width: 100%;
                margin: 0;
                padding: 0;
                overflow-y: auto;
                height: 400px;
                background: #fff;
                max-height: none;
                border-radius: 0;
                border: 1px solid @border_color;
                border-top: none;

                li {
                    float: none;
                    clear: both;
                    width: 100%;
                    margin-bottom: 0;
                    border-bottom: 1px solid @border_color;
                    list-style-type: none;
                    .rems(14);
                    line-height: 24px;
                    padding: 0;

                    &:last-child {
                        border: none;
                        margin-bottom: 0;
                    }

                    h5 {
                        float: none;
                        clear: both;
                        width: 100%;
                        margin: 0;
                        .rems(14);
                        line-height: 21px;
                        font-weight: 500;
                        color: @primary_color;
                        text-transform: capitalize;
                        transition: color 200ms linear;
                    }

                    a {
                        text-decoration: none;
                        color: @text_color;
                        display: block;
                        width: 100%;
                        height: 100%;
                        background: #fff;
                        transition: background 200ms linear;
                        padding: 12px 24px;

                        &:hover, &:active {
                            background: @light_color;
                            transition: background 200ms linear;

                        }

                        &.selected {
                            background: fade(@primary_color, 24%) !important;
                        }

                    }

                }
            }

        }

        .helpText {

            display: none;
            padding-top: 16px;

            &.visible {
                display: block;
            }

            p {
                margin: 0;
                .rems(14);
                line-height: 16px;
                font-weight: 500;
                color: fade(@primary_color, 48%);
            }
        }
    }


    .gpSelectionWrap {

        padding-top: 40px;

        .surgerySelected {

            h5 {
                .rems(16);
                line-height: 24px;
                margin-bottom: 4px;
            }

            p {
                margin: 0 0 8px;
            }
        }

        .surgeryWarning {
            margin-top: 24px;
        }


    }

}


.prescriptionBasketWrap {

    margin: 0 0 62px;

    table.prescriptionBasket {

        margin: 0;

        tr {

            th, td {

                position: relative;

                &.itemName {
                    width: auto;
                }

                &.itemVariation {
                    width: 140px;
                }

                &.itemQty {
                    width: 48px;
                }

                &.itemCost {
                    width: 100px;
                }

                &:last-child {
                    text-align: right;
                }
            }

            th {
                padding: 10px 14px;
            }

            td {
                padding: 19px 14px;
            }

        }

        .removeRow {
            position: absolute;
            top: 50%;
            right: -48px;
            transform: translateY(-50%);
            width: 48px;
            height: 48px;

            button {
                background: transparent;
                outline: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                .rems(14);
                line-height: 14px;
                color: @text_color;
                transition: color 200ms linear;
                cursor: pointer;

                &:hover {
                    color: fade(@alert_color, 90%);
                    transition: color 200ms linear;
                }

                &:active {
                    color: fade(@alert_color, 100%);
                    transition: color 200ms linear;
                }

            }
        }

    }

    .deliveryRow, .totalsRow {
        width: 100%;
        display: flex;

        .colLeft, .colRight {
            padding: 18px 14px;
        }

        .colRight {
            text-align: right;
        }

    }

    .deliveryRow {

        margin-bottom: 8px;
        .rems(14);
        line-height: 24px;

        .colLeft, .colRight {
            width: 50%;
        }

        .colLeft {
            opacity: 0.64;
        }

    }

    .totalsRow {

        border-top: 1px solid @border_color;
        margin-top: 8px;
        .rems(16);
        line-height: 24px;

        .colLeft, .colRight {
            width: 50%;
        }

        .colLeft {
            width: ~"calc(100% - 100px)";
        }

        .colRight {
            width: 100px;
        }
    }

}


.newPrescriptionOptions {
    border-bottom: 1px solid @border_color;
    padding: 0 0 40px;
    margin: 0 0 40px;
    display: flex;
    justify-content: center;

    .innerPrescriptionOpts {
        display: flex;
        align-items: center;
        text-align: center;
        width: auto;
        margin: 0 auto;

        @media @mobile {
            flex-wrap: wrap;
            justify-content: center;
        }

        .helperText {
            display: inline-block;
            margin-right: 24px;

            @media @mobile {
                display: block;
                width: 100%;
                text-align: center;
                margin: 0 0 24px;
            }
        }

        .button {
            margin: 0;

            i {
                margin: 0 11px 0 0;
            }
        }
    }
}


.checkboxList {

    padding: 24px 0 0;

    .checkboxListWrap {
        padding: 22px 0 32px 24px;

        @media @mobile {
            padding: 22px 0 32px 0;
        }

        &.withTextHighlightBox {
            padding: 22px 0 32px 14px;

            @media @mobile {
                padding: 22px 0 32px 0;
            }
        }

        .row {
            margin: 0 0 4px;
        }
    }
}
