//Colours
@delivery_bg_color: @primary_color;
@delivery_color: #fff;



.deliveryTimer {
    float: none;
    clear: both;
    width: 100%;
    display: block;
    color: @delivery_color;
    background: @delivery_bg_color;
    font-weight: 600;
    padding: 13px 0;
    .rems(14);
    line-height: 1.2em;
    position: relative;
    letter-spacing: 0;
    text-align: center;

    @media @flyout {
        padding: 24px 0;
    }
	
	@media @mobile {
		padding: 15px 0;
	}

    &:not(.noWrapper) {

        background: @delivery_bg_color;
        color: @delivery_color;
    }

    span {
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
        color: @delivery_color;
    }

    p {
        font-size: inherit;
        line-height: inherit;
        color: inherit;
        margin: 0;
    }


}
