/*
 * Typography
 */

body {
	line-height: 24px;
	color: @text_color;
	letter-spacing: 0.01px;
}

.darkBg {
	color: #fff;
}

a {
	color: @highlight_color;
	text-decoration: none;
	transition: color 200ms linear;
	font-weight: inherit;

	&:hover {
		text-decoration: none;
		color: darken(@highlight_color,10%);
		transition: color 200ms linear;
	}

	&:active {
		text-decoration: none;
		color: darken(@highlight_color,5%);
		transition: color 200ms linear;
	}


}

p {
	line-height: 22px;
	margin: 0 0 25px;
	font-weight: 400;
}

small {
	.ems(13);
	line-height: 20px;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0 0 16px 0;
	color: @text_color;
	font-family: @primary_font;
	line-height: 1;
    font-weight: 400!important;


	.darkBg & {
		color: #fff;
	}

	&.subtitle1 {
		font-size: 18px;
		line-height: 24px;
		font-weight: 400;
		color: @text_color;
		margin: 0 0 4px;

		.darkBg & {
			color: #fff;
		}
	}

	&.subtitle2 {
		font-size: 16px;
		line-height: 24px;
		font-weight: 400;
		color: @text_color;
		margin: 0 0 4px;

		.darkBg & {
			color: #fff;
		}
	}

	span, strong {
		font-weight: 700;
	}
}

h1 {
	.headingStyle(h1);
}

h2 {
	.headingStyle(h2);
}

h3 {
	.headingStyle(h3);
}

h4 {
	.headingStyle(h4);
}

h5 {
	.headingStyle(h5);
}

h6 {
	.headingStyle(h6);
}


/*
 * Page Content Class
 */

.pageContent, .wysiwyg {

	.clearfix();
	font-weight: 400;

	blockquote {

		.rems(14);
		line-height: 24px;
		font-weight: normal;
		margin: 0 0 20px;
		padding: 0;
		min-height: 48px;
		position: relative;
		padding-left: 24px;

		&:before {
			content: "";
			height: 100%;
			width: 4px;
			background: @secondary_color;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			display: block;

		}

		p {
			margin: 0 0 8px;
		}

		cite {
			.rems(14);
			line-height: 1;
			font-style: italic;

			a {
				text-decoration: none;

				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	/*
	 * Tiny mce produces left and right images with these attributes
	 */
	img[style="float: left;"] {
		margin: 5px 25px 25px 0;
	}

	img[style="float: right;"] {
		margin: 5px 0 25px 25px;
	}

	img[style="float: right;"], img[style="float: left;"] {

		@media @mobile {
			float: none !important;
			display: block;
			margin: 0 auto 25px;
		}
	}

	ul:not(.accordion):not(.faqs):not(.sideEffectsList), ol {

		margin: 0 0 0 19px;
		padding: 0 0 24px 0;
		display: inline-block;
		width: auto;

		&:before, &:after {
			display: none;
		}

		li {
			list-style-type: disc;
			padding-left: 8px;
		}

	}

	ol{


		li {
			list-style: decimal;
		}
	}

	&.itemDescription {

		ul:not(.accordion):not(.faqs):not(.sideEffectsList), ol {
			@media @product_breakpoint {
				margin-left: auto;
				margin-right: auto;
			}
		}

	}

	a {
		text-decoration: none;
	}

	p, ul li, ol li {

		color: @text_color;

		.darkBg & {
			color: @dark_text_color !important;
		}
	}

	&.darkBg {

		p, ul li, ol li {
			color: @dark_text_color !important;
		}

	}
}


.visuallyHidden {
	position: absolute !important;
	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	margin: -1px !important;
	overflow: hidden !important;
	clip: rect(0,0,0,0) !important;
	white-space: nowrap !important;
	border: 0 !important;
}
