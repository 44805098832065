/*
 * Vanilla Datepicker Styles
 */

.row {

    .datepickerWrap {
        position: relative;
        .clearfix();
        max-width: 100%;
        clear: both;

        input[type="text"] {
            float: none;
            cursor: pointer;
        }

        &:after {
            .fa(f073);
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            z-index: 1000;
            color: @input_icon_color;
        }
    }
}

.datePicker {
    cursor: pointer;
    width: 65%;
}

.ui-datepicker {
    background: #fff;
    box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    overflow: hidden;
    z-index: 10000 !important;
    display: none;

    .ui-datepicker-header {
        position: relative;
        z-index: 999;
        overflow: hidden;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .ui-datepicker-title {
            .headingStyle(h6);
            .rems(14) !important;
            line-height: 16px !important;
            padding: 4px 0;
            text-align: center;
            position: relative;
            font-weight: 500;
            color: @primary_color;
            background: @light_color;
            border-bottom: none;
            width: 100%;
			font-weight: 700;

            select {
                -webkit-appearance: none;
                -moz-appearance: none;
                color: @primary_color;
                border: none;
                padding: 12px 16px 12px 0;
                background: transparent;
                cursor: pointer;
                transition: color 200ms linear;
				font-weight: 700;

				&:hover, &:active, &:focus {
                    color: darken(@primary_color,10%);
                    transition: color 200ms linear;
                }
            }


            .ui-datepicker-month {

                position: relative;

                &:after {
                    .fa(f107);
                    margin-left: 6px;
                    font-weight: 300;
                    color: @primary_color;
                    pointer-events: none;
                    position: absolute;
                    right: -50px;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: color 200ms linear;

                }
            }


        }

        .ui-datepicker-prev, .ui-datepicker-next {
            transform: translateY(-50%);
            position: absolute;
            top: 50%;
            cursor: pointer;
            z-index: 999;
            overflow: hidden;
            width: 48px;
            height: 100%;

            span {
                display: none;
            }

            &:after {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                opacity: 1;
                transition: all 200ms linear;
				color: @text_color;
            }

            &:hover {
                &:after {
                    opacity: 1;
					color: @highlight_color;
                    transition: all 200ms linear;
                }
            }

            &:active {
                &:after {
                    opacity: 1;
					color: darken(@highlight_color, 10%);
                    transition: all 200ms linear;
                }
            }
        }

        // Next Button
        .ui-datepicker-next {
            right: 0;

            &:after {
                .fa(f105);
                .rems(14);
                line-height: 16px;
            }

        }

        // Previous Button
        .ui-datepicker-prev {
            left: 0;

            &:after {
                .fa(f104);
                .rems(14);
                line-height: 16px;
            }
        }
    }

    .ui-datepicker-calendar {
        border: 0;
        table-layout: auto;
        background: #fff;
        margin: 0;
        border-spacing: 0;

        thead {
            color: @highlight_color;
        }

        th, td {
            padding: 8px 11.5px;
            cursor: pointer;
            text-align: center;
            z-index: 100;
            position: relative;
            .rems(12);
            font-family: @primary_font;
            border: 0;
            font-weight: normal;
            background: transparent;

            &.ui-state-disabled {
                opacity: 0.5;
            }
        }

        td {

            border-radius: 0 !important;

            a {
                color: @text_color;
                display: block;
                position: relative;
                z-index: 2;
				font-weight: 700;

                &:after {
                    content: "";
                    width: 28px;
                    height: 28px;
                    background: @highlight_color;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    border-radius: 50%;
                    z-index: -1;
                    opacity: 0;
                    transition: opacity 200ms linear;

                }

                &:hover, &:active, &:focus {
					
					color: @highlight_color;
					
                    &:after {
                        opacity: 0;
                        transition: opacity 200ms linear;
                    }
                }
				
            }

            &.ui-state-disabled {
                border: 0;
            }
        }

        .ui-datepicker-today {

			background: transparent;


			a {
				color: @text_color !important;
				opacity: 1 !important;

				&:after {
					opacity: 0.24 !important;
					transition: opacity 200ms linear;
				}
			}
        }

        .ui-datepicker-current-day {

            background: transparent;


            a {
                color: #fff !important;
                opacity: 1 !important;

                &:after {
                    opacity: 1 !important;
					background: @highlight_color !important;
                    transition: opacity 200ms linear;
                }
            }
        }
    }

    .ui-datepicker-buttonpane {
        padding: 12px;
        background: @light_color;
        display: flex;

        &:before, &:after {
            display: none;
        }

        button {
            background: 0;
            border: 0;
            margin: 0;
            cursor: pointer;

            .button();
            margin: 0;
            padding: 8px 16px;
            .rems(14);
            line-height: 16px;

            &.ui-priority-secondary {
                margin-right: auto;
                display: none;
            }

            &.ui-priority-primary {
                margin-left: 0;
                width: 100%;
            }
        }
    }

    .ui-slider {
        position: relative;
        background: fade(@text_color, 50%);
    }

    .ui-slider-horizontal {
        height: 12px;
    }

    .ui-slider-handle {
        position: absolute;
        z-index: 2;
        width: 20px;
        height: 20px;
        cursor: default;
        -ms-touch-action: none;
        touch-action: none;
        top: -4px;
        margin: 0 0 0 -6px;
        background: @text_color;
        font-weight: normal;
    }

    .ui_tpicker_time_label, .ui_tpicker_hour_label, .ui_tpicker_minute_label {
        padding: 10px;
        .rems(14);
        font-weight: bold;
        clear: both;
    }

    .ui_tpicker_hour, .ui_tpicker_minute {
        display: block;
        margin: 0 20px 10px;
        width: ~'calc(100% - 40px)';
    }

    .ui_tpicker_time_input {

        &[disabled] {
            background: none;
            border: none;
        }
    }

    .ui_tpicker_time_label {
        float: left;
        width: 100px;
    }

    .ui_tpicker_time {
        float: left;
        width: ~'calc(100% - 110px)';
    }

    a {
        text-decoration: none;
    }
}

.ui_tpicker_unit_hide {
    display: none;
}
