@quick_input_width: 110px;

.itemsBrowse .quickAddToBasket {
    position: relative;
    padding: 0;
    margin-bottom: 20px;
    .transition(all ease-in-out 300ms);
    z-index: 20;
    cursor: default;

    .close {
        width: 36px;
        height: 36px;
        outline: 0;
        background: none;
        color: #4D4D4D;
        text-align: center;
        line-height: 36px;
        border-radius: 50%;
        position: absolute;
        top: 8px;
        right: 8px;
        .rems(16);
        cursor: pointer;
        display: none;
        z-index: 310;


        @media all and (max-width: 400px) {
            width: 16px;
            height: 16px;
            top: 4px;
        }
    }

    .success, .error {
        width: 100%;
        z-index: 400;
        padding-right: 40px;
        bottom: 40px !important;
        background: #fff;
        border-left: none !important;
        border-right: none !important;
        
        &.error {
            padding: 13px 10px 15px 50px!important;
            
            &:after {
                top: 13px;
            }
        }
    }

    .sizeLabel {
        padding: 4px 0;
        width: ~'-webkit-calc(100% - @{quick_input_width})';
        width: ~'calc(100% - @{quick_input_width})';
        .clearfix();
    }

    .priceNow, .priceWas {
        color: @primary_color;
    }
    
    .priceWas .multiPriceBlock span {
        color: #333;
    }

    .sizeName {
        display: inline-block;
        margin-right: 10px
    }
    
    .priceWas span {
        text-decoration: line-through;
    }
    
    .multiPriceBlock span {
        font-weight: 500;
        color: @mid_color;
    }

    .row {
        float: left;
        width: 100%;

        .inputWrap {
            float: right;
            width: 110px;

            .numberButtons {
                position: inherit;
                width: auto;
                height: auto;
                left: auto;
                right: auto;
                top: auto;

                .numberButton {
                    bottom: auto;
                    top: 0;
                    width: 36px;
                    height: 36px;
                    line-height: 36px;
                    text-align: center;
                    font-weight: normal;
                    color: #4D4D4D;
                    user-select: none;
                    opacity: 1;
                    transition: opacity 0.3s;

                    &.add {
                        top: 0;
                        right: 0;
                        left: auto;
                    }

                    &.subtract {
                        left: 0;
                        top: 1px;
                    }

                    &.disabled {
                        opacity: 0.4;
                        cursor: default;
                    }
                }
            }

            input:not([type='checkbox']), input:not([type='radio']) {

                &.numberArrows {
                    text-align: center;
                    .rems(20);
                    font-weight: 700;
                    border: 0;
                    background: 0;
                    padding: 5px 35px;
                }
            }
        }
    }

    .rowsSingleWrap {
        float: left;
        width: 100%;

        .inputWrap {
            float: left;
            width: 100%;

            .row {
                padding: 0;
                border: none;
            }
        }

        .chosen-container {
            min-width: 100px;

            .chosen-results li {
                width: 100%;
                text-align: left;
            }
        }

        label {
            line-height: 45px;
            padding: 0;
        }

        @media all and (max-width: 400px) {

            .row {
                padding: 6px 12px;
                .rems(12);
            }

            label {
                line-height: 36px;
            }
        }
    }

    .bottomWrap {

        .totalWrap {
            margin-bottom: 15px;
            padding: 10px 15px;
            .rems(20);
            font-weight: normal;
            color: #4D4D4D;
        }

        .total {
            display: inline-block;
            white-space: nowrap;
        }
    }

    .button {
        width: 100%;
        float: none;
        margin: 0 !important;
    }
}
