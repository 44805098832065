.infoBanner {

    background: #fff;
    padding: 120px 0;
    overflow: hidden;
    position: relative;
    float: none;
    clear: both;

    @media @flyout {
        padding: 80px 0;
    }

    @media @mobile{
        padding: 56px 0;
    }

    .wrapper {
        position: relative;
        z-index: 2;

        @media all and (max-width: 1000px) {
            padding: 0;
        }

    }



    .iconListWrap {

        max-width: 1364px;
        margin: 0 auto;

        .iconList {

            .colGroup(16px);
            margin-bottom: 0;
            padding-bottom: 0 !important;

            &:before, &:after {
                display: none;
            }



            .slick-track {

                display: flex;

                &:before, &:after {
                    display: none;
                }
            }

            &:not(.slick-initialized) {

                @media all and (max-width: 1000px) {
                    .colGroup(48px);
                }

                & > li {

                    @media all and (max-width: 1000px) {
                        &:nth-child(n+5) {
                            display: none;
                        }
                    }

                    @media all and (max-width: 700px) {
                        &:nth-child(n+4) {
                            display: none;
                        }
                    }

                    @media all and (max-width: 560px) {
                        &:nth-child(n+3) {
                            display: none;
                        }
                    }

                    @media all and (max-width: 400px) {
                        &:nth-child(n+2) {
                            display: none;
                        }
                    }
                }

            }

            & > li, .slick-track > li {
                .col(5, 8px);
                margin-bottom: 0 !important;

                @media all and (max-width: 1000px) {
                    .col(4, 48px);
                }

                @media all and (max-width: 700px) {
                    .col(3, 48px);
                }

                @media all and (max-width: 560px) {
                    .col(2, 48px);
                }

                @media all and (max-width: 400px) {
                    .col(1, 48px);
                }

            }

        }

        .iconTitleWrap {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            flex-direction: column;
            padding: 0;
            text-align: center;

            .icon {
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    width: 100%;
                    height: auto;
                }
            }

            .iconTitle {
                margin: 16px 0 0;
                .rems(16);
                line-height: 32px;
                font-weight: normal;
                width: 100%;
                text-align: inherit;
                letter-spacing: 0;

                @media @mobile {
                    .rems(14);
                    line-height: 24px;
                }
            }

        }

    }


}
