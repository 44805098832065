.onlineDoctorCTA {
    float: none;
    clear: both;
    position: relative;
    width: 100%;
    padding: 60px 0;
    background: @light_color;

    @media @large_tablet {
        padding: 56px 0;
    }

    @media @mobile {
		padding: 48px 0;
    }

    &.darkBg {
        background: @dark_color;
        color: #fff;

        &:after {
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(255, 255, 255, 0.16);
            display: none;
        }

        @media @large_tablet {
            &:after {
                display: block;
            }
        }
    }


}

.innerOnlineDoctorCTA {

    .introBlock {

        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 56px;

        @media @large_tablet {
            margin-bottom: 40px;
        }

        .innerIntro {
            width: 100%;
            max-width: 100%;
        }

        .introContentWrap {
            max-width: 100%;

        }

    }

    .buttonWrap {
        width: 100%;
        text-align: center;
        margin: 0 0 -16px 0;

        & > a {
            display: inline-block;
            margin: 0 16px 16px 16px;

            @media @mobile {
                margin: 0 12px 16px 12px;
            }

        }
    }

}
