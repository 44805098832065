.crossSellTabs {
    position: relative;
    clear: both;

    .tabSelection {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
        border-bottom: 1px solid @border_color;
        .clearfix();

        li {
            display: inline-block;
            vertical-align: bottom;

            a {
                display: block;
                padding: 10px 25px;
                background: @highlight_color;
                color: #fff;
                text-decoration: none;

                @media @mobile {
                    padding: 10px 15px;
                    width: 100%;
                }

                &:hover {
                    color: #fff;
                    background: lighten(@highlight_color, 10%);
                }
            }

            &.active {

                &:hover {
                    color: #fff;
                    background: lighten(@highlight_color, 10%);
                }
            }
        }
    }

    .tab {
        display: none;

        &:first-child {
            display: block;
        }

        .itemDisplayTitle {
            margin: 20px 0;
            text-align: center;
        }
    }
}
