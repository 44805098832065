.unsubscribeWrap {
    .wrapper();

    .unsubscribe {
        max-width: 600px;
        margin: 0 auto;
        padding: 50px 0;

        @media @mobile {
            max-width: initial;
        }
    }
}
