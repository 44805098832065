/*
 * Checkout CSS
 */

#checkout {
    margin: 0;
    .clearfix();
    .wrapper();
    padding: 20px 0;
    clear: both;

    @media @tablet {
        margin: 0 auto;
        max-width: 800px;
    }
}

/*
 * Main checkout layout
 */

.orderSummaryWrap {
    float: left;
    width: ~"calc(58% - 40px)";
    margin-right: 40px;
    .clearfix();

    @media @tablet {
        margin: 20px 0 0;
        padding: 0;
        width: 100%;
        clear: both;
    }

    @media @mobile {
        margin: 0;
        margin-top: 10px;
        padding: 0;
        width: 100%;
        clear: both;
    }
}

#mobileCheckoutButton {
    display: none;

    @media @mobile, @tablet {
        width: 100%;
        display: block;
    }
}

.checkoutForm .userDetailsWrap {
     @media @mobile, @tablet {
        display: none;
    }

}

.userDetailsWrap {
    float: right;
    width: 42%;
    .clearfix();

    @media @tablet {
        margin: 0;
        padding: 0;
        width: 100%;
        clear: both;
    }

    @media @mobile {
        margin: 0;
        width: 100%;
        clear: both;
    }

    #shipAddress {
        max-height: 0;
        transition: opacity 0.5s cubic-bezier(0, 0, 0.58, 1), max-height 0.5s cubic-bezier(0, 0, 0.58, 1);
        overflow: hidden;
        opacity: 0;
        margin: 0;
        padding: 0 0 20px;

        &.alternate_delivery {
            max-height: 1000px;
            opacity: 1;
        }
    }
}

#orderSummary {
    .clearfix();
    padding: 20px;
    background: #F6F6F6;
    border: 1px solid @border_color;
    margin-bottom: 20px;

    .itemAlert {
        background: #fff;

        a {
            text-decoration: none;
            color: @note_color;
        }
    }
}

#userDetails {
    .clearfix();
    padding-top: 21px;
}

.checkoutSectionWrap {
    .clearfix();
    margin-top: 30px;

    @media @desktop, @tablet {
        float: left;
        width: 100%;
        margin-right: 20px;

        &:nth-child(3n) {
            margin-right: 0;
        }
    }

    h6 {
        .rems(18);
        font-weight: 500;
        margin-bottom: 20px;

        i {
            display: none;
        }
    }

    .fakeInput {
        padding-top: 6px;
    }

    .countdown {
        .rems(12);
        float: right;
    }
}



/*
 * Basket View
 */

.bagImageWrap {
    height: 50px;
    width: 50px;
    text-align: center;
    background: #fff;
    border: 1px solid @border_color;
    overflow: hidden;

    @media @mobile {
        width: 100%;
        height: auto;
    }
}

.buttonAlt.goBack {
    min-width: 200px;
    border: 1px solid #D5D5D5;
    color: #999;
    font-size: 0.8125rem;
    font-weight: 500;

    @media @mobile {
        width: 100%;
    }
}

a.bagTitle {
    text-decoration: none;
    .rems(13);
    line-height: 16px;

    &:hover {
        text-decoration: underline;
    }
}

/*
 * Basket Overview
 */

.overviewHeader {
    .clearfix();

    .orderSummaryPage & {
        cursor: pointer;
    }
}

.detailsOverview {
    width: 100%;
    .clearfix();

    .checkoutSectionWrap {

        h5 {
            position: relative;
            margin: 0;
            padding: 0;

            i {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }
}



.formInline {

    .overviewContent {
        clear: both;
        .clearfix();
        width: 100%;
        padding: 20px 0;

        .row {
            margin: 0;

            .fakeInput {
                padding: 0;
                word-wrap: break-word;
            }
        }

        label {
            display: none;
        }
    }
}

.editBasket {
    .button();
    float: right;
    padding: 10px 15px;
}

/*
 * Basket View
 */

#bagItems {
    width: 100%;

    .itemAlert {

        .error {
            text-align: center;
        }
    }
}

.bagItemsHeader, .bagItem {
    display: table;
    text-align: center;
    width: 100%;
    table-layout: fixed;

    > div {
        display: table-cell;
        padding: 10px;
        vertical-align: middle;

        @media @mobile {
            display: block;
            float: left;
            padding: 0;
            text-align: left;

        }

        &:first-child {
            padding-left: 0;
            text-align: left;
        }

        &:last-child {
            text-align: right;
            padding-right: 0;
        }
    }

    .bagThumb {
        padding: 5px;
        width: 8%;

        @media @mobile {
            width: 60px;
        }
    }

    .bagDescription {
        text-align: left;
        width: 20%;

        @media @mobile {
            width: ~'calc(100% - 70px)';
            float: right;
            margin: 0;
        }
    }

    .bagCode {

        @media @mobile {
            width: ~'calc(100% - 70px)';
            float: right;
            margin: 0 0 5px;
        }
    }

    .bagSize {
        width: 16%;

        @media @mobile {
            width: ~'calc(100% - 70px)';
            float: right;
            margin: 0 0 50px;
            .rems(12);
        }
    }

    .bagUnitPrice {
        width: 13%;

        @media @mobile {
           line-height: 35px;
           position: absolute;
           right: 100px;
           bottom: 10px;
           width: auto;
        }
    }

    .bagQuantity {
        width: 12%;

        @media @mobile {
            float: right;
            position: absolute;
            right: 30px;
            bottom: 10px;
            line-height: 37px;
            width: auto;
        }

        &:before {

             @media @mobile {
                content: 'x';
                line-height: 35px;
                position: absolute;
                right: 100%;
                margin: 0 5px 0 0;
                top: 0;
             }
        }
    }

    .bagCost {
        width: 12%;

        @media @mobile {
            display: none;
        }
    }

    .bagButtons {
        width: 4%;

        @media @mobile {
            float: right;
            position: absolute;
            bottom: 10px;
            right: 0;
            line-height: 35px;
            padding: 0 10px;
            width: auto;
            .ems(20);
        }
    }
}

.bagItemsHeader {

    @media @mobile {
        display: none;
    }

    > div {
        border-bottom: 1px solid @border_color;
    }
}

.passportHelpLink {
    display: block;
    margin-top: 10px;
    .rems(13);
}

.bagItem {

    @media @mobile {
        .clearfix();
        position: relative;
        display: block;
        padding: 0 0 10px;
        margin: 0 0 20px;
        border-bottom: 1px solid @border_color;
    }

    .row {
        margin: 0;

        .bagItemQuantity {
            width: 50px;
            margin: 0 auto;
            padding: 0 10px;
            line-height: 35px;
            height: 37px;
            text-align: center;
            float: none;

            @media @mobile {
                height: 35px;
                line-height: 33px;
            }
        }
    }
}


#totals {
    margin-bottom: 0;
    border: none;
    table-layout: auto;

    tr {

        td {
            border-bottom: 1px solid @border_color;
            padding: 20px 0;

            &:nth-child(2n) {
                text-align: right;
            }

            &:first-child {
                padding-left: 0;
            }
        }

        &.checkoutTotal {

            td {
                border-bottom: none;
            }
        }
    }

    tr:last-child td {
        border-bottom: 0;
    }
}

.deliveryOptions {

    td, .row {
        padding: 0;
        margin: 0;
    }

    td {

        &:first-child {
            width: 200px;
        }
    }

    .deliverySelect {
        float: left;
        width: 80%;

        @media @mobile {
            width: 100%;
            margin-bottom: 10px;
            text-align: right;
        }
    }

    .deliveryTotal {
        float: left;
        width: 20%;
        padding: 0 0 0 20px;
        text-align: right;

        @media @mobile {
            width: 100%;
        }
    }
}

.discount {

    .row {
        margin: 0;
    }

    td {

        &:first-child {
            width: 200px;

            @media @mobile {
                width: 126px;
            }
        }

        &:last-child {
            width: ~'calc(100% - 200px)';

            @media @mobile {
                width: ~'calc(100% - 126px)';;
            }
        }
    }
}

.termslink {
    text-decoration: underline;
}

.itemUpdateButton,
.itemDeleteButton {
    border: 0;
    background: 0;
    padding: 0;
    outline: 0;
    cursor: pointer;
}

table {

    tr {

        .success, .error {
            margin: 0;
        }
    }
}

/*
 * Sage Pay Server
 */

.sagepayServerForm {
    clear: both;

    h2 {
        float: left;
        width: 100%;
        margin: 0 0 20px;
        .rems(24);
    }
}

.sagePayServerIframe {
    height: 550px;
    width: 100%;
    border: 0;
}

.PayPalForm {
    .clearfix();
    float: right;
}

.paypalSubmit {
    background: 0;
    border: 0;
    float: left;
    cursor: pointer;
}

/*
 * Order Complete
 */
.paymentCompletePage {
	float: none;
	clear: both;
	background: #fff;
}

.orderComplete {
	
	float: none;
	clear: both;
    padding: 40px 0;

    @media @desktop {
        min-height: 700px;
    }

    @media @tablet {
        min-height: 500px;
    }

    table {
        float: left;
        max-width: 600px;

        th, td {
            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }
}

.whatYouDo, .whatWeDo {
    float: left;
    width: 48%;
    margin-right: 4%;
    padding: 20px 0;

    @media @tablet, @mobile {
        width: 100%;
        margin: 0 0 30px;
    }
}

.whatWeDo {
    margin-right: 0;
}

/*
 * Checkout Buttons
 */

.logoutText {
    text-align: center;
}

.checkoutButton {
    .button();
    .rems(18);
    margin: 30px 0 0;

    @media @tablet {
        margin-bottom: 30px;
    }

    @media @mobile {
        width: 100%;
        margin: 0;
        margin-bottom: 30px;
    }
}

.paymentDetails {
    background: @light_color;
    padding: 20px;
    margin: 20px 0;
    width: 100%;
    .clearfix();

    .error {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9100;

        &.relative {
            position: relative;
        }
    }

    @media @tablet {

        form {
            float: right;
        }
    }

    .checkoutButton {
        width: 100%;
        margin: 0 0 20px;

        @media @tablet {
            margin: 0;
        }
    }

    @media @mobile {
/*
        &.fixed {
            box-shadow: 2px -5px 5px 0px rgba(0, 0, 0, 0.13);
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            margin: 0;
            z-index: 1000;
        }
*/
    }
}

@media all and (max-width: 640px) {

    select {
        height: 45px;
    }
}

.paymentProcesserTestMessage {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}

.checkoutTotal {
    font-weight: 600;
    .rems(18);
}

.emptyBasket {
    text-align: center;
    padding: 200px 0;

    .busyLoading {
        opacity: 0;
        transition: opacity 0.3s ease-in;
    }

    &.loading {

        .busyLoading {
            opacity: 1;
        }
    }

    @media @mobile {
        padding: 100px 0;
    }
}

/*
 * Accepted cards
 */

.acceptedCards {
    padding: 0;
    .clearfix();

    @media @desktop {
        clear: both;
    }

    @media @tablet {
        margin-top: 5px;
        float: left;
    }

    li {
        display: inline-block;
        margin: 0 10px 10px 0;

        @media @tablet {
            margin: 0 10px 0 0;
        }

        svg {
            width: 80px;
            height: 50px;

            .paymentDetails & {

                @media @mobile, @tablet {
                    width: 50px;
                    height: 30px;
                }
            }
        }

        img {
            max-height: 30px;
            max-width: 100px;
        }
    }

    @media @mobile {
        text-align: center;
    }
}

.memberAlready {
    background: #edf0f2;
    padding: 20px;
    border: 1px solid #d5d5d5;

    .button {
        margin-bottom: 0;
    }
}

.checkoutActions {

    .goBack {

        i {
            margin-right: 5px;
        }
    }
}
