/*
 * Group Grid Listing
 */

.groupGridWrap {

    .groupGridList {
        .clearfix();
        margin-bottom: 30px;

        li {

            @media @desktop {
                .grid(3);
            }

            @media @tablet {
                .grid(2);
            }

            @media @mobile {
                margin-bottom: @gutter_width;
            }

            .imageWrap, .groupGridDescription {
                margin: 0 0 @gutter_width;
            }

            .groupGridTitle {
                margin: 0 0 (@gutter_width / 2);
                line-height: 1;

                a {
                    color: @text_color;
                    text-decoration: none;
                }
            }

            .groupGridTitle, .groupGridDescription {
                padding: 0 @gutter_width;
            }

            .groupGridReadMore {
                .button;
                margin: 0 @gutter_width @gutter_width;
            }
        }
    }
}
