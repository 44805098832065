/*
 * Responsive styles for mobile size tables
 */

@media @mobile {

    table {

        &.responsive {
            margin-bottom: 0;

            th, td {
                position: relative;
                white-space: nowrap;
                overflow: hidden;

                &:first-child {
                    display: none;
                }
            }

            .pinned {

                td {
                    display: none;
                }
            }
        }
    }

    .tableWrapper {
        position: relative;
        margin-bottom: 20px;
        overflow: hidden;
        border-right: 1px solid @border_color;

        .scrollable {
            margin-left: 35%;
            overflow: scroll;
            overflow-y: hidden;
        }

        .pinned {
            position: absolute;
            left: 0;
            top: 0;
            background: #fff;
            width: 35%;
            overflow: hidden;
            overflow-x: scroll;
            border-right: 1px solid @border_color;
            border-left: 1px solid @border_color;

            table {
                border-right: none;
                border-left: none;
                width: 100%;

                th, td {
                    white-space: nowrap;
                }

                td {

                    &:last-child {
                        border-bottom: 0;
                    }
                }
            }
        }
    }
}
