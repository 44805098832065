.tooltipRow {
    position: relative;

    .tooltipContent {
        display: none;
        position: absolute;
        bottom: 105%;
        left: 0;
        background: #4d4f51;
        border-radius: 5px;
        width: 200px;
        padding: 15px 20px;
        color: #fff;
        line-height: 18px;
        .rems(13);
        z-index: 1000;
		text-transform: none;

        &:after {
            position: absolute;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
            content: '';
            width: 20px;
            height: 20px;
            background: #4d4f51;
        }
    }

    i {
        cursor: pointer;
    }
}
