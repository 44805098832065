.wrapper {

	@media all and (max-width: (@wrapper_width + @page_side_padding)) {
		padding-left: @page_side_padding;
		padding-right: @page_side_padding;
	}

	@media @mobile {
		padding-left: @page_side_padding;
		padding-right: @page_side_padding;
	}
}

.pageWrap {
	.clearfix();
	padding: 0;

	@media @flyout {
		padding: 0;
	}

}
