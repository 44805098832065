/**
 * Gallery
 */

.galleryWrap {
    position: relative;

    .gallery {
        .clearfix();

        li {

            @media @desktop {
                .grid(3);
            }

            @media @mobile, @tablet {
                .grid(2);
            }

            a {
                text-decoration: none;

                .imageWrap {
                    position: relative;
                    margin: 0 0 20px;

                    &:before, &:after {
                        opacity: 0;
                        transition: opacity 0.3s;
                        position: absolute;
                    }

                    &:before {
                        content: '';
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, 0.3);
                    }

                    &:after {
                        .fa(F067);
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        border-radius: 50%;
                        background: @highlight_color;
                        text-align: center;
                        line-height: 61px;
                        height: 58px;
                        width: 58px;
                        .rems(30);
                        color: #fff;
                        transform: translate(-50%, -50%);

                        @media @mobile {
                            .rems(22);
                            line-height: 42px;
                            height: 40px;
                            width: 40px;
                        }
                    }
                }
            }

            a {

                &:hover {

                    .imageWrap {

                        &:before, &:after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
