//Top header desktop widths
@header_left_width: 290px;
@header_center_width: 522px;
@header_right_width: auto;

@header_logo_max_width: 154px;
@header_search_form_max_width: 482px;


//Top header medium widths
@header_left_medium_width: 200px;
@header_center_medium_width: auto;
@header_right_medium_width: 100px;

@header_search_form_max_medium_width: 400px;


//Grid
@header_gutter_width: 20px;
@header_gutter_space: @header_gutter_width * 2;

@header_gutter_medium_width: 8px;
@header_gutter_medium_space: @header_gutter_medium_width * 2;

//Padding
@header_top_padding: 25px 0;
@header_top_flyout_padding: 16px 0 25px;
@header_top_mobile_padding: 16px 0 25px;

//Colours
@header_border_color: #fff;


//Header actions
@header_actions_width: 29px;
@header_actions_height: 29px;
@header_actions_font_size: 12;
@header_actions_line_height: 12px;

@header_actions_button_padding: 0;
@header_actions_border_radius: 50%;



@header_actions_gutter_width: 5px;
@header_actions_gutter_space: @header_gutter_width * 2;

@header_actions_gutter_medium_width: 5px;
@header_actions_gutter_medium_space: @header_gutter_width * 2;

//Header Actions Small
@header_actions_small_breakpoint: 400px;

@header_actions_small_width: 48px;
@header_actions_small_height: 48px;
@header_actions_small_font_size: 12;
@header_actions_small_line_height: 12px;


//search
@desktop_search_breakpoint: 700px;




/*
 * Header
 */

#header {
    float: none;
    clear: both;
    position: relative;
    background: #fff;
    //z-index: 5000;
    border: none;

    .transparentHeader & {

        margin-bottom: -96px;

        @media @above_flyout {
            margin-bottom: -128px;
        }
    }

    &.hasNotice {

        .transparentHeader & {

            margin-bottom: -136px;

            @media @above_flyout {
                margin-bottom: -176px;
            }
        }

    }

	.uspBanner {

		position: relative;

	}

    &.menuOpen {
        @media @flyout {
            z-index: 1 !important;
        }
    }

    .headerInnerWrap {
        float: none;
        clear: both;
        position: relative;
        background: #fff;
        border-bottom: none;
        //min-height: 142px;
        //
        //@media @flyout {
        //    min-height: 142px;
        //}

		@media @header_mobile_breakpoint {
            height: auto;
			min-height: 155px;
        }

        .transparentHeader & {
        	background: transparent;
        }
    }



    .headerTop {
        padding: @header_top_padding;
        border-bottom: none; //1px solid @header_border_color;
        position: relative;
        //z-index: 9999;
        max-height: 100%;


        @media @flyout {
            padding: 25px 0;
        }


		& > .wrapper {
			padding: 0;

			@media all and (max-width: (@wrapper_width + @page_side_padding)) {
				padding: 0 @page_side_padding;
			}
		}

    }

    .headerGrid {

        position: relative;
        min-height: 75px;

        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        width: ~"calc(100% + @{header_gutter_medium_space})";
        margin: 0 (0 - @header_gutter_medium_width);

        @media @header_mobile_breakpoint {
            flex-wrap: wrap;
        }


        .headerLeftMobile {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            order: 1;
            flex: 0 0 48px;

			@media @header_mobile_breakpoint {
				order: 1;
			}


            .flyoutButton {
                width: 48px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                background: #fff;
                border-radius: 50%;
                z-index: 600;
            }

            .desktopSearchWrap {
                margin-left: 60px;

                @media all and (max-width: 1100px) {
                    margin-left: 40px;
                }

                @media all and (max-width: 1000px) {
                    margin-left: 20px;
                }

                @media @header_mobile_breakpoint {
                    display: none;
                }
            }

        }

        .headerLeft, .headerRight, .headerCenter {
            width: auto;
            display: flex;
            align-items: center;
            padding: 0 @header_gutter_width;

        }


        .headerLeft {
            flex: 0 0 @header_left_width;
            order: 3;
            display: flex;
            justify-content: center;
            min-height: 54px;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            padding: 0;

            @media @header_mobile_breakpoint {
                top: 17%;
            }

            .mainMenu {
                li {
                    a {
                        z-index: 9999;
                    }
                }
            }

        }

        .headerCenter {
            flex: 1 1 @header_center_width;
            justify-content: center;
            z-index: 999;
            display: none;

            @media @flyout {
                flex: 1 1 auto;
				order: 2;
				display: none;
				justify-content: center;
                z-index: unset;
            }

			@media @header_mobile_breakpoint {
                display: block;
				order: 4;
				flex: 0 0 100%;
				padding: 16px 0 0;
			}

			search {
				width: 100%;
			}

        }

        .headerRight {
            flex: 1 1 @header_right_width;
            justify-content: flex-end;
            z-index: 999;

            @media @flyout {
                flex: 0 0 48px;
                padding: 0;
				order: 4;
            }

			@media @header_mobile_breakpoint {
				order: 3;
			}

            &:after {
                content: "";
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: rgba(0,0,0,0.56);
                z-index: 99998;
                display: block;
                visibility: hidden;
                opacity: 0;
                pointer-events: none;
                transition: visibility 200ms linear, opacity 200ms linear;
            }


			.flyoutWrap {
				@media @above_flyout {
					justify-content: flex-end;
				}
			}

            .flyoutActive & {
				/*
                &:after {
                    visibility: visible;
                    opacity: 1;
                    transition: visibility 200ms linear, opacity 200ms linear;
                }

				 */
            }


        }

    }

	.headerBelow {
		width: 100%;
		display: flex;
		padding: 30px 0 0;

		@media @flyout {
			padding: 0;
		}

		.navLeft {
			flex: 1 1 100%;
		}

		.navRight {
			flex: 0 0 300px;

			@media @flyout {
				display: none;
			}
		}
	}


    .logo {
        float: none;
        clear: both;
        display: inline-block;
        width: 100%;
        max-width: @header_logo_max_width;
        margin: 0;
        height: 100%;
        padding: 0;


        .imageWrap {
            width: auto;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 100%;
                height: auto;
            }

            img {
                width: auto;
                height: auto;
            }
        }
    }

    .searchForm {

        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        max-width: @header_search_form_max_width;

        @media @flyout {
            max-width: @header_search_form_max_medium_width;
        }

		@media @header_mobile_breakpoint {
			width: 100%;
			flex: 0 0 100%;
			max-width: 100%;
		}

    }

    .js_toggleSearch {

        &.active + .fullScreenSearch {
            opacity: 1;
            pointer-events: auto;
            visibility: visible;
            transition: opacity 200ms linear, visibility 200ms linear;
        }
    }


    .fullScreenSearch {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        align-items: center;
        justify-content: center;
        background: #fff;
        background: rgba(255,255,255,0.88);
        backdrop-filter: blur(10px);
        z-index: 99999999;

        opacity: 0;
        pointer-events: none;
        visibility: hidden;
        transition: opacity 200ms linear, visibility 200ms linear;

		display: none;

		.pageReady & {
			display: flex;
		}

        & > .wrapper {
            min-width: 640px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            position: relative;

            @media @mobile {
                min-width: 100%;
            }

            .searchForm {
                margin: 0 auto;
            }
        }

        .searchControls {
            display: inline-block;
            position: absolute;
            top: 24px;
            right: 24px;
            z-index: 999;
            width: 48px;
            height: 48px;

            .menuClose {
                width: 100%;
                height: 100%;
                background: @secondary_color;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                cursor: pointer;
                opacity: 0.8;
                transition: opacity 200ms linear;

                &:hover {
                    opacity: 1;
                    transition: opacity 200ms linear;
                }

                &:active {
                    opacity: 0.9;
                    transition: opacity 200ms linear;
                }


                svg {
                    width: 16px;
                    height: 16px;

                    &, * {

                        fill: #fff;
                    }
                }
            }

        }

    }


    .headerActions {

        width: 100%;
        height: 100%;
		display: flex;
        justify-content: flex-end;
        align-items: center;

        & > ul {

            display: flex;
            flex-direction: row;
			justify-content: flex-end;
            width: "calc(100% + @{header_actions_gutter_space})";
            margin: 0 (0 - @header_actions_gutter_width);

            @media @flyout {
                width: auto;
                margin: 0;
            }

            &:before, &:after {
                display: none;
            }


            & > li {

                padding: 0 @header_actions_gutter_width;
                display: flex;
                align-items: center;
				flex: 0 0 auto;

                @media @flyout {
                    padding: 0;
                }

                &.loginListItem {

                    @media @flyout {
                        display: none;
                    }

					.button {
						white-space: nowrap;
						margin: 0;
					}
                }

                &.miniBasketListItem {
                    margin: 0 0 0 20px;
                }


                .headerCtaWrap {

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: 29px;


					.basketButton {

						overflow: visible;

						.totalQty {
							display: flex;
							width: 14px;
							height: 14px;
                            background: @secondary_gradient;
                            color: #ffffff;
							border-radius: 50%;
							justify-content: center;
							align-items: center;
							position: absolute;
							bottom: 100%;
							right: 0;
							margin-bottom: -9px;
							margin-right: -5px;
							text-indent: 0.5px;
							.rems(9);
							line-height: 9px;
							letter-spacing: 0;

							&[v-cloak] {
								display: none !important;
							}
						}
					}

                }

                .actionButton, .miniBasket > li > a {

                    border-radius: @header_actions_border_radius;
                    width: @header_actions_width;
                    height: @header_actions_height;
                    .rems(@header_actions_font_size);
                    line-height: @header_actions_line_height;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: @header_actions_color;
					background: @header_actions_bg_color;
					border-color: @header_actions_bg_color;
                    text-decoration: none;
                    padding: @header_actions_button_padding;
                    position: relative;
                    transition: all 200ms linear;
                    z-index: 9;
					margin: 0;



					i {
						width: 100%;
						height: 100%;
						margin: 0;
					}

                    svg {

                        display: inline-block;
                        width: 15px;
                        height: 15px;
                        margin: -0.5px 0 0 0;
						transform: translate(-50%, -50%);


                    }

                    &:hover, &:active {
                        color: #fff;
						background: darken(@header_actions_bg_color, 8%);
						border-color: darken(@header_actions_bg_color, 8%);
                        transition: all 200ms linear;

                    }


                    .basketItemTotalPrice {
                        display: none !important;
                    }

                    &.miniBasketLink {

                        display: none;

                        .itemsInBasket {
                            display: flex;
                        }
                    }

                    .numberOfBasketItems {
                        position: absolute;
                        top: 2px;
                        right: 2px;
                        background: @alert_color;
                        color: #fff;
                        z-index: 2;
                        .rems(12);
                        line-height: 12px;
                        font-weight: bold;
                        width: 18px;
                        height: 18px;
                        padding: 4px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }


                    &.textAction {

                        border-radius: 0;
                        background: transparent;
                        width: auto;

                        @media @flyout {
                            width: 48px;
                            height: 48px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        i {
                            display: none;

                            @media @flyout {
                                display: flex;
                            }
                        }

                        &.loggedIn {
                            i {
                                display: flex;

                                @media @flyout {
                                    display: flex;
                                }

                                .transparentHeader & {
                                    @media @above_flyout {
                                        color: #fff;
                                    }
                                }

                                &:hover, &:active  {
                                    color: inherit;
                                    transition: color 200ms linear;
                                }
                            }

                            .actionText {
                                display: none !important;
                            }
                        }

                        .actionText {
                            display: block;
                            font-weight: 600;
                            color: @text_color;
                            transition: color 200ms linear;
                            .rems(17);
                            line-height: 24px;
                            letter-spacing: 0;

                            @media all and (max-width: 1300px) {
                                display: none;
                            }

                            .transparentHeader & {
                                @media @above_flyout {
                                    color: @text_color;
                                }
                            }

                            &:hover, &:active  {
                                color: @secondary_color;
                                transition: color 200ms linear;
                            }

                        }

                    }

                    &.active {
                        color: @secondary_color !important;
                    }

                }

            }

        }

		@media @above_flyout {
			display: block;
		}
    }

	.secondaryMenuWrap {

		.secondaryMenu {
			display: none;

			@media @above_flyout {
				display: block;
			}
		}

		.headerActions {

			display: flex;

			@media @above_flyout {
				display: none;
			}
		}
	}

    .miniBasketWrap {
        min-width: 29px;
    }

    .navigationBar {

        .outerNavBar {

            position: relative;

        }

        .navBarWrapper {

            display: flex;

            .navBarLeft {
                flex: 1 1 auto;
                display: flex;
                align-items: center;
            }

            .navBarRight {

                flex: 0 0 auto;
                margin-left: auto;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                @media @flyout {
                    width: 100%;
                    padding: 0;
                }

                @media @mobile {
                    padding: 0;
                }

                .menuList {

                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    @media @flyout {
                        width: 100%;
                        justify-content: flex-start;
                    }

                    &:before, &:after {
                        display: none;
                    }

                    & > li {
                        flex: 0 0 auto;
                        display: flex;
                        align-items: center;
                        margin-left: 16px;


                        &.flyoutButtonWrap {
                            align-self: flex-end;
                            display: flex;

                            @media @above_flyout {
                                display: none;
                            }
                        }

                        &.searchButtonTriggerWrap {
                            align-self: flex-end;
                            display: none;

                            @media all and (max-width: @desktop_search_breakpoint) {
                                display: flex;
                            }

                        }

                        &.telephoneButton {

                            @media @flyout {
                                margin-left: 0;
                                margin-right: auto;
                            }

                            a {
                                position: relative;
                                cursor: pointer;
                                text-decoration: none;
                                width: auto;
                                height: auto;
                                .rems(16);
                                line-height: 24px;
                                display: flex;
                                align-items: center;
                                color: @secondary_color;
                                transition: color 200ms linear;
                                padding: 16px 0;
                                font-weight: normal;

                                &:hover {
                                    color: darken(@secondary_color, 10%);
                                    transition: color 200ms linear;
                                }

                                i {

                                    font-size: inherit;
                                    line-height: inherit;
                                    margin-right: 8px;
                                    width: 24px;
                                    height: 24px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    color: inherit;
                                    transition: color 200ms linear;
                                    position: relative;
                                    top: -1px;

                                    @media @flyout {
                                        font-size: 20px;
                                        line-height: 20px;
                                        position: relative;
                                        top: 1px;
                                    }

                                    &:before {
                                        width: 100%;
                                        height: 100%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                    }
                                }

                                span {
                                    @media @flyout {
                                        line-height: 19px;
                                    }
                                }
                            }

                        }
                    }




                }


            }

        }

        .mobileSearchWrapper {

            .wrapper {

            }

            .searchForm {
                width: 100%;
                max-width: none;
            }


        }


    }


    .staticBlock {

        background: @secondary_color;
        color: #fff;
        padding: 8px 0;
        text-align: center;
        font-weight: bold;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @media @mobile {
            min-height: 40px;
        }

        .staticBlockInstance {
            .wrapper();
            background: inherit;
            color: inherit;
            text-align: inherit;
            font-weight: inherit;
            display: flex;
            align-items: center;
            justify-content: center;




            &:before, &:after {
                display: none;
            }

            &, p {
                .rems(14);
                line-height: 24px;
                color: inherit;
                text-align: inherit;
                font-weight: inherit;

                @media @mobile {
                    .rems(12);
                    line-height: 16px;
                    min-width: 100%;
                }

                &:last-of-type {
                    margin: 0;
                }

                @media @mobile {
                    p {
                        visibility: hidden;
                        opacity: 0;
                        will-change: visibility, opacity;
                        transition: visibility 200ms linear, opacity 200ms linear;

                        .wf-active & {
                            visibility: visible;
                            opacity: 1;
                            transition: visibility 200ms linear, opacity 200ms linear;
                        }
                    }
                }
            }


            a {
                color: inherit;
                text-decoration: none;
                font-weight: inherit;
                transition: opacity 200ms linear;

                &:hover {
                    color: inherit;
                    text-decoration: none;
                    opacity: 0.8;
                    transition: opacity 200ms linear;
                }
            }
        }

    }

}




/*
* Checkout and Assessment form Header
*/
.progressHeader {

    background: @primary_color;
    box-shadow: none;
    float: none;
    clear: both;
    width: 100%;
    padding: 24px 0;
    position: relative;
    z-index: 9999;


    .logoWrap {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-bottom: 24px;

        .logo {
            padding: 0;
        }

        .imageWrap {
            width: auto;
            height: auto;

            img {
                height: 48px;
                width: auto;

                @media @flyout {
                    width: 168px;
                    height: auto;
                }

            }
        }
    }


}
