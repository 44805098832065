/*
* Trust logos and banner
 */

@trust_banner_padding: 24px 0;
@trust_banner_bg_color: @tertiary_color;
@trust_banner_bg_color_mobile: @primary_color;

.trustLogos {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
	flex: 0 0 91px;
	gap: 16px;
	
	&:before, &:after {
		display: none;
	}

    li {

        a {

            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            img {

                width: auto;
                height: auto;

                &.squareLogo {
                    width: auto;
                    height: 56px;
                }

                &.rectangleLogo {
                    width: auto;
                    height: 36px;
                }

                &.medSellerLogo {
                    width: 67px;
                    height: 56px;
                }

                &.regLogo {
                    width: 91px;
                    height: 36px;
                }

                &.nhsLogo {
                    width: 138px;
                    height: 36px;
                }
            }

        }

    }

}

.trustLogoBanner {

    background: @trust_banner_bg_color;
    padding: @trust_banner_padding;
    float: none;
    clear: both;

    &.mobileOnly {
        display: none;
        padding: 16px 0;

        @media @mobile {
            display: block;
            background: @trust_banner_bg_color_mobile;
        }

    }

    .innerTrustBanner {
        display: flex;
        align-items: center;
        justify-content: center;

        @media @mobile {
            justify-content: center;
        }

        .trustLogos {
            width: auto;
        }
    }
}
