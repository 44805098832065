.sidebarNavPage {

    float: none;
    clear: both;
    position: relative;

    .innerPage {
        display: flex;

        @media @flyout {
            width: 100%;
            flex-wrap: wrap;
        }

        .pages {
            width: 100%;
            flex: 1 1 100%;
            padding: 128px 0 160px;

            @media @flyout {
                padding: 108px 0 40px;
            }

            @media @mobile {
                padding: 72px 0 40px;
            }
        }

        .sidebarNav {

            width: 450px;
            flex: 0 0;
            flex-basis: 450px;

            @media @tablet, @flyout {
                width: 350px;
                flex-basis: 350px;
            }

            @media @flyout {
                width: 100%;
                flex: 0 0 100%;
                display: none;
            }

            & + .pages {
                width: ~"calc(100% - 450px)";
                flex: 0 0;
                flex-basis: ~"calc(100% - 450px)";

                @media @tablet, @flyout {
                    width: ~"calc(100% - 350px)";
                    flex-basis: ~"calc(100% - 350px)";
                }

                @media @flyout {
                    width: 100%;
                    flex: 0 0 100%;
                }
            }

        }
    }

    .page {

        position: relative;
        display: block;
        max-width: 100%;
        margin: 0 auto;
        width: 100%;

        @media @tablet, @flyout {
            padding-left: 40px;
            padding-right: 40px;
        }

        @media @flyout {
            padding: 0;
        }


        .pageHeading {

            float: none;
            clear: both;
            width: 100%;
            padding: 0 0 40px;
            margin: 0 0 30px;
            //border-bottom: 1px solid @border_color;

            .iconWrap {

                display: flex;
                justify-content: flex-start;
                margin-bottom: 16px;

                .icon {
                    height: auto;
                    width: 48px;

                    svg {
                        width: 100%;
                        height: auto;
                    }
                }
            }

            h2, h1 {

                .rems(25);
                line-height: 29px;
                font-weight: normal;
                margin: 0;
                letter-spacing: 0.01px;

            }

            p {
                margin: 16px 0 0;
                .rems(14);
                line-height: 24px;
                letter-spacing: 0;
            }

        }

        .innerPageContent, .innerAssessmentContent, .productListContent {

            min-height: 200px;
            padding: 0;
            margin: 0;
            .rems(14);
            line-height: 24px;


            .mainContent {
                padding: 0 0 32px;
                font-size: inherit;
                line-height: inherit;
            }

            .wysiwyg {

                font-size: inherit;
                line-height: inherit;

                p, ul, ol {
                    font-size: inherit;
                    line-height: inherit;
                }
            }

            .fullscreenItems {
                width: 100%;
                max-width: 920px;
                margin-left: auto;
                margin-right: 0;

                & > .wrapper {
                    padding: 0 16px;
                }
            }

        }

    }

}

.sidebarMobileNav {

    margin-bottom: 40px;
    display: none;

    @media @flyout {
        display: block;
    }

    .sidebarTitle {

        position: relative;
        width: 100%;
        height: auto;
        margin: 0;
        cursor: pointer;
        .rems(18);
        line-height: 24px;
        padding: 16px 40px 16px 16px;
        background: @secondary_color;
        color: #fff;
        border-radius: @border_radius;
        transition: all 200ms linear;

        & + .sidebarContent {
            padding-top: 32px;
        }

        &:after {
            position: absolute;
            display: inline;
            .fa(f107);
            top: 50%;
            right: 8px;
            width: 32px;
            height: 32px;
            .rems(16);
            line-height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateY(-50%) rotate(0deg);
            transform-origin: center;
            transition: transform 200ms linear;
            color: inherit;
        }

        &.open {

            border-radius: @border_radius @border_radius 0 0;
            transition: all 200ms linear;

            &:after {
                transform: translateY(-50%) rotate(-180deg);
                transition: transform 200ms linear;
            }
        }

        & + .sidebarContent {
            padding: 16px;
            border: 1px solid @sidebar_border_color;
            border-top: none;
            border-radius: 0 0 @border_radius @border_radius;
        }

    }

    .sidebarContent {

        padding-top: 8px;
        display: none;

        ul {

            margin-top: 8px;

            li {
                position: relative;
                border-bottom: none;
                margin-bottom: 16px;

                &:last-of-type {
                    margin-bottom: 0;
                }

                a {
                    font-family: @primary_font;
                    color: @text_color;
                    text-decoration: none;
                    .rems(15);
                    line-height: 24px;
                    text-transform: none;
                    display: block;
                    font-weight: 500;
                    position: relative;
                    transition: color 200ms linear;

                    &:hover {
                        color: @highlight_color;
                        transition: color 200ms linear;
                    }
                }

                &.active {

                    a {
                        color: @highlight_color;
                        font-weight: bold;

                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.sidebarNav {

    color: @text_color;
    width: 100%;
    background: @light_color;
    padding: 128px 0 160px;
    position: relative;

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 100%;
        bottom: 0;
        width: 50vw;
        height: 100%;
        background: @light_color;
    }


    .sidebarIntro {

        display: block;
        width: 100%;
        padding: 0 69px 0 0;
        margin: 0 0 64px;

        @media @tablet, @flyout {
            padding: 0 40px 0 0;
        }

        @media @flyout {
            margin: 0 0 24px;
        }


        h1, h2 {

            .rems(35);
            line-height: 40px;
            margin: 0;
            font-weight: normal;
            letter-spacing: 0.01px;
            color: @text_color;

            @media @flyout {
                .rems(24);
                line-height: 32px;
            }

        }

        p {
            .rems(14);
            line-height: 24px;
            margin: 8px 0 0;
        }

        h6 {
            .rems(12);
            line-height: 16px;
            margin: 16px 0 0;

            @media @flyout {
                margin: 8px 0 0;
            }
        }




    }


    .sidebarPageNav {

        ul {

            li {

                position: relative;
                display: block;
                margin-bottom: 0;

                &:last-child {
                    margin-bottom: 0;
                }

                a {

                    position: relative;
                    display: block;
                    width: 100%;
                    .rems(16);
                    line-height: 24px;
                    font-weight: normal;
                    color: @text_color;
                    padding: 24px;
                    overflow: hidden;
                    border-radius: 0;
                    background: transparent;
                    box-shadow: none;
                    cursor: pointer;
                    transition: all @menu_transition_speed_out @menu_transition_easing;

                    &:before {
                        content: "";
                        display: block;
                        width: 4px;
                        height: 100%;
                        position: absolute;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        border-radius: 8px 0 0 8px;
                        background: @highlight_color;
                        opacity: 0;
                        transition: opacity @menu_transition_speed_out @menu_transition_easing;
                    }

                    &:hover, &:active {
                        color: @highlight_color;
                        transition: all @menu_transition_speed @menu_transition_easing;
                    }

                    h4, h6 {
                        font-size: inherit;
                        line-height: inherit;
                        font-weight: inherit;
                        color: inherit;
                        transition: color 200ms linear;
                        margin: 0;
                    }

                    h6 {
                        .rems(12);
                        line-height: 14px;
                        transition: color 200ms linear;
                        margin-top: 0;
                        letter-spacing: 0;
                        opacity: 0.64;
                        color: @text_color !important;
                    }


                    &.active {
                        background: transparent;

                        &:before {
                            opacity: 1;
                            transition: opacity @menu_transition_speed @menu_transition_easing;
                        }
                    }
                }

            }


        }

    }


}


.helpQuestion {

    float: none;
    clear: both;
    position: relative;

    .helperBoxRow {
        display: flex;
        flex-wrap: wrap;
        padding: 32px 0;
        border-top: 1px solid @border_color;
        border-bottom: 1px solid @border_color;

        .boxLeft {
            display: flex;
            align-items: center;
            width: ~"calc(100% - 130px)";
            flex: 1 1;
            flex-basis: ~"calc(100% - 130px)";
            padding: 0 24px 0 0;
        }

        .boxRight {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 130px;
            flex: 0 0 130px;

            .row {
                margin: 0;
                float: none;
                clear: both;
            }
        }

        p {
            margin: 0;
        }
    }

    .helperBoxContent {
        padding: 32px 0;
        border-bottom: 1px solid @border_color;
        display: none;
    }

}
