.changeCurrency {
    position: relative;
    display: block;

    &:hover {

        .currencySelector {
            opacity: 1;
            visibility: visible;
            transition-delay: 0ms;
        }
    }

    > a {
        display: block;
        color: @text_color;
        min-width: 100px;
        padding: 12px 25px;
    }

    i {
        padding-left: 3px;
    }

    .currencySelector {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background: @light_color;
        opacity: 0;
        visibility: hidden;
        z-index: 10;
        transition: opacity 400ms ease-in 0ms, visibility 400ms linear 400ms;

        li {
            display: block;

            a {
                display: block;
                padding: 5px 25px;
                white-space: nowrap;
            }

            img {
                padding-right: 5px;
            }
        }
    }
}

.multiPriceBlock {

    span {
        //display: none;
    }

    .show {
        //display: inline;
    }
}

#multiCurrencyTotal {
    //display: none;

    &.show {
        display: table-row;
    }


    .totalText {
        font-weight: 600;

        .noticeText {
            width: 100%;
            padding-top: 4px;
            .rems(11);
            line-height: 12px;
            font-weight: 600;
            opacity: 0.8;
        }
    }

    .totalVal {
        font-weight: 600;
    }

}
