/*
* News / Blog Styles
*/

.newsPageWrapper {
    padding: 96px 0 113px;
}

.blogGrid {
    padding: 40px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px 25px;

    @media all and (max-width: 982px) and (min-width: @mobile_width + 1px) {
        grid-template-columns: 1fr 1fr;
    }

    @media @mobile {
        grid-template-columns: 1fr;
    }

    &:before, &:after {
        display: none;
    }

    &.mobileGridSlider {
        @media @mobile {
            width: 100% !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
        }

        .articleWrap {

            @media @mobile {
                padding-left: 16px;
                padding-right: 16px;
            }

            .date {
                @media @mobile {
                    text-align: center;
                }
            }
            .title {
                @media @mobile {
                    text-align: center;
                }
            }
        }
    }

    //& > li {
    //    .col(3, 24px);
    //    margin-bottom: 80px;
    //
    //    @media @flyout {
    //        .col(2, 24px);
    //        margin-bottom: 40px;
    //    }
    //
    //    @media @mobile {
    //        .col(1, 24px);
    //        margin-bottom: 40px;
    //    }
    //}

    .articleWrap {

        color: @text_color;
        text-decoration: none;
		text-align: center;


        .date {
            .rems(12);
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0.24px;
            color: @text_color;
        }

        .title {
            .rems(14);
            font-weight: 400;
            line-height: 22px;
            color: @text_color;
            margin: 0 0 15px;
        }

        .summary {
            .rems(14);
            line-height: 16px;
            color: inherit;
            letter-spacing: 0;
            margin: 0;
        }

    }

    .imageWrap {
        margin: 0 0 20px;
        border-radius: 15px;
        overflow: hidden;

        img {
            aspect-ratio: 59 / 34;
            object-fit: cover;
        }
    }

    //a {
    //    &:hover, &:active {
    //
    //        .imageWrap {
    //
    //            img {
    //                transform: translate(-50%,-50%) scale(1.1);
    //                transition: transform 1000ms;
    //            }
    //
    //        }
    //
    //    }
    //}

    &.blogGridSmall {
        padding: 40px 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 40px 25px;

        @media all and (max-width: 982px) and (min-width: @mobile_width + 1px) {
            grid-template-columns: 1fr 1fr;
        }

        @media @mobile {
            grid-template-columns: 1fr;
        }

        .articleWrap {
            display: flex;
            flex-direction: column;

            .date {
                .rems(12);
                font-weight: 400;
                line-height: 14px;
                letter-spacing: 0.24px;
                color: @text_color;
            }

            .title {
                .rems(14);
                font-weight: 400;
                line-height: 22px;
                color: @text_color;
                margin: 0 0 15px;
            }

            .summary {
                display: none;
            }

        }


    }

}

.blogWrapper {

    padding: 56px 0 94px;
	background: #fff;


    @media all and (max-width: 1200px) {
        padding: 16px 0 94px;
    }

    @media @flyout {
        padding: 0 0 56px;
    }


    .articlesWrap {
        .wrapper();
    }


    .paginationWrap {
        padding: 0;
    }

}

.emptyBlog {

    padding: 80px 0;

    h2 {
        .rems(32);
        line-height: 40px;
        margin: 0 0 8px;
        text-align: center;
        font-weight: 400;
    }

    p {
        margin: 0;
        text-align: center;
    }
}

/*
* News Articles
*/
.blogArticleWrapper {

    float: none;
    clear: both;
    padding: 64px 0 0;
    position: relative;

    &:before {
        content: "";
        display: block;
        background: @tertiary_color;
        width: 100%;
        height: 500px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    .wrapper {
        max-width: 920px;
        position: relative;
        z-index: 2;
    }

    .articleTitleBanner {

        text-align: center;
        padding: 0 0 64px;
        color: @text_color;

        .articleDate {
            .rems(12);
            line-height: 14px;
            margin-bottom: 16px;
            text-align: inherit;
            color: inherit;
        }

        .articleTitle {
            .rems(35);
            line-height: 40px;
            margin: 0;
            text-align: inherit;
            font-weight: 400;
            color: inherit;
        }

    }

    .articleContent {

        .articleImage {
            background: transparent;
            width: 100%;
            height: auto;
            padding-bottom: 56.306%;
            position: relative;
            box-shadow: 0 8px 32px rgba(0,0,0,0.14);
            margin: 0;
            border-radius: 15px;
            overflow: hidden;

            img {
                width: 100%;
                height: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }
        }

        .blogContentContain {

            padding: 80px 0;
            margin: 0 auto;
            max-width: 680px;

            @media @flyout {
                padding: 40px 0;
            }

        }

    }
}


/*
* Recommended articles
*/
.recommendedArticlesWrap {

    background: @light_color;
    padding: 120px 0 96px;

    @media @flyout {
        padding: 56px 0;
    }

    .introBlock {
        margin-bottom: 64px;

        @media @flyout {
            margin-bottom: 56px;
        }
    }

    &.generalBlogBanner {

        padding: 70px 0;

        @media @flyout {
            padding: 56px 0;
        }

        @media all and (max-width: 900px) {
            padding: 56px 0;
        }

        @media @mobile {
            padding: 56px 0;
        }

    }

}



.blogProducts {

    float: none;
    clear: both;
    position: relative;

    .upsellItemsWrap {
        padding: 120px 0 88px;

        @media @flyout {
            padding: 56px 0;
        }
    }
}

.blogPostList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px 25px;

    @media all and (max-width: 982px)  {
        grid-template-columns: 1fr;
    }

    &:before, &:after {
        display: none;
    }

    .innerListing {
        text-align: center;

        .imageWrap {
            margin: 0 0 20px;
            border-radius: 15px;
            overflow: hidden;

            img {
                aspect-ratio: 59 / 34;
                object-fit: cover;
            }
        }

        .articleWrap {
            .articleTitle {
                .rems(14);
                font-weight: 400;
                line-height: 22px;
                color: @text_color;
                margin: 0 0 15px;

                > a {
                    color: @text_color;
                }
            }

            .articleDate {
                .rems(12);
                font-weight: 400;
                line-height: 14px;
                letter-spacing: 0.24px;
                color: @text_color;

                > a {
                    color: @text_color;
                }
            }
        }
    }
}

.latestBlogPosts {
    margin: 0 0 100px;

    @media @mobile {
        margin: 0 0 50px;
    }
}
