.aboutBanner {

    float: none;
    clear: both;
    padding: 160px 0;
    background: #fff;

    @media @flyout {
        padding: 80px 0;
    }

    @media @mobile {
        padding: @top_bottom_gutter_mobile 0;
    }

}

.innerAboutBanner {

    .introBlock {

        @media @flyout {
            margin-bottom: 40px;
        }

    }

    .aboutGrid {
        display: flex;

        @media all and (max-width: 1200px) {
            flex-wrap: wrap;
            width: 100%;
        }

        @media @flyout {
            max-width: 816px;
            margin: 0 auto;
        }

        .imageCol {
            width: 137px;
            flex: 0 0 137px;

            @media all and (max-width: 1200px) {
                width: 100%;
                flex: 0 0 100%;
            }

            .imageSection {
                width: 100%;
                max-width: 132px;
                margin: 0 auto 40px auto;

                .imageWrap {
                    filter: grayscale(100%);
                    opacity: 0.24;
                }
            }

            .imageWrap {
                width: 100%;
                position: relative;
                padding-bottom: 61%;

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    width: 100%;
                    height: auto;
                }
            }
        }

        .contentCol {
            padding: 0 136px;
            width: ~"calc(100% - 376px)";
            flex: 1 1;
            flex-basis: ~"calc(100% - 376px)";

            @media all and (max-width: 1400px) {
                padding: 0 56px;
            }

            @media all and (max-width: 1200px) {
                padding: 0 56px 0 0;
            }

            @media @flyout {
                width: 100%;
                flex: 0 0 100%;
                padding: 0;
            }
        }

        .pointsCol {
            width: 376px;
            flex: 0 0 376px;

            @media @flyout {
                width: 100%;
                flex: 0 0 100%;
                margin: 40px 0 0;
                padding: 40px 0 0;
                border-top: 1px solid @border_color;
            }
        }

        .pointList {

            &:before, &:after {
                display: none;
            }

            width: 100%;

            @media all and (max-width: 1200px) {
                max-width: 376px;
                margin: 0 auto;
            }

            & > li {
                width: 100%;
                display: block;
                position: relative;
                padding: 16px 23px 16px 38px;
                .rems(16);
                line-height: 24px;
                color: fade(@text_color, 64%);

                &:before {
                    .fa(f00c);
                    color: @tertiary_color;
                    position: absolute;
                    top: 21px;
                    left: 0;
                    width: 14px;
                    height: 24px;
                    .rems(14);
                    line-height: 24px;
                }
            }
        }

    }

}

.aboutBlockWrap {
    margin: 0 0 100px;

    .aboutBlock {
        display: flex;
        flex-flow: row nowrap;
        gap: 100px;
        background: @tertiary_gradient;
        border-radius: 15px;
        //overflow: hidden;
        padding: 0 78px;
        max-height: 400px;

        .imageWrap {
            position: relative;
            margin: -50px 0 0;

            img {
                max-width: 100%;
                height: auto;
            }
        }

        .contentWrap {
            max-width: 50%;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            padding: 60px 40px 60px 0;

            h3 {
                .rems(25);
                line-height: 25px;
                font-weight: 400;
                margin: 0 0 25px;
            }

            .desc {
                .rems(14);
                line-height: 22px;
                font-weight: 400;
                margin: 0 0 35px;
            }

            .buttonWrap {
                display: flex;
                flex-flow: row wrap;
                gap: 10px;

                .button {
                    margin: 0;
                }
            }
        }

        @media all and (max-width: 1000px) {
            display: block;
            flex-flow: unset;
            gap: unset;
            position: relative;
            padding: 0;

            .imageWrap {
                width: 100%;
                top: -50px;
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                align-items: center;
                opacity: 0.1;
                margin: unset;

                img {
                    max-width: unset;
                }
            }

            .contentWrap {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 30px;
                max-width: unset;
            }
        }
    }
}
