.aboutUsBanner {
    float: none;
    clear: both;
    padding: 0;


    h2 {
        margin-bottom: 8px;
        .rems(32);
        color: @primary_color;
        line-height: 40px;
        font-weight: normal;

        @media @flyout {
            text-align: center;
            max-width: 800px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 16px;
        }

        @media @mobile {
            .rems(24);
            line-height: 32px;
            text-align: left;
            margin-left: 0;
            margin-right: 0;
        }
    }

    h3 {
        margin-bottom: 32px;
        .rems(32);
        color: @primary_color;
        line-height: 40px;
        font-weight: normal;

        @media @flyout {
            margin-bottom: 24px;
        }

        @media @mobile {
            .rems(24);
            line-height: 32px;
            margin-bottom: 16px;
        }
    }

    p {
        color: @text_color;
        .rems(14);
        line-height: 24px;

        @media @flyout {
            text-align: center;
            max-width: 800px;
            margin-left: auto;
            margin-right: auto;
        }

        @media @mobile {
            text-align: left;
            margin-left: 0;
            margin-right: 0;
        }
    }

    a {
        text-decoration: none;
    }

    .buttonWrap {
        @media @flyout {
            text-align: center;
        }

        @media @mobile {
            text-align: left;
        }
    }


    .innerAbout {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: ~"calc(100% + @{gutter_space})";
        margin: 0 ( 0 - @gutter_width );


        @media @flyout {
            width: ~"calc(100% + @{gutter_space_mobile})";
            margin: 0 ( 0 - @gutter_width_mobile );
        }

    }

    .gridLeft, .gridRight {

        .button, buttonAlt {
            margin-bottom: 0;
            min-width: 160px;

            @media @mobile {
                width: 100%;
            }
        }

    }

    .gridLeft {

        width: 50%;
        padding: @top_bottom_gutter @gutter_width;
        order: 2;

        @media @flyout {
            padding: @top_bottom_gutter_mobile @gutter_width_mobile;
            width: 100%;
        }

    }

    .gridRight {
        position: relative;
        width: 50%;
        padding: @top_bottom_gutter @gutter_width;
        background: transparent;
        order: 1;

        @media @flyout {
            padding: @top_bottom_gutter_mobile @gutter_width_mobile;
            width: 100%;
        }

        .inner {
            position: relative;
            width: 100%;
            overflow: hidden;

            .largeImage {

                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: @border_radius;
                overflow: hidden;

                img {
                    width: 100%;
                    height: auto;
                    border-radius: @border_radius;

                    &[data-lazy-img-src] {
                        width: 56px !important;
                        height: auto;
                        margin: 0 auto;
                    }

                    &.desktopImage {
                        display: inline-block;

                        @media @mobile {
                            display: none;
                        }
                    }

                    &.mobileImage {

                        display: none;

                        @media @mobile {
                            display: inline-block;
                        }
                    }
                }

            }
        }
    }

    &.aboutBackground {

        background-repeat: no-repeat;
        background-position: right 0 bottom -32px;
        background-size: auto 100%;
        min-height: 432px;
        display: flex;
        align-items: center;

        @media all and (max-width: 1600px) {
            background-position: right -176px bottom -32px;
        }

        @media all and (max-width: 1440px) {
            background-position: right -88px bottom -32px;
        }

        @media all and (max-width: 1200px) {
            background-position: right -216px bottom -32px;
        }

        @media @flyout {
            padding-bottom: 27%;
            background-position: 50% bottom;
            background-size: 640px;
        }

        @media @mobile {
            padding-bottom: 0;
            background-image: none !important;
            min-height: 0;
            padding-top: 0;
        }


        h2 {

            @media @above_flyout {
                .rems(40);
                line-height: 56px;
                font-weight: bold;
                margin-bottom: 16px;
            }

        }

        p {

            @media @above_flyout {
                .rems(16);
                line-height: 24px;
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

        }

        .buttonWrap {
            @media @above_flyout {
                padding-top: 16px;
            }
        }

        .innerAbout {
            width: 100%;
            margin: 0;

            @media @flyout {
                width: 100%;
                margin: 0;
            }
        }

        .gridLeft {
            order: 1;
            padding: 48px 0;
        }
    }

    &.aboutWithImage {

        .gridLeft, .gridRight {

            display: flex;
            flex-direction: column;
            justify-content: center;

            .inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
            }

        }

        .gridRight {
            @media @flyout {
                display: none;
            }
        }

        .gridLeft {
            order: 1;
        }

        .gridRight {
            order: 2;
        }
    }

    &.swapSides {
        .gridLeft {
            order: 1;
        }

        .gridRight {
            order: 2;
        }

        &.aboutWithImage {
            .gridLeft {
                order: 2;
            }

            .gridRight {
                order: 1;
            }
        }

        &.aboutBackground {
            .gridLeft {
                order: 2;
            }

            .gridRight {
                order: 1;
            }
        }
    }


    &.faqWhyChoose {

        .gridRight, .gridLeft {
            .inner {
                height: 100%;
                display: flex;
                flex-direction: column;

                .buttonWrap {
                    margin-top: auto;
                }
            }
        }

        .gridRight {

            position: relative;
            background: @light_primary;

            &:after {
                content: "";
                width: 50vw;
                position: absolute;
                left: auto;
                right: 100%;
                top: 0;
                bottom: 0;
                background: lighten(@primary_color, 50%);
                display: block;
            }

        }

        &.swapSides {

            .gridRight {

                &:after {
                    left: 100%;
                    right: auto;
                }

            }

        }

    }
}
