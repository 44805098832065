@button_color: @primary_color;
@button_color_hover: darken(@button_color, 8%);
@button_color_focus: darken(@button_color, 8%);

@button_top_padding: 15px;
@button_side_padding: 15px;
@button_bottom_padding: 13px;


@button_border_radius: 50px;

/*
 * Button Styling
 */

.button {
    position: relative;
    display: inline-block;
    background: @button_color;
    border: none;
    margin: 0 0 16px;
    padding: @button_top_padding @button_side_padding @button_bottom_padding;
    cursor: pointer;
    text-align: center;
    outline: none;
    font-family: @primary_font;
    color: #fff;
    font-weight: 400;
    .rems(12);
    line-height: 12px;
    transition: all 200ms linear;
    border-radius: @button_border_radius;
    text-decoration: none;
    box-shadow: none;
    z-index: 1;
    overflow: hidden;
	text-transform: unset;
	letter-spacing: 0.24px;



    @media @mobile {
        .rems(14);
		line-height: 1.4117em;
        padding: @button_top_padding @button_side_padding @button_bottom_padding;
    }


    &:hover {
        color: #fff;
        background: @button_color_hover;
		border-color: @button_color_hover;
        transition: all 200ms linear;
    }

    &:focus {
        color: #fff;
        background: @button_color_hover;
		border-color: @button_color_hover;
        transition: all 200ms linear;
    }

    &:active {
        color: #fff;
        background: @button_color_focus;
        border-color: @button_color_focus;
        transition: all 200ms linear;
    }


    i {
        margin: 0 0 0 11px;
        width: auto;
        height: auto;
        display: inline-block;
        text-align: center;

        &:before {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
        }

        &.svgIcon {
            margin: 0 0 0 8px;

            svg {
                position: unset;
                transform: unset;
                left: unset;
                top: unset;
                height: 10px;
                width: 9px;
            }
        }
    }

    &.iconLeft {

        i {
            margin: 0 11px 0 0;
        }

    }

    &.buttonSmall {
        padding: 0.49em 1.25em;
		.rems(12);
		line-height: 1.2em;
    }

	&.buttonText {
		color: @text_color;
		background: transparent;
		border-color: transparent;
		position: relative;
		padding-top: 8px;
		padding-bottom: 8px;

		&:after {
			content: "";
			width: 80px;
			height: 2px;
			line-height: 2px;
			display: block;
			background: @primary_color;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
		}

		&:hover, &:active {
			color: @highlight_color;
			background: transparent;
			border-color: transparent;
		}
	}


    &[disabled] {
        color: #fff !important;
        background: @button_color !important;
        border-color: @button_color !important;
        pointer-events: none;
        opacity: 0.4;

        &.buttonOutlined {
            background: transparent !important;
            border-color: @button_color !important;
            color: @button_color !important;
        }

        &.buttonText {
            color: @button_color !important;
            background: transparent !important;
            border-color: transparent !important;
            opacity: 0.5;
        }

    }

    &.buttonOutlined {

        background: transparent;
        border: 1px solid @text_color;
        color: @text_color;

        &:hover {
            background: @secondary_gradient;
            border-color: transparent;
            color: #fff;
        }

        &:focus {
            background: @secondary_gradient;
            border-color: transparent;
            color: #fff;
        }

        &:active {
            background: @secondary_gradient;
            border-color: transparent;
            color: #fff;
        }

        &.buttonWhite {

            background: transparent;
            border-color: #fff;
            color: #fff;

            &:hover {
                background: #fff;
                border-color: #fff;
                color: @text_color;
            }

            &:focus {
                background: darken(#fff, 5%);
                border-color: darken(#fff, 5%);
                color: @text_color;
            }

            &:active {
                background: darken(#fff, 2%);
                border-color: darken(#fff, 2%);
                color: @text_color;
            }

        }

    }

	&.buttonRed {
		background: @alert_color;
		border-color: @alert_color;

		&:hover, &:focus {
			background: darken(@alert_color, 8%);
			border-color: darken(@alert_color, 8%);
		}

		&:active {
			background: darken(@alert_color, 4%);
			border-color: darken(@alert_color, 4%);
		}
	}

	&.buttonGreen {
		background: @success_color;
		border-color: @success_color;

		&:hover, &:focus {
			background: darken(@success_color, 8%);
			border-color: darken(@success_color, 8%);
		}

		&:active {
			background: darken(@success_color, 4%);
			border-color: darken(@success_color, 4%);
		}
	}

	&.buttonDark {
		background: @dark_button_color;
		border-color: @dark_button_color;
		color: #fff;

		&:hover, &:active {
			background: darken(@dark_button_color, 8%);
			border-color: darken(@dark_button_color, 8%);
			color: #fff;
		}

		&.darkBg {

			background: @button_color;
			border-color: @button_color;
			color: #fff;

			&:hover, &:active {
				background: @button_color_hover;
				border-color: @button_color_hover;
				color: #fff;
			}

		}
	}

    &.buttonLight {
        background: @tertiary_gradient;
        border: none;
        color: @text_color;

        &:hover, &:active {
            color: @text_color;
            background: darken(@tertiary_color_2, 2%);
        }
    }

    &.fullWidth {
        width: 100%;
    }

}



/*
* Button Alt
*/
.buttonAlt {
    .button();
    color: #fff;
    background: @secondary_color;
    border: 2px solid @secondary_color;

    &:hover {
        color: #fff;
        background: darken(@secondary_color, 10%);
        border: 2px solid darken(@secondary_color, 10%);
    }

    &:focus {
        color: #fff;
        background: darken(@secondary_color, 10%);
        border: 2px solid darken(@secondary_color, 10%);
    }

    &:active {
        color: #fff;
        background: darken(@secondary_color, 10%);
        border: 2px solid darken(@secondary_color, 10%);
        transition: all 200ms linear;
    }

    &.buttonOutlined {

        background: transparent;
        color: @secondary_color;

        &:hover {
            background: @secondary_color;
            border-color: @secondary_color;
            color: #fff;
        }

        &:focus {
            background: darken(@secondary_color, 10%);
            border-color: darken(@secondary_color, 10%);
            color: #fff;
        }

        &:active {
            background: darken(@secondary_color, 5%);
            border-color: darken(@secondary_color, 5%);
            color: #fff;
        }


    }

    &[disabled] {

        color: #fff !important;
        background: @secondary_color !important;
        border-color: @secondary_color !important;


        &.buttonOutlined {
            background: transparent !important;
            color: @secondary_color !important;
            border-color: @secondary_color !important;
        }

        &.buttonText {
            color: @secondary_color !important;
            background: transparent !important;
            border-color: transparent !important;
        }

    }

}


.buttonOptionSelected {
	color: #fff;
	border-color: @alert_color;
	background: @alert_color;


	&:hover {
		color: #fff;
		background: darken(@alert_color, 8%);
		border-color: darken(@alert_color, 8%);
	}

	&:focus {
		color: #fff;
		background: darken(@alert_color, 8%);
		border-color: darken(@alert_color, 8%);
	}

	&:active {
		color: #fff;
		background: darken(@alert_color, 4%);
		border-color: darken(@alert_color, 4%);
	}


	&[disabled] {
		color: #fff !important;
		background: @alert_color !important;
		border-color: @alert_color !important;
	}
}

/*
* Button alert
 */
.buttonAlert {
    .button();
    color: #fff;
    background: @alert_color;
    border: 2px solid @alert_color;

    &:hover {
        color: #fff;
        background: darken(@alert_color, 10%);
        border: 2px solid darken(@alert_color, 10%);
    }

    &:focus {
        color: #fff;
        background: darken(@alert_color, 10%);
        border: 2px solid darken(@alert_color, 10%);
    }

    &:active {
        color: #fff;
        background: darken(@alert_color, 8%);
        border: 2px solid darken(@alert_color, 8%);
        transition: all 200ms linear;
    }


    &[disabled] {

        color: #fff !important;
        background: @alert_color !important;
        border-color: @alert_color !important;


    }

}

/*
 * Button Groups
 */

.buttonGroup {

    float: none;
    clear: both;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    align-items: flex-start;

    &:before, &:after {
        display: none;
    }

    a {
        margin-right: 16px;
        height: auto;
        flex: 0 0 auto;

        &:last-of-type {
            margin-right: 0;
        }
    }

}

.buttonMini {
    .rems(10);
    padding: 1em;

}

.toggleButtonWrap {
    text-decoration: none;
}

.toggleButton {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    text-decoration: none;
    font-weight: 400;
    .rems(14);
    line-height: 14px;
    border-radius: 50%;
    overflow: hidden;
    text-shadow: none;

    &:before, &:after {
        .fa(f067);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        .rems(14);
        line-height: 14px;
    }

    &:before {
        position: relative;
        z-index: 2;
        text-decoration: none;
        color: @primary_color;
        transition: all 200ms linear;
        text-shadow: none;

        .darkBg & {
            color: #fff;
        }
    }

    &:after {
        position: absolute;
        z-index: 1;
        border-radius: 50%;
        background: @secondary_color;
        opacity: 0;
        width: 0;
        height: 0;
        transition: all 200ms linear;
    }

    .open & {
        &:before {
            content: "\f068";
            color: @primary_color;

            .darkBg & {
                color: #fff;
            }
        }
    }

    &:hover, &:active {
        &:after {
            width: 100%;
            height: 100%;
            opacity: 0.24;
            transition: all 200ms linear;
        }
    }

    &:active {
        &:after {
            opacity: 0.64;
        }
    }

    &.toggleAlt {

        &:before {
            color: @secondary_color;

            .darkBg & {
                color: @secondary_color;
            }
        }
    }

    &.toggleBordered {

        border: 1px solid rgba(23, 39, 65, 0.4);

        .darkBg & {
            border-color: #fff;
        }

    }
}


.buttonIcon {
	.button();

	background: transparent;
	margin: 0;
	border-color: transparent;
	color: @text_color;
	padding: 14px 0;
	text-align: left;
	width: auto;
	max-width: none;

	&:hover, &:active, &:focus {
		background: transparent;
		border-color: transparent;
		color: @highlight_color;
	}

	.svgIcon {
		display: inline-block;
		width: 14px;
		height: 14px;
		margin-left: 4px;
		color: @highlight_color;
		position: relative;
		top: 1px;

		svg {
			width: 100%;
			height: 100%;
		}
	}
}
