/*
 * FAQ Wrap
 */

.faqContent {
    margin: 0 0 20px;
}

.faqQuestionAnswerWrap {
    margin: 0 0 40px;

    @media @mobile {
        margin: 0 0 20px;
    }

    .faqQuestionAnswer {
        border-bottom: 1px solid @border_color;
    }
}
