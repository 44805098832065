/*
 * Group List Listing
 */

.groupListWrap {

    .groupList {
        .clearfix();
        margin-bottom: 30px;

        li {
            .clearfix();
            padding: 0 0 @gutter_width;
            margin: 0 0 @gutter_width;
            border-bottom: 1px solid @border_color;

            .imageWrap {
                float: right;
                width: 260px;
                margin-left: 20px;

                @media @mobile, @tablet {
                    width: 100%;
                    margin-left: 0;
                    margin-bottom: 20px;
                }

                & + .innerText {

                    @media @desktop {
                        width: ~"calc(100% - 290px)";
                    }
                }
            }

            .groupListDescription {
                margin: 0 0 @gutter_width;
            }

            .groupListTitle {
                line-height: 1;

                a {
                    color: @text_color;
                    text-decoration: none;
                }
            }

            .groupListReadMore {
                margin: 0 0 @gutter_width;
            }
        }
    }
}
