/*
 * Slick Styles
 */

.slick-slider {
    position: relative;
    display: block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    padding: 0 0 54px;


    .slick-track, .slick-list {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    .slick-track {
        -webkit-perspective: 1px;
        perspective: 1px;
    }
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;

    &:before, &:after {
        display: table;
        content: '';
    }

    &:after {
        clear: both;
    }
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    outline: 0;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);

    img {
        display: block;
    }

    &.slick-loading {

        img {
            display: none;
        }
    }

    &.dragging {

        img {
            pointer-events: none;
        }
    }
}

[dir='rtl'] .slick-slide {
    float: right;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

.slick-dots {
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    float: none;
    clear: both;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    li {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 0;
        width: auto;
        height: auto;
        opacity: 1;

        button {
            position: relative;
            height: 24px;
            width: 24px;
            margin: 0;
            cursor: pointer;
            text-indent: -9999px;
            background: transparent;
            border-radius: 0;
            border: none;
            outline: 0;
            opacity: 1;

            &:after {
                content: "";
                width: 8px;
                height: 8px;
                border-radius: 50%;
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                background: @primary_color;
                opacity: 0.24;
                transition: all 200ms linear;

                .darkBg & {
                    background: #fff;
                }
            }

            &:focus {
                border: none;
                outline: 0;
            }

            &:hover {
                &:after {
                    opacity: 1;
                    transition: all 200ms linear;
                }
            }

            &:active {
                &:after {
                    opacity: 1;
                    transition: all 200ms linear;
                }
            }
        }

        &.slick-active {

            button {
                background: transparent;
                opacity: 1;

                &:after {
                    opacity: 1 !important;
                }
            }
        }
    }
}

.slick-prev, .slick-next {
    height: 48px;
    width: 48px;
    cursor: pointer;
    text-indent: -9999px;
    background: transparent;
    border-radius: 0;
    border: none;
    outline: 0;
    opacity: 1;
    position: absolute;
    top: 50%;
    left: auto;
    right: 24px;
    transform: translateY(-50%);
    z-index: 5;
    margin: -24px 0 0 0;

    &.slick-prev {

        left: 24px;
        right: auto;

        &:after {
            .fa(f104);
            .rems(24);
            line-height: 24px;
            font-weight: 300;
            right: auto;
            left: 0;
        }
    }

    &:after {
        .fa(f105);
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        color: @primary_color;
        .rems(24);
        line-height: 24px;
        font-weight: 300;
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        left: auto;
        transform: translateY(-50%);
        text-indent: 0;
        opacity: 0.24;
        transition: all 200ms linear;

        .darkBg & {
            color: #fff;
        }
    }


    &:focus {
        border: none;
        outline: 0;
    }

    &:hover {
        &:after {
            opacity: 1;
            transition: all 200ms linear;
        }
    }

    &:active {
        &:after {
            opacity: 1;
            transition: all 200ms linear;
        }
    }
}

.slickNavigation {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    .slickNav {

        .slick-prev, .slick-next {
            position: relative;
            height: 24px;
            width: 24px;
            margin: 0 0 0 5px;
            cursor: pointer;
            text-indent: -9999px;
            background: transparent;
            border-radius: 0;
            border: none;
            outline: 0;
            opacity: 1;
            left: auto;
            right: auto;
            transform: none;



            &.slick-prev {

                left: auto;
                right: auto;
                transform: none;
                margin: 0 5px 0 0;

                &:after {
                    .fa(f104);
                    .rems(14);
                    line-height: 14px;
                    font-weight: 300;
                }
            }

            &:after {
                .fa(f105);
                width: auto;
                height: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                color: @primary_color;
                .rems(14);
                line-height: 14px;
                font-weight: 300;
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                text-indent: 0;
                opacity: 0.24;
                transition: all 200ms linear;

                .darkBg & {
                    color: #fff;
                }
            }



            &:focus {
                border: none;
                outline: 0;
            }

            &:hover {
                &:after {
                    opacity: 1;
                    transition: all 200ms linear;
                }
            }

            &:active {
                &:after {
                    opacity: 1;
                    transition: all 200ms linear;
                }
            }
        }
    }

    .slick-dots {
        position: relative;
        bottom: auto;
        left: auto;
        right: auto;
    }


}

.slideshowLeftArrow, .slideshowRightArrow {
    position: absolute;
    top: 50%;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    height: 40px;
    width: 40px;
    text-align: center;
    .rems(25);
    line-height: 40px;
    cursor: pointer;
    transition: background 0.5s;
    z-index: 4000;
    color: @text_color;
    margin: -20px 0 0;
    padding: 0 0 0 0.1em;

    &:focus {
        border: none;
        outline: none;
    }

    i {
        color: @text_color;
        display: block;
    }

    &:hover {
        background: #fff;
    }
}

.slideshowLeftArrow {
    padding: 0 0.1em 0 0;
    left: 30px;
}

.slideshowRightArrow {
    right: 30px;
}

.closeArrows() {
    .slideshowRightArrow, .slideshowLeftArrow {
        background: @light_color;
        transition: opacity 0.3s, background 0.5s;

        &:hover {
            opacity: 0.8;
        }

        i {
            color:  @primary_color;
        }
    }

    .slideshowLeftArrow {
        left: -50px;
    }

    .slideshowRightArrow {
        right: -50px;
    }
}
