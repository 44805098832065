.cardGridWrap {
    background: @light_color;
    padding: 80px 0 48px;

    @media @flyout {
        padding: 40px 0 40px;
    }

    .searchHeader {
        margin-bottom: 56px;
        text-align: center;

        & + .searchGrid {

            .noArticles {
                padding: 0 0 120px;
            }

        }
    }
}

.cardGrid {

    float: none;
    clear: both;
    position: relative;

    ul.cards {

        .colGroup(24px);

        @media all and (max-width: 580px) {
            .colGroup(16px);
        }

        & > li.card {

            .col(3, 24px);

            @media @flyout {
                .col(2, 24px);
            }

            @media all and (max-width: 700px) {
                .col(1, 16px);
            }


        }

    }

    .innerCards {

        width: 100%;
        height: 100%;
        background: #fff;
        padding: 24px 24px 32px 24px;
        display: flex;
        flex-direction: column;

        .cardHeader {
            padding: 0 0 38px;
            flex: 1 1 auto;
            min-height: 126px;
            display: flex;
            flex-wrap: wrap;

            @media all and (max-width: 1300px) {
                padding: 0 0 24px;
            }

            &.faqCardHeader {
                flex: 0 0 auto;
            }

            .headerBox {
                flex: 0 0 100%;
                width: 100%;
            }

            .iconBox {
                width: 64px;
                height: auto;

                @media all and (max-width: 1300px) {
                    width: 100%;
                }

                .icon {
                    text-align: center;
                    width: 64px;
                    height: 64px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @media all and (max-width: 1300px) {
                        margin-bottom: 4px;
                    }

                    svg {
                        width: auto;
                        height: auto;
                        max-width: 48px;
                        max-height: 48px;
                    }
                }

                & + .headerBox {
                    width: ~"calc(100% - 64px)";
                    flex: 1 1;
                    flex-basis: ~"calc(100% - 64px)";
                    padding-left: 18px;

                    @media all and (max-width: 1300px) {
                        width: 100%;
                        flex: 0 0;
                        flex-basis: 100%;
                        padding-left: 0;
                    }
                }
            }

        }

        .cardTitle {
            .rems(25);
            line-height: 29px;
            margin: 0;
            color: @text_color;
            letter-spacing: 0.01px;
        }

        .cardContent {

            .rems(14);
            line-height: 16px;
            color: @text_color;
            padding: 11px 0 0;
            letter-spacing: 0;

            p {
                font-size: inherit;
                line-height: inherit;
                color: inherit;
                margin: 0;
            }
        }

    }

    .cardLinks {

        flex: 1 0 auto;
        display: flex;
        flex-direction: column;

        .showAllWrap {
            padding: 16px 0 0;
            margin-top: auto;

            a {
                .rems(14);
                line-height: 16px;
            }

        }


        & > ul {
            margin: 0;
            padding: 0;
            display: block;
            width: 100%;

            &:before, &:after {
                display: none;
            }

            &.medicationsHidden {
                display: none;
                padding: 0;
                border-top: 1px solid rgba(23, 39, 65, 0.14);;
            }

            & > li {

                display: block;
                width: 100%;

                a {
                    padding: 19px 24px 19px 0;
                    .rems(14);
                    line-height: 16px;
                    font-weight: 500;
                    position: relative;
                    display: block;
                    width: 100%;
                    height: 100%;
                    border-bottom: 1px solid @border_color;
                    color: @text_color;
                    transition: color 200ms linear;


                    &:hover {
                        color: @secondary_color;
                        transition: color 200ms linear;
                    }

                    &:active {
                        color: darken(@secondary_color, 10%);
                        transition: color 200ms linear;
                    }

                    &:hover, &:active {
                        &:before {
                            transform: translate(6px, -50%);
                            transition: transform 200ms linear;
                        }
                    }

                    &:before {
                        .fa(f178);
                        .rems(14);
                        line-height: 16px;
                        position: absolute;
                        right: 6px;
                        top: 50%;
                        transform: translate(0, -50%);
                        color: @secondary_color;
                        transition: transform 200ms linear;
                    }
                }

                &:last-child {
                    a {
                        border: none;
                    }
                }
            }
        }
    }

}
