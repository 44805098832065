.resourcesHeader {
    padding-bottom: 24px;
    margin-bottom: 40px;
    border-bottom: 1px solid @border_color;

    .subHeadingContent {
        padding-top: 24px;
        margin-bottom: -24px;

    }

}

.attachmentsWrap {

    width: 100%;
    float: none;
    clear: both;

    .attachmentRow {
        display: flex;
        background: @light_color;
        border-radius: @border_radius;
        padding: 24px;
        margin-bottom: 24px;

        @media all and (max-width: 700px) {
            flex-wrap: wrap;
        }

        @media (min-width: 981px) and (max-width: 1100px) {
            flex-wrap: wrap;
        }

        .fileInfo {
            width: 100%;
            flex: 1 1 100%;
            display: flex;

            .fileIcon {
                display: flex;
                align-items: center;
                width: 34px;
                flex: 0 0 34px;

                i {
                    color: @text_color;
                    .rems(32);
                    line-height: 32px;
                }
            }

            .filename {
                display: flex;
                align-items: center;
                padding: 0 16px;
                flex: 1 1 100%;

                @media all and (max-width: 700px) {
                    padding: 0 0 0 16px;
                }

                @media (min-width: 981px) and (max-width: 1100px) {
                    padding: 0 0 0 16px;
                }

            }
        }

        .actions {
            width: 290px;
            flex: 0 0 290px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            @media all and (max-width: 700px) {
                width: 100%;
                flex: 0 0 100%;
                padding: 24px 0 0;
                margin-top: 24px;
                border-top: 1px solid rgba(0,0,0,0.1);
            }

            @media (min-width: 981px) and (max-width: 1100px) {
                width: 100%;
                flex: 0 0 100%;
                padding: 24px 0 0;
                margin-top: 24px;
                border-top: 1px solid rgba(0,0,0,0.1);
            }

            @media all and (max-width: 400px) {
                flex-wrap: wrap;
            }


            .fileMeta {
                width: 100%;
                flex: 1 1 100%;
                padding: 0 16px 0 0;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                @media all and (max-width: 700px) {
                    width: 50%;
                    flex: 0 0 50%;
                    padding: 0 8px 0 0;
                    justify-content: flex-start;
                }

                @media (min-width: 981px) and (max-width: 1100px) {
                    width: 50%;
                    flex: 0 0 50%;
                    padding: 0 8px 0 0;
                    justify-content: flex-start;
                }

                @media all and (max-width: 400px) {
                    width: 100%;
                    flex: 0 0 100%;
                    justify-content: center;
                    .rems(14);
                    line-height: 16px;
                }
            }

            .buttonWrapper {

                width: 160px;
                flex: 0 0 160px;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                @media all and (max-width: 700px) {
                    width: 50%;
                    flex: 0 0 50%;
                    padding: 0 0 0 8px;
                }

                @media (min-width: 981px) and (max-width: 1100px) {
                    width: 50%;
                    flex: 0 0 50%;
                    padding: 0 0 0 8px;
                }

                @media all and (max-width: 400px) {
                    width: 100%;
                    flex: 0 0 100%;
                    padding-top: 16px;
                    justify-content: center;
                }

                .button {
                    margin: 0;

                    @media all and (max-width: 400px) {
                        width: 100%;
                    }
                }
            }

        }
    }

}
