.headingStyle(@selector: h1) {

	//font-weight: 600;
	//text-transform: uppercase;

	& when (@selector = h1) {

		.rems(54);
		line-height: 64px;

		@media @flyout {
			.rems(35);
			line-height: 40px;
		}

		@media @mobile {
			.rems(35);
			line-height: 40px;
		}
	}

	& when (@selector = h2) {

		.rems(43);
		line-height: 56px;

		@media @flyout {
			.rems(25);
			line-height: 32px;
		}

		@media @mobile {
			.rems(25);
			line-height: 32px;
		}
	}

	& when (@selector = h3) {
		.rems(34);
		line-height: 44px;

		@media @flyout {
			.rems(22);
			line-height: 28px;
		}

		@media @mobile {
			.rems(22);
			line-height: 28px;
		}
	}

	& when (@selector = h4) {
		.rems(27);
		line-height: 32px;

		@media @mobile {
			.rems(20);
			line-height: 24px;
		}
	}

	& when (@selector = h5) {
		.rems(22);
		line-height: 28px;
		font-weight: 700;

		@media @mobile {
			.rems(18);
			line-height: 24px;
		}
	}

	& when (@selector = h6) {
		.rems(18);
		line-height: 24px;
		font-weight: 700;

		@media @mobile {
			.rems(17);
			line-height: 22px;
		}
	}
}


.itemsList() {
	float: none;
	clear: both;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-auto-columns: 20%;
	gap: 15px 15px;

	@media all and (max-width: 1240px) {
		grid-template-columns: repeat(4, 1fr);
		grid-auto-columns: 25%;
	}

	@media all and (max-width: 900px) {
		grid-template-columns: repeat(3, 1fr);
		grid-auto-columns: 33.333333%;
	}

	@media all and (max-width: 700px) {
		grid-template-columns: repeat(2, 1fr);
		grid-auto-columns: 50%;
	}

	@media all and (max-width: 400px) {
		grid-template-columns: 1fr;
		grid-auto-columns: 100%;
	}


	&:before, &:after {
		display: none;
	}


}
