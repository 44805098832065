.featuredBrandsWrap {
    clear:both;
    .clearfix();
    margin: 0 -10px 20px -10px;
    width: ~"calc(100% + 20px)";

    .featuredBrandsTitle {
        text-align: center;
        position: relative;
        margin: 0 0 35px;

        span {
            position: relative;
        }

        @media @mobile {
            margin: 0 0 20px;
            .headingStyle(@selector: h4);
        }

        .closeArrows();
    }

    .featuredBrands {
        .clearfix();
        .itemsList();
        margin: 0;
        width: 100%;

        li {
            position: relative;
            height: 80px;

            a {
                display: block;
            }

            .imageWrap {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}
