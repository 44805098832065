@menu_transition_speed: 400ms;
@menu_transition_speed_out: 600ms;
@menu_transition_easing: ease-in-out;

@fullscreen_breakpoint: 800px;
@fullscreen_mobile: ~"all and (max-width: @{fullscreen_breakpoint})";

.siteWrapper {
    &.fullscreen {
        min-height: 100vh;
        display: flex;
        flex-wrap: wrap;
    }
}

.siteInnerWrapper {

    .fullscreen & {
        flex: 1 1 100vh;

        @media @fullscreen_mobile {
            z-index: initial;
        }
    }


    &content {
        & > .wrapper {
            @media @fullscreen_mobile {
                padding: 0;
            }
        }
    }
}

.headerMini, .footerMini {
    flex: 0 0 auto;
    width: 100%;
    float: none;
    clear: both;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 5;

    @media @fullscreen_mobile {
        position: relative;
        width: 100%;
    }
}

.headerMini {
    top: 0;
    background: transparent;

    @media @fullscreen_mobile {
        background: @faded_primary;
        border-bottom: 1px solid rgba(0,0,0,0.024);
    }

    .headerGrid {
        display: flex;
        width: 100%;
        padding: 24px 0;

        .headerLeft {
            width: 50%;
            flex: 0 0;
            flex-basis: 460px;
            padding-right: 60px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            @media @fullscreen_mobile {
                padding-right: 12px;
                width: 50%;
                flex-basis: 50%;
            }
        }

        .headerRight {
            width: 50%;
            flex-basis: ~"calc(100% - 460px)";
            padding-left: 60px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            @media @fullscreen_mobile {
                padding-left: 12px;
                width: 50%;
                flex-basis: 50%;
            }
        }
    }

    .logo {
        float: none;
        clear: both;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 28px;
        display: flex;
        align-items: center;

        .imageWrap {

            position: relative;
            height: 100%;
            width: 100%;


            img, svg {
                height: 100%;
                width: auto;
                max-height: 28px;
                min-height: 0;
                min-width: 0;
                max-width: none;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);

                @media @fullscreen_mobile {
                    max-height: 32px;
                }

            }
        }
    }

    .headerLinks {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;

        a {
            .rems(14);
            line-height: 16px;
            margin-left: 8px;
        }
    }
}

.footerMini {
    bottom: 0;
    z-index: 3;

    @media @fullscreen_mobile {
        z-index: 1;
        border-top: 1px solid @border_color;
    }

    @media @mobile {
        padding-bottom: 100px;
    }

    .footerGrid {
        display: flex;
        width: 100%;
        padding: 24px 0;

        @media @fullscreen_mobile {
            flex-wrap: wrap;
        }

        .footerLeft {
            width: 50%;
            flex: 0 0;
            flex-basis: 460px;
            padding-right: 60px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            @media @fullscreen_mobile {
                padding: 0;
                width: 100%;
                flex-basis: 100%;
                justify-content: center;
            }
        }

        .footerRight {
            width: 50%;
            flex-basis: ~"calc(100% - 460px)";
            padding-left: 60px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            @media @fullscreen_mobile {
                padding-left: 0;
            }

            @media @mobile {
                padding: 0;
                width: 100%;
                flex-basis: 100%;
                justify-content: center;
                margin-top: 24px;
            }
        }
    }

    .footerColumn {
        display: flex;
        flex-direction: column;

        .footerLinks {
            flex: 0 0 100%;
        }

        .footerMeta {
            flex: 0 0 100%;
            padding-top: 8px;
        }
    }

    .footerLinks {

        @media @fullscreen_mobile {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        a {
            .rems(14);
            line-height: 16px;
            color: @text_color;
            transition: color 200ms linear;
            margin-right: 48px;
            font-weight: 500;

            @media @fullscreen_mobile {
                margin: 0 8px;
            }

            &:last-of-type {
                margin-right: 0;

                @media @fullscreen_mobile {
                    margin: 0 8px;
                }
            }

            &:hover {
                color: @secondary_color;
                transition: color 200ms linear;
            }

            &:active {
                color: darken(@secondary_color, 5%);
                transition: color 200ms linear;
            }
        }
    }

    .footerMeta {
        color: fade(@text_color, 64%);
        .rems(14);
        line-height: 16px;
        background: transparent;
        font-weight: inherit;

        @media @fullscreen_mobile {
            padding: 16px 0 0 !important;
        }

        @media @mobile {
            .rems(12);
            line-height: 20px;
        }

        a {
            color: inherit;

            strong {
                font-weight: inherit;
            }
        }

        .creditText {
            a {
                &:hover, &:active {
                    color: @mtc_blue;
                    opacity: 1 !important
                }
            }
        }
    }

    .footerLogo {

        width: 100%;
        display: flex;
        align-items: flex-end;

        @media @mobile {
            justify-content: center;
        }


        .imageWrap {
            height: 40px;
            width: 100%;
            position: relative;

            @media @mobile {
                width: 134px;
            }


            img {
                width: auto;
                height: 100%;
                max-height: 40px;
                max-width: none;
                min-width: 0;
                min-height: 0;
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }

    }
}

.fullscreenWrap {

    float: none;
    clear: both;
    display: flex;
    flex-flow: row nowrap;
    gap: 60px;
    width: 100%;
    height: 100%;
    min-height: 100vh;

    @media all and (max-width: @wrapper_width) and (min-width: @fullscreen_breakpoint + 1px) {
        gap: 30px;
    }

    @media @fullscreen_mobile {
        flex-flow: column nowrap;
        gap: 0;
        min-height: 0;
    }

    .fullscreenSidebar, .fullscreenContent {
        padding-top: 190px;
        padding-bottom: 136px;

    }



    .fullscreenSidebar {
        position: relative;
        width: 100%;
        flex: 0 0;
        flex-basis: 512px;
        padding-right: 64px;

        @media all and (max-width: 1100px) {
            padding-right: 48px;
            flex-basis: 340px;
        }

        @media @fullscreen_mobile {

            padding: 24px 24px 32px 24px;
            background: @faded_primary;
            width: ~"calc(100% + 48px)";
            flex: 0 0;
            flex-basis:  ~"calc(100% + 48px)";
            margin: 0 -24px;
            height: 112px;

        }

        &.assessmentSidebars {
            @media @fullscreen_mobile {
                padding: 8px 24px;
                height: auto;
            }
        }

        &.loginSidebar {
            @media @fullscreen_mobile {
                display: block;
                width: ~"calc(100% + 48px)";
                flex-basis: auto;
                max-width: none;
                margin: 0 -24px;
                height: auto;
                padding-top: 40px;
                padding-bottom: 40px;
            }

            .innerSidebar, .sidebarLogin {
                @media @fullscreen_mobile {
                    max-width: 920px;
                    margin: 0 auto;
                }

                .row {
                    .assistiveLink {
                        padding: 12px 0 0;
                    }
                }
            }

            .sidebarIntro {
            	margin: 0 0 16px 0;
            }
        }

        &:before {
            content: "";
            width: 50vw;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            background: @faded_primary;

            @media @fullscreen_mobile {
                display: none;
            }
        }

        .innerSidebar {
            position: relative;
            z-index: 1;

            &.navigationSidebarWrap {

                .sidebarIntro {
                    @media @fullscreen_mobile {
                        margin: 0;
                    }

                    p {
                        @media @fullscreen_mobile {
                            display: none;
                        }
                    }
                }

                .sidebarPageNav {
                    @media @fullscreen_mobile {
                        display: none;
                    }
                }

            }
        }
    }

    .fullscreenContent {
        position: relative;
        width: ~"calc(100% - 512px)";
        flex: 0 0;
        flex-basis: ~"calc(100% - 512px)";
        padding-left: 0;

        @media all and (max-width: @wrapper_width) and (min-width: @fullscreen_breakpoint + 1px) {
            padding-right: 30px;
        }

        @media all and (max-width: 1100px) {
            flex-basis: ~"calc(100% - 340px)";
            padding-left: 0;
        }

        @media @fullscreen_mobile {
            width: 100%;
            flex-basis: unset;
            padding: 40px 0 0;
        }

        @media @mobile {
            padding: 24px 0 0;
        }

    }

    .mainContent {

        & > .wrapper {
            @media @fullscreen_mobile {
                padding: 0;
            }
        }
    }

    .pages, .pageWrap {

        position: relative;
        display: block;

        .page {

            position: relative;
            display: block;
            max-width: 100%;
            width: 100%;
            margin: 0 auto;
            padding-bottom: 40px;

        }

        .assessmentFooter {
            position: relative;
            display: block;
            width: 100%;
            margin: 0 auto;
            padding-bottom: 0;

            .innerSectionFooter {
                width: 100%;
                max-width: 920px;
                margin: 16px 0 0 auto;
                border-top: 1px solid fade(@text_color, 8%);
                padding: 40px 0 0;

                @media @mobile {
                    border-top: 0;
                    padding: 0;
                    margin: 0;
                }
            }
        }

        .pageHeading {

            float: none;
            clear: both;
            width: 100%;
            padding: 0 0 40px;
            margin: 0 0 40px;
            position: relative;

            @media @fullscreen_mobile {
                margin: 0 0 40px;
            }

            @media @mobile {
                padding: 0 0 19px;
                margin: 0;
            }

            &:before {
                content: "";
                width: 100vw;
                height: 1px;
                position: absolute;
                bottom: 0;
                left: 0;
                display: block;
                background: fade(@text_color, 8%);

                @media @fullscreen_mobile {
                    width: 100%;
                }

                @media @mobile {
                    display: none;
                }
            }


            .innerPageHeader {
                width: 100%;
                max-width: 920px;
                margin: 0 0 0 auto;

                h1, h2 {


                    span {
                        &.sectionSteps {
                            display: none;
                        }
                    }
                    @media @mobile {
                        width: 100%;
                        display: flex;

                        span {
                            &.sectionTitle {
                                width: auto;
                                flex: 1 1 60%;
                            }

                            &.sectionSteps {
                                width: auto;
                                flex: 1 1 40%;
                                color: fade(@text_color, 32%);
                                .rems(18);
                                line-height: 24px;
                                display: block;
                                font-weight: normal;
                                text-align: right;
                            }
                        }
                    }
                }
            }

            .iconWrap {

                display: flex;
                justify-content: flex-start;
                margin-bottom: 16px;

                .icon {
                    height: auto;
                    width: 48px;

                    svg {
                        width: 100%;
                        height: auto;
                    }
                }
            }

            h2, h1 {

				.rems(32);
				line-height: 40px;
                margin: 0;
                letter-spacing: 0;
                font-weight: bold;
                color: @text_color;

                @media @mobile{
                    .rems(18);
                    line-height: 24px;
                }

            }

            p {
                margin: 8px 0 0;
                letter-spacing: 0;
                max-width: 448px;
                margin-right: auto;

                @media @mobile {
                    display: none;
                }
            }

        }

        .innerPageContent {

            min-height: 200px;
            padding: 0 0 40px;
            margin: 0 0 40px;
            border-bottom: 1px solid fade(@text_color, 8%);


            opacity: 1;
            visibility: visible;
            pointer-events: auto;
            transition: all 200ms linear;

            &.isLoading {
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
            }

        }

        .buttonWrapper {
            display: flex;
            align-items: center;

            @media @mobile {
                flex-wrap: wrap;
            }

            .prev, .next {
                margin: 0;
                flex: 0 0 auto;

                @media @mobile {
                    width: 100%;
                }
            }

            .prev {

                opacity: 0.5;

                &:hover, &:active {
                    opacity: 1
                }

                @media @mobile {
                    order: 2;
                    margin-top: 16px;
                    opacity: 0.48;

                    &:hover, &:active {
                        opacity: 1;
                    }
                }
            }

            .next {
                margin-left: auto;

                @media @mobile {
                    order: 1;
                    margin: 0;
                }
            }
        }


    }

}


.fullscreenSidebar {

    color: @text_color;


    .sidebarIntro {

        display: block;
        width: 100%;
        margin: 0 0 40px;

        @media @fullscreen_mobile {
            margin: 0 0 24px;
        }

        h1, h2 {

            .rems(24);
            line-height: 32px;
            margin: 0;
            letter-spacing: 0px;
            color: @text_color;

            @media @mobile {
                .rems(24);
                line-height: 32px;
            }

        }

        h1 {
            &.assessmentTitle {
                @media @fullscreen_mobile {
                    display: none !important;
                }
            }
        }

        p {
            margin: 8px 0 0;
        }

        h6 {
            .rems(12);
            line-height: 24px;
            margin: 16px 0 0;

            @media @fullscreen_mobile {
                margin: 8px 0 0;
            }
        }

        .nhsMembersLink {
            display: block;
            width: 100%;
            .rems(12);
            line-height: 16px;
            margin-top: 8px;
        }

        .statusBox {
            margin-top: 24px;
            padding-top: 24px;
            padding-bottom: 24px;
            border-top: 1px solid fade(@text_color, 8%);
            border-bottom: 1px solid fade(@text_color, 8%);
            margin-bottom: -40px;

            @media @fullscreen_mobile {
                display: none;
            }

            p {
                margin: 0 0 8px;
                font-weight: 500;
            }
        }


    }


    .sidebarPageNav {

        ul {

            &:before, &:after {
                display: none;
            }

            li {

                position: relative;
                display: block;
                margin-bottom: 24px;

                &:last-child {
                    margin-bottom: 0;
                }

                a {

                    cursor: default;
                    position: relative;
                    display: block;
                    width: 100%;
                    .rems(18);
                    line-height: 32px;
                    color: @text_color;
                    padding: 24px;
                    overflow: hidden;
                    border-radius: 8px;
                    background: fade(#fff, 0%);
                    box-shadow: none;
                    transition: all @menu_transition_speed_out @menu_transition_easing;

                    &:hover, &:active {
                        color: @text_color;
                        transition: all @menu_transition_speed @menu_transition_easing;
                    }


                    h4, h6 {
                        font-size: inherit;
                        line-height: inherit;
                        font-weight: inherit;
                        color: inherit;
                        transition: color 200ms linear;
                        margin: 0;
                    }

                    h4 {
                        font-weight: bold;
                    }

                    h6 {
                        .rems(12);
                        line-height: 16px;
                        color: inherit;
                        transition: color 200ms linear;
                        margin-top: 0;
                        letter-spacing: 0;
                        font-weight: 600;
                    }


                    &.active {
                        background: fade(#fff, 100%);
                        box-shadow: none;
                        transition: all @menu_transition_speed @menu_transition_easing;

                        &:before {
                            opacity: 1;
                            transition: opacity @menu_transition_speed @menu_transition_easing;
                        }
                    }
                }

            }


        }

    }


}


.helpBox {

    padding: 40px;
    margin: 40px 0 0;

    &.darkBg {
        background: @dark_color;
    }

    h3 {
        .rems(21);
        line-height: 24px;
        font-weight: 500;
    }

    p {
        .rems(16);
        line-height: 24px;
        margin: 0 0 24px;
    }


    .button, .buttonAlt {
        margin: 0;
    }


}

/*
* Footer buttons
 */
.fullscreenFormFooter {
    float: none;
    clear: both;
    width: 100%;
    padding: 40px 0 0;
    margin: 40px 0 0;
    border-top: 1px solid fade(@text_color, 8%);
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    @media all and (max-width: 840px) {
        flex-wrap: wrap;
        justify-content: center;
    }

    .button {
        min-width: 115px;


        @media all and (max-width: 840px) {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
            order: 1;
        }
    }

    .formLink {
        opacity: 0.64;
        color: @text_color;
        transition: opacity 200ms linear;
        margin-right: auto;

        @media all and (max-width: 840px) {
            margin-left: 0;
            margin-right: 0;
            margin-top: 16px;
            order: 2;
        }

        &.smallScreen {
            display: none;

            @media @fullscreen_mobile {
                display: inline-block;
            }
        }

        &:hover {
            opacity: 1;
            color: @text_color;
            transition: opacity 200ms linear;
        }

        &:active {
            opacity: 0.8;
            color: @text_color;
            transition: opacity 200ms linear;
        }
    }
}


.popupContent {
    display: none;
}

.innerPopupContent, .innerPopupContent.wysiwyg {

    .mainPopuheader {
        .rems(32);
        line-height: 40px;
        margin-bottom: 16px;

        @media @fullscreen_mobile {
            .rems(24);
            line-height: 32px;
            margin-bottom: 16px;
        }
    }

    h1,h2,h3,h4,h5,h6 {
        .rems(24);
        line-height: 32px;
        margin-bottom: 16px;

        @media @fullscreen_mobile {
            .rems(20);
            line-height: 30px;
        }
    }

}
