@video_banner_border_radius: @border_radius;
@video_banner_overlay_color: fade(@dark_primary, 40%);
@video_banner_overlay_color_hover: fade(@dark_primary, 20%);
@video_banner_box_shadow: 0 4px 8px rgba(0,0,0,0.24);
@video_banner_icon_color: #fff;
@video_banner_icon_font_size: 64;

.videoBanner {

    padding: @top_bottom_gutter 0;
    background: @light_color;
    float: none;
    clear: both;

    @media @mobile {
        padding: @top_bottom_gutter_mobile 0;
    }

    .videoWrap {

        display: flex;
        justify-content: center;

    }

    .videoBox {

        width: 100%;
        height: auto;
        max-width: 920px;
        position: relative;

        a {

            display: block;
            width: 100%;
            height: auto;
            position: relative;
            z-index: 2;
            border-radius: @video_banner_border_radius;
            overflow: hidden;
            box-shadow: @video_banner_box_shadow;

            &:before {
                .fa(f144);
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                color: #fff;
                font-weight: 400;
                z-index: 5;
                .rems(@video_banner_icon_font_size);
                line-height: 1.125em;
                width: 1.125em;
                height: 1.125em;
                display: flex;
                align-items: center;
                justify-content: center;
                text-shadow: 0 4px 8px rgba(0,0,0,0.24);
                transition: text-shadow 400ms;
            }

            &:after {
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 3;
                background: @video_banner_overlay_color;
                transition: all 400ms;
            }

            .imageWrap {

                img {
                    display: inline-block;
                    width: 100%;
                    height: auto;

                    &.imageDesktop {
                        display: inline-block;

                        @media @mobile {
                            display: none;
                        }
                    }

                    &.imageMobile {
                        display: none;

                        @media @mobile {
                            display: inline-block;
                        }
                    }
                }

                img[data-lazy-img-src] {
                    width: 40px;
                    height: auto;
                }

            }

            &:hover {
                &:before {
                    text-shadow: 0 4px 8px rgba(0,0,0,0.40);
                    transition: text-shadow 400ms;
                }

                &:after {
                    background: @video_banner_overlay_color_hover;
                    transition: all 400ms;
                }
            }

            &:active {
                &:before {
                    text-shadow: 0 0 0 rgba(0,0,0,0);
                    transition: all 200ms;
                }
            }



        }

    }



}
