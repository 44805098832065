/*
 * CSS Browser
 */

@css_browser_menu_background: @mtc_blue;

.cssBrowserMenuWrap {
    .clearfix();
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 7000;

    @media @mobile {
        position: relative;
        top: auto;
        left: auto;
        width: 100%;
        display: block;
    }

    a {
        text-decoration: none;
    }

    .cssBrowserMenuTrigger {
        border: 0;
        padding: 5px 15px;
        background: @css_browser_menu_background;
        color: #fff;
        .ems(16);
        width: 250px;
        transition: background-color 0.5s;
        float: left;
        position: relative;
        cursor: pointer;

        @media @mobile {
            width: 30px;
            height: 30px;

            span {
                display: none;
            }
        }

        &:hover {
            background: lighten(@css_browser_menu_background, 10%);
        }

        &:after {
            .fa(f0c9, @dark_text_color);
            position: absolute;
            top: 50%;
            .transform(translateY(-50%));
            right: 20px;
            color: #fff;

            @media @mobile {
                left: 50%;
                right: auto;
                .transform(translate(-50%, -50%));
            }
        }
    }

    ul {
        display: none;
        .clearfix();
        margin: 0;
        padding: 0;
        background: @css_browser_menu_background;

        ul {
            background: lighten(@css_browser_menu_background, 5%);

            ul {
                background: lighten(@css_browser_menu_background, 10%);

                ul {
                    background: lighten(@css_browser_menu_background, 15%);
                }
            }
        }

        li {
            list-style-type: none;
            width: 100%;
            margin: 0;
            padding: 0;
            position: relative;

            &.hasChildren {

                &:after {
                    .fa(f107, @dark_text_color);
                    position: absolute;
                    top: 5px;
                    right: 20px;
                    color: #fff;
                    .transform(rotate(-90deg));
                    .transition(transform 0.3s);
                }

                &.active {

                    &:after {
                        .transform(rotate(0deg));
                    }
                }
            }

            a {
                display: block;
                padding: 5px 20px;
                color: #fff;
            }
        }
    }

    > ul {
        position: relative;
        clear: both;
        width: 250px;

        @media @mobile {
            width: 100%;
        }
    }
}
