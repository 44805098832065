@banner_padding: 120px 0;
@banner_padding_flyout: 80px 0;
@banner_padding_mobile: 40px 0;
@banner_bg_color: @dark_color;
@banner_color: #fff;

.largeBanner {

    padding: @banner_padding;
    background: @light_color;
    color: @banner_bg_color;
    float: none;
    clear: both;

    &.darkBg {
        background: @banner_bg_color;
        color: @banner_color;
    }

    &.lightBg {
        background: @light_primary;
    }

    @media @flyout {
        padding: @banner_padding_flyout;
    }

    @media @mobile {
        padding: @banner_padding_mobile;
    }


    .introBlock {

        margin-bottom: 32px;

        .innerIntro {

            .introTitle {
                .rems(40);
                line-height: 56px;

                @media @mobile {
                    .rems(24);
                    line-height: 32px;
                }
            }

            .introContentWrap {
                margin-bottom: 0;
                margin-top: 24px;
                max-width: 100%;
                .rems(14);
                line-height: 20px;
            }

        }

        & + .buttonWrap {
            margin-top: 24px;
            margin-bottom: 0;
        }

    }


    .buttonWrap {

        width: 100%;
        text-align: center;

        a {
            margin: 0;
            padding-left: 32px;
            padding-right: 32px;

            @media @mobile {
                width: 100%;
            }

        }
    }

}
