/*
 * Vanilla Datepicker Styles
 */

.row {

    .datepickerWrap {
        position: relative;
        .clearfix();
        max-width: 100%;
        clear: both;


        input[type="text"] {
            float: none;
        }

        &:after {
            .fa(f073);
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            z-index: 1000;
        }
    }
}

.datePicker {
    cursor: pointer;
    width: 65%;
}

.ui-datepicker {
    background: @light_color;
    box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 0 3px 3px;
    z-index: 10000 !important;
	


    .ui-datepicker-header {
        position: relative;
        z-index: 999;
        overflow: hidden;

        .ui-datepicker-title {
            .headingStyle(h6);
            font-size: 16px;
            line-height: 16px;
            padding: 15px 0;
            text-align: center;
            position: relative;
            border-bottom: 1px solid @border_color;
        }

        .ui-datepicker-prev, .ui-datepicker-next {
            transform: translateY(-50%);
            position: absolute;
            top: 50%;
            cursor: pointer;
            z-index: 999;
            overflow: hidden;
			color: @text_color !important;

            span {
                display: none;
            }
			
        }

        // Next Button
        .ui-datepicker-next {
            right: 10px;

            &:after {
                .rems(22);
                .fa(f105);
                right: 0;
            }
        }

        // Previous Button
        .ui-datepicker-prev {
            left: 10px;

            &:after {
                .rems(25);
                .fa(f104);
                left: 0;
            }
        }
    }

    .ui-datepicker-calendar {
        border: 0;
        table-layout: auto;
        background: #fff;
        margin: 0;

        thead {
            color: @highlight_color;
        }

        th, td {
            padding: 7.5px 9px;
            cursor: pointer;
            text-align: center;
            z-index: 100;
            position: relative;
            .rems(12);
            font-family: @primary_font;
            border: 0;
            font-weight: normal;

            &.ui-state-disabled {
                opacity: 0.5;
            }
        }

        td {

            a {
                
                display: block;
				color: @text_color;

				&:hover, &:active, &:focus {
					color: @highlight_color;
				}
            }

            &.ui-state-disabled {
                border: 0;
            }
        }

        .ui-datepicker-today {
            background: fade(@primary_color, 30%);
        }

        .ui-datepicker-current-day {
            background: @primary_color;

            a {
                color: #fff;
            }
        }
    }

    .ui-datepicker-buttonpane {
        padding: 5px 10px;
        .clearfix();

        button {
            background: 0;
            border: 0;
            padding: 0;
            margin: 0;
            cursor: pointer;

            &.ui-priority-secondary {
                float: left;
            }

            &.ui-priority-primary {
                float: right;
            }
        }
    }

    .ui-slider {
        position: relative;
        background: fade(@text_color, 50%);
    }

    .ui-slider-horizontal {
        height: 12px;
    }

    .ui-slider-handle {
        position: absolute;
        z-index: 2;
        width: 20px;
        height: 20px;
        cursor: default;
        -ms-touch-action: none;
        touch-action: none;
        top: -4px;
        margin: 0 0 0 -6px;
        background: @text_color;
        font-weight: normal;
    }

    .ui_tpicker_time_label, .ui_tpicker_hour_label, .ui_tpicker_minute_label {
        padding: 10px;
        .rems(14);
        font-weight: bold;
        clear: both;
    }

    .ui_tpicker_hour, .ui_tpicker_minute {
        display: block;
        margin: 0 20px 10px;
        width: ~'calc(100% - 40px)';
    }

    .ui_tpicker_time_input {

        &[disabled] {
            background: none;
            border: none;
        }
    }

    .ui_tpicker_time_label {
        float: left;
        width: 100px;
    }

    .ui_tpicker_time {
        float: left;
        width: ~'calc(100% - 110px)';
    }

    a {
        text-decoration: none;
    }
}

.ui_tpicker_unit_hide {
    display: none;
}
