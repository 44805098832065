.wrapper404 {

    position: relative;
	padding: 96px 0 120px;
    
}

.content404 {
    position: relative;
    text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	min-height: 20vh;

	
	.message404 {
		
	}

    .logo404 img {
        margin-bottom: 50px;
    }
}
