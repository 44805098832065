/*
 * HTML Tag
*/

html {
	font-size: ~"@{base_font_size}px";
	scroll-behavior: smooth;

    &:not(.stickyPage) {
        overflow-y: scroll;

        .siteWrapper {
            overflow: hidden;
        }
    }
}

html {

	&:before {
		content: 'tablet: @{wrapper_width}, mobile: @{mobile_width}, flyout: @{flyout_breakpoint}, medium: @{medium_width}, product_small: @{product_sidebar_mobile_breakpoint}, menu: @{header_mobile_breakpoint}, sidebar: @{sidebar_breakpoint}';
		position: absolute;
		top: 0;
		visibility: hidden;
	}
}

/*
* Styleguide helpers
 */
.styleGuideWrap {

	.darkBg {
		background: @dark_color;

		.styleGuideSectionTitle {
			color: #fff;
		}
	}

	.lightBg {
		background: @light_color;
	}
}

.spacers {
	height: 4px;
	background: @primary_color;
	width: 100%;
	float: none;
	clear: both;

	&.white {
		background: #fff;
	}
}

.screenreader {
	position: absolute !important;
	height: 1px; width: 1px;
	overflow: hidden;
	clip: rect(1px 1px 1px 1px);
	clip: rect(1px, 1px, 1px, 1px);
}

#tp-widget-loader {
	display: none !important;
}
