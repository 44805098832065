//Header step sizing
@header_step_size: 16px;
@header_step_complete_size: 32px;
@header_step_active_size: 16px;
@header_step_width: 128px;
@header_step_width_mobile: 90px;
@header_step_width_mobile_small: 80px;

//Border
@header_step_border_width: 2px;
@header_step_border_color: #fff;

//Header step colours
@header_step_highlight_color: red;
@header_bg_color: @primary_color;


.headerProgress {

    display: flex;
    justify-content: center;
    position: relative;
    float: none;
    clear: both;

    .headerProgressBar {

        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        position: relative;

        &:after {
            content: "";
            width: (@header_step_width * 3) - 8px;
            position: absolute;
            height: @header_step_border_width;
            background: @header_step_border_color;
            transform: none;
            left: 65px;
            top: 16px;
            opacity: 0.32;
            z-index: 1;

            @media @mobile {
                left: 56px;
                width: (@header_step_width_mobile * 3) - 8px;
            }

            @media all and (max-width: 360px) {
                width: (@header_step_width_mobile_small * 3) - 16px;
            }

        }

        &:before {
            content: "";
            height: @header_step_border_width;
            background: #fff;
            display: block;
            position: absolute;
            width: @header_step_width + (@header_step_size / 2);
            top: 16px;
            left: 56px;
            z-index: 2;

            @media @mobile {
                width: @header_step_width_mobile - @header_step_size;
            }

            @media all and (max-width: 360px) {
                width: @header_step_width_mobile_small - @header_step_size;
            }

        }

        &.step1 {
            &:before {
                width: 0;
            }
        }

        &.step2 {
            &:before {
                width: (@header_step_width + (@header_step_size / 2));

                @media @mobile {
                    width: @header_step_width_mobile - 8px;
                }

                @media all and (max-width: 360px) {
                    width: @header_step_width_mobile_small - 8px;
                }
            }
        }

        &.step3 {
            &:before {
                width: (@header_step_width * 2) + (@header_step_size / 2);

                @media @mobile {
                    width: (@header_step_width_mobile * 2) - 8px;
                }

                @media all and (max-width: 360px) {
                    width: (@header_step_width_mobile_small * 2) - 8px;
                }
            }
        }

        &.step4 {
            &:before {
                width: (@header_step_width * 3) + (@header_step_size / 2);

                @media @mobile {
                    width: (@header_step_width_mobile * 3) - 8px;
                }

                @media all and (max-width: 360px) {
                    width: (@header_step_width_mobile_small * 3) - 8px;
                }

            }
        }


        .progSection {

            padding-left: 8px;
            padding-right: 8px;
            position: relative;
            z-index: 3;
            width: @header_step_width;

            @media @mobile {
                width: @header_step_width_mobile;
            }

            @media all and (max-width: 360px) {
                width: @header_step_width_mobile_small;
            }


            .progBubble {

                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;


                .innerProgBubble {
                    width: 32px;
                    height: 32px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:before {
                        content: "";
                        width: 16px;
                        height: 16px;
                        display: block;
                        position: absolute;
                        z-index: 1;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        border-radius: 100px;
                        background: @header_bg_color;
                        border: @header_step_border_width solid @header_bg_color;
                        box-sizing: border-box;

                    }

                    &:after {
                        content: "";
                        width: 16px;
                        height: 16px;
                        border: @header_step_border_width solid @header_step_border_color;
                        background: @header_bg_color;
                        border-radius: 100px;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        z-index: 10;
                        opacity: 0.32;
                        box-sizing: border-box;

                    }

                    &.bubbleSelected {

                        opacity: 1;

                        &:after {
                            .fa('f00c');
                            color: @header_bg_color;
                            border: none;
                            width: 32px;
                            height: 32px;
                            opacity: 1;
                            background: #fff;
                            .rems(14);
                            line-height: 14px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }

                }

            }

            .progText {
                .rems(14);
                font-weight: normal;
                line-height: 16px;
                text-align: center;
                padding-top: 8px;
                opacity: 0.32;
                color: #fff;

                @media all and (max-width: 360px) {
                    .rems(13);
                }
            }

            &.progActive {

                .progBubble {

                    .innerProgBubble {

                        &:after {
                            opacity: 1;
                            background: #fff;
                            border: @header_step_border_width solid #fff;
                        }
                    }

                }

                .progText {
                    opacity: 1;
                    font-weight: 500;
                }

            }
        }

    }

}
