.teamContent {
    margin: 0 0 30px;
}


.teamWrap {

    background: @light_color;
    padding: 80px 0;

    @media @flyout {
        padding: 40px 0;
    }

    .teamList {
        .colGroup();
        letter-spacing: normal;
        margin-bottom: -32px;

        @media @mobile {
            .colGroup();
            text-align: center;
        }
    }

    .teammate {
        .col(4);
        max-width: 500px;
        display: inline-block;


        @media @mobile {
            .col(2);
        }

        @media all and (max-width: 420px) {
            .col(1);
            float: none;
        }


        .imageWrap {
            text-align: center;
        }

        img {
            margin-bottom: 0;
        }
    }

    .infoWrap {
        padding: 24px 0 0;
        text-align: center;
        background: transparent;

        h4 {
            color: @text_color;
            .rems(21);
            line-height: 24px;
            margin-bottom: 8px;
        }

        h5 {
            .rems(12);
            line-height: 14px;
            color: #fff;
            margin-bottom: 0;
            color: @text_color;
            opacity: 0.64;
        }

        h6 {
            .rems(12);
            line-height: 14px;
            color: #fff;
            margin-bottom: 0;
            margin-top: 8px;
            color: @text_color;
            opacity: 0.64;
        }

        a {
            display: inline-block;
            color: #fff;
            text-decoration: none;
            transition: color 0.3s;

            &:hover {
                color: @secondary_color;
            }
        }

        .bioLinks {
            display: block;
            text-align: center;
            color: #fff;
            .rems(12);

            i {
                margin-right: 5px;
                .rems(16);
                vertical-align: middle;
            }
        }
    }

    &.teamBanner {
        .infoWrap {
            h6 {
                display: none;
            }
        }
    }
}

.teamBio {
    display: none;
}

.overlayContent {

    .bioInfo {
        float: left;
        width: ~"calc(100% - 240px)";

        @media @mobile {
            width: 100%;
        }
    }

    .bioDetails {
        float: right;
        width: 220px;
        background: @light_color;
        padding: @gutter_width;
        margin: 0 0 0 @gutter_width;

        @media @mobile {
            width: 100%;
            margin-left: 0;
            margin-bottom: @gutter_width;
        }

        img {
            display: block;
            margin: 0 0 10px;
        }

        a {
            display: block;
            text-decoration: none;
        }
    }
}

.ctaTabs.teamBanner {

    padding: 120px 0;

    @media @flyout {
        padding: 80px 0;
    }

    @media @mobile {
        padding: 40px 0;
    }

    .wrapper {
        max-width: 1264px;
    }

    .introBlock {
        margin: 0 0 40px;

        @media @mobile {
            margin: 0 0 24px;
        }
    }

    .ctaTabsWrap {

        position: relative;
        width: 100%;
        text-align: center;


        .ctaTabsNav {
            width: auto;
            margin: 0 auto;
            display: inline-block;

            button {
                padding-left: 20px;
                padding-right: 20px;
            }

            .chosen-container {
                min-width: 600px;

                @media @mobile {
                    width: 100%;
                    min-width: 280px;
                }
            }
        }

        .ctaTabs {

            .tabContentWrapper {

                padding-top: 55px;

            }

        }


    }

}
