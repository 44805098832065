/*
* Sidescroller
 */
.hs__wrapper {

	display: block;
	width: 100%;

	.hs {
		display: flex;
		justify-content: flex-start;
		gap: 0 16px;
		overflow-y: auto;
		-ms-overflow-style: none;
		scrollbar-width: none;
		padding: 0 32px;


		@media all and (max-width:(@wrapper_width + 48px)) {
			padding: 0 24px;
		}

		&::-webkit-scrollbar {
			display: none;
		}
	}


	.buttonWrapper {

		padding: 16px 24px 0 24px;
		display: flex;
		justify-content: center;
		width: 100%;
		display: none;

		.arrow {

			padding: 13px 6px;
			border: none !important;
			background: transparent !important;
			color: @text_color;
			margin: 0;

			&.arrow-prev {
				padding-left: 0;
			}

			&.arrow-next {
				padding-right: 0;
			}

			&:not(.disabled) {

				&:hover, &:active {
					color: @secondary_color;
					opacity: 1;
				}

			}

			.text {
				position: absolute;
				left: -9999px;
			}

			&.disabled {
				opacity: 0.4;
				pointer-events: none;
			}
		}

	}

}


.hs__wrapper_mobile {
	
	.buttonWrapper {
		display: none;
	}
	
}


@media @mobile {
	
	
	.hs__wrapper_mobile {

		display: block;
		width: 100%;

		.hs {
			display: flex;
			justify-content: flex-start;
			gap: 0 16px;
			overflow-y: auto;
			-ms-overflow-style: none;
			scrollbar-width: none;
			padding: 0 32px;


			@media all and (max-width: (@wrapper_width + 48px)) {
				padding: 0 24px;
			}

			&::-webkit-scrollbar {
				display: none;
			}
		}


		.buttonWrapper {

			padding: 16px 24px 0 24px;
			justify-content: center;
			width: 100%;
			display: flex;
			
			.svgIcon {
				width: 100%;
				height: 100%;
				margin: 0;
				
				svg {
					width: 100%;
					height: 100%;
					max-width: 24px;
					max-height: 24px;
				}
			}

			.arrow {

				padding: 0;
				width: 48px;
				height: 48px;
				border: none !important;
				background: transparent !important;
				color: @text_color;
				margin: 0 2px;

				&.arrow-prev {
					padding-left: 0;
				}

				&.arrow-next {
					padding-right: 0;
				}

				&:not(.disabled) {

					&:hover, &:active {
						color: @secondary_color;
						opacity: 1;
					}

				}

				.text {
					position: absolute;
					left: -9999px;
				}

				&.disabled {
					opacity: 0.4;
					pointer-events: none;
				}
			}

		}

	}

}
