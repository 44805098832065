/*
 * Image Text Callout
 */

.imageTextWrap {
    .clearfix();
    clear: both;
    padding: 20px;

    @media @mobile {
        padding-bottom: 0;
    }

    .imageWrap {
        width: ~"calc(50% - 10px)";
    }

    .textWrap {
        width: ~"calc(50% - 10px)";

        .innerText {

            @media @tablet, @desktop {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }

            .description {
                margin: 0 0 20px;
            }
        }
    }

    .imageWrap, .textWrap {
        float: left;
        margin: 0 20px 20px 0;
        position: relative;

        @media @mobile {
            width: 100%;
        }
    }

    .textWrap {
        margin-right: 0;
    }

    &.imageRight {

        .imageWrap {
            margin-right: 0;
            float: right;
        }

        .textWrap {
            margin-right: 20px;
        }
    }

    @media @mobile {

        .imageWrap, .textWrap {
            margin-right: 0;
        }
    }

    .button, .buttonAlt {
        margin: 0;
    }
}

.lightBackground {
    background: @light_color;
}
