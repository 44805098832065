.categoryCTAWrap {
	background: #fff;
	padding: 25px 0 100px;
	float: none;
	clear: both;
	width: 100%;
	position: relative;
	overflow: hidden;
	
	@media all and (max-width: 900px) {
		padding: 65px 0;
	}
	
}

.categoryCtaList {

    float: none;
    clear: both;
    margin: 0;
    padding: 0;
    border-bottom: none;
	display: flex;
	gap: 0 25px;
	width: 100%;
	position: relative;
	//min-height: 219px;

	.splide__track {
		width: 100%;
		overflow: visible;
	}
	
	.splide__list {
		width: 100%;
		display: flex;
		gap: 0 25px;

		@media all and (max-width: 900px) {
			gap: 0;
		}

	}

    &:before, &:after {
        display: none;
    }

    .categorySlide {
		
		max-width: 219px;
		padding: 0;
		flex: 1 1 219px;
		
		@media all and (max-width: 900px) {
			flex: 0 0 219px;
			width: 219px;
			min-width: 219px;
			height: 219px;
			//margin-right: 35px !important;
		}

        a {
            color: #fff;
			display: flex;
			width: 100%;
			height: 100%;
			position: relative;
			.rems(18);
			line-height: 20px;
			font-weight: 700;
			transition: color 200ms linear;
			border-radius: 10px;
			overflow: hidden;
	
			
			&:hover, &active, &:focus {
				color: #fff;
				transition: color 200ms linear;
				
				.imageWrap {
					
					img {
						transform: scale(1.05);
						transition: transform 300ms;
					}
					
				}
				
			}
        }
		
		.boxText {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			padding: 25px;
			text-align: center;
			z-index: 2;
		}
		
		.imageWrap {
			
			position: relative;
			width: 100%;
			padding-bottom: 100%;
			z-index: 1;
			
			img {
				position: absolute;
				object-fit: cover;
				width: 100%;
				height: 100%;
				z-index: 1;
				transform: scale(1);
				transition: transform 300ms;
			}
			
			&:after {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				top: 0;
				width: 100%;
				height: 100%;
				display: block;
				z-index: 2;
				background: linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #666666 100%);
				mix-blend-mode: multiply;
			}
			
		}

    }


}
