/*
 * Overlay Styles
 */

@close_button_size: 48px;


.overlayBoxOuter {
    position: fixed;
    z-index: 999999999;
    top: 0;
    width: 100%;
    min-height: 100%;
    height: 100%;
    background: #000000;
    background: rgba(0,0,0,0.5);
    overflow: hidden;

    .ps-container {
        position: relative;

        > .ps-scrollbar-y-rail {
            opacity: 1;
        }
    }
}

.overlayBox {
    position: absolute;
    opacity: 0;
    display: block;
    max-width: 100%;

    .overlayContent {
        background: #fff;
        margin-top: @close_button_size;
        max-height: ~'calc(100% - @{close_button_size})';
        position: relative;
        height: 100%;
        border-radius: 10px;
    }

    &.transparentOverlay {

        .overlayContent {
            background: transparent;
            padding: 12px 0 0;
        }

    }

    &.hasLink {

        .overlayImage {
            position: relative;
        }
    }

    .overlayImage {

        img {
            max-width: 100%;
            height: auto;
            display: block;
        }
    }

    .overlayVideo {
        position: relative;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
        }
    }

    &.overlayFullscreen {

        .overlayAjax {
            height: 100% !important;

            iframe {
                width: 100%;
                height: 100%;
            }
        }

        .overlayContent {
            height: 100% !important;
            background: transparent !important;
        }
    }

    .overlayAjax {
        overflow-y: auto;
        max-height: 100%;
    }
}

.overlayContentLoading, .overlayLoading {

    &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        .fa(f1ce, @primary_color);
        .rems(30);
        transform: translate(-50%, -50%);
        animation: overlay-spinner 2s infinite linear;
        z-index: 999999;
    }
}

body{
    &[data-admin] {

        .overlayContentLoading, .overlayLoading {

            &:before {
                font: normal normal normal 14px/1 FontAwesome;
            }
        }

    }
}

@keyframes overlay-spinner {

  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(350deg);
  }

}

.noScroll {
    overflow: hidden;
    position: relative;
}

.overlayCloseButton {
    z-index: 9200;
    text-align: right;
    position: absolute;
    top: -4px;
    right: 0;

    a {
        background: @highlight_color;
        cursor: pointer;
        font-family: @primary_font;
        text-transform: uppercase;
        z-index: 9200;
        outline: 0;
        text-decoration: none;
        .rems(16);
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        padding: 8px;
        color: #fff;
        font-weight: bold;
        transition: background 200ms linear;
        border-radius: @border_radius;

        &:hover {
            background: darken(@highlight_color, 10%);
            transition: background 200ms linear;
            color: #fff;
        }

        &:active {
            background: @highlight_color;
            transition: background 200ms linear;
            color: #fff;
        }

        i {
            .rems(16);
            line-height: 16px;
            width: 16px;
            height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:before {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

.overlayAjax {
    padding: 24px;
}

.overlayBoxTitle {
    position: relative;
    bottom: 0;
    line-height: 30px;
    font-size: 18px;
    width: 100%;
    text-align: center;
    left: 0;
    display: block;
    color: #fff;
}

.overlayBoxLink {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
}

.galleryPrev, .galleryNext {
    .rems(30);
    line-height: 30px;
    position: absolute;
    top: 50%;
    padding: 0;
    background: none;
    border: none;
    box-shadow: none;
    color: @highlight_color;
    cursor: pointer;
    width: 30px;
    background: #fff;
    border-radius: 100%;

    &:focus {
        border: none;
        outline: none;
    }

    &.arrowInactive {
        display: none;
    }
}

.galleryPrev {
    left: -35px;

    @media @mobile {
        left: 5px;
    }

    .fa-angle-left {
       margin: 0 4px 0 0;
    }
}

.galleryNext {
    right: -35px;

    @media @mobile {
        right: 5px;
    }

    .fa-angle-right {
        margin: 0 0 0 4px;
    }
}
