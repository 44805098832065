@intro_block_max_width: 800px;


.introBlock {

    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 47px;
    color: @text_color;

    @media @mobile {
		margin-bottom: 50px;
	}

	.wrapper {
		width: 100%;
	}


    &.darkBg, .darkBg & {
        color: #fff;
    }

    &.introTitle {
        text-align: left;
    }

    &.alignLeft {
        text-align: left;
        justify-content: flex-start;

        @media all and (max-width: 800px) {
            text-align: center;
            justify-content: center;
        }

        .introContentWrap {
            max-width: 100%;
        }
    }


    .innerIntro {
        width: 100%;
        max-width: @intro_block_max_width;
        color: inherit;
        position: relative;

    }

    .subtitle {

        margin: 0 0 8px;
        .rems(12);
        line-height: 14px;
        position: relative;
        padding: 0;
        font-weight: bold;


        &:before {
            display: none;
        }

    }

    .introTitle {
        .rems(25);
        line-height: 25px;
        text-align: inherit;
        margin: 0;
        position: relative;
        z-index: 2;
        font-weight: 400;


        @media @mobile {
			.rems(25);
			line-height: 1.2em;
        }

        &.smallTitle {
			.rems(25);
			line-height: 1.2em;

            @media @mobile {
				.rems(25);
				line-height: 1.2em;
            }
        }


        & + .introContentWrap {
            margin-top: 16px;

            @media @large_tablet {
                margin-top: 12px;
            }
        }

		a {
			color: @text_color;
			transition: color 200ms linear;

			&:hover, &:active {
				color: @highlight_color;
				transition: color 200ms linear;
			}

		}
    }

    .introContentWrap {

        max-width: 644px;
        margin-left: auto;
        margin-right: auto;
        color: @text_color;
		.rems(14);
		line-height: 16px;
        letter-spacing: 0;
		font-weight: 400;


        @media @mobile {
            .rems(14);
            line-height: 22px;
        }

        .darkBg & {
            color: #fff;
        }

        p, ul, ol {
            color: inherit;
            font-size: inherit;
            line-height: inherit;
            margin: 0 auto;
            font-weight: inherit;
            letter-spacing: inherit;

        }

    }


    .buttonWrap {
        padding: 24px 0 0;

        .button, .buttonAlt {
            margin: 0;

			@media all and (max-width: 540px) {
				width: 100%;
			}

        }
    }


	&.horizontalIntro {
		.innerIntro {
			margin-left: auto;
			margin-right: auto;
		}
	}

	@media all and (min-width: 1100px) {
		&.horizontalIntro {

			margin-bottom: 49px;


			.innerIntro {
				display: flex;
				justify-content: flex-start;
				width: 100%;
				max-width: 100%;
				text-align: left;
			}

			.introTitle {
				flex: 0 0 auto;
				margin: 0;
				padding: 0 25px 0 0;
				display: flex;
				align-items: flex-end;
				.rems(28);
				line-height: 28px;
			}

			.introContentWrap {
				flex: 0 0 auto;
				margin: 0;
				padding: 0 25px 0 0;
				display: flex;
				align-items: flex-end;
				.rems(14);
				line-height: 18px;
			}

			.buttonWrap {
				padding: 0;
				margin: 0 0 0 auto;
				flex: 0 0 auto;
				display: flex;
				align-items: center;
				position: relative;
				//top: 10px;

				.button {
					white-space: nowrap;
					//padding-top: 14px;
					//padding-bottom: 14px;
				}
			}

		}
	}

    &.categoryProductsIntro {
        .buttonWrap {
            .button {
                background: @primary_gradient;
                color: #ffffff;
            }
        }
    }

    &.introPill {
        padding: 0 0 75px;
		margin: 0!important;

        @media all and (min-width: 1200px) {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            padding: 0 45px 75px;
			margin: 0!important;
        }

        .innerIntro {
            position: relative;
            border-radius: 43px;
            padding: 30px 50px 28px;
            overflow: hidden;
            background: url(https://www.will.oushkpharmacy.mtcdevserver6.com/sites/default/images/svgs/pill_background.svg) no-repeat center center border-box, linear-gradient(150.87deg, #EA9087 0%, #D9776D 100%) no-repeat center center border-box;
            background-size: cover;
            color: #ffffff;
            gap: 20px;

            @media all and (max-width: 1199px) {
                padding: 22px 40px;
                display: flex;
                flex-flow: column nowrap;
                gap: 15px;
            }

            .introTitle {
                .rems(22);
                line-height: 25px;
                font-weight: 400;
                color: inherit;
                padding: 0;
            }

            .introContentWrap {
                .rems(12);
                line-height: 16px;
                font-weight: 400;
                color: inherit;
                padding: 0;

                @media all and (max-width: 1199px) {
                    margin-top: 0;
                }
            }

            .buttonWrap {
                padding: 0;

                @media all and (min-width: 1200px) {
                    position: absolute;
                    top: 50%;
                    right: 26px;
                    transform: translateY(-50%);
                }

                .button {
                    background: #ffffff;
                    color: #E2847B;
                    padding: 14px 15px;

                    &:hover {
                        background: rgba(#ffffff, 0.8);
                    }
                }
            }
        }

        &.secondaryCol {
            .innerIntro {
                background: url(https://www.will.oushkpharmacy.mtcdevserver6.com/sites/default/images/svgs/pill_background.svg) no-repeat center center border-box, @secondary_color_3 no-repeat center center border-box;

                .buttonWrap {

                    .button {
                        color: @secondary_color_3;
                    }
                }
            }
        }
    }

}


