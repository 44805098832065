.ctaBanner {

    position: relative;
    float: none;
    clear: both;
    padding: 0;

    @media @tablet, @flyout {
        padding: 0;
    }

    @media @mobile {
        padding: 0;
    }

    &.altPadding {

        padding: 0 0 120px;

        @media @tablet, @flyout {
            padding: 0 0 80px;
        }

        @media @mobile {
            padding: 0 0 40px;
        }

    }

    &.aboveAbout {
        margin-bottom: -72px;

        @media @flyout {
            margin-bottom: 0;
        }
    }

    .wrapper {
        position: relative;
        z-index: 1;
    }

    &.hasBackground {

        &:before {
            content: "";
            width: 100%;
            height: 50%;
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            background: @light_color;

        }

        &.inverted {

            &:before {
                bottom: auto;
                top: 0;
            }

        }

        &.altPadding {

            &:before {

                height: ~"calc(100% - ((100% - 120px) / 2))";

                @media @tablet, @flyout {
                    height: ~"calc(100% - ((100% - 80px) / 2))";
                }

                @media @mobile {
                    height: ~"calc(100% - ((100% - 40px) / 2))";
                }
            }

        }
    }


    .innerCtaBanner {


        a {
            display: flex;
			flex-wrap: wrap;
            width: 100%;
            background: @light_primary;
            color: @text_color;
            padding: 56px 0;
			justify-content: center;
			text-align: center;


            @media all and (max-width: 1100px) {
                padding: 48px 0;
            }


            @media @mobile {
				padding: 48px 0;
            }
        }

        .titleWrapper {

            color: inherit;
            width: 100%;
			padding: 0 0 12px;


            h3 {
                color: inherit;
                .rems(24);
                line-height: 32px;
                font-weight: normal;
                margin: 0;

                @media all and (max-width: 1100px) {
                    .rems(24);
                    line-height: 32px;
                }

                @media all and (max-width: 800px) {
                    width: 100%;
                    text-align: center;
                    margin: 0 0 16px;
                }
            }

        }

        .contentWrapper {

            width: 100%;
			padding: 0 0 16px;


            .contentContainer {
                width: 100%;
                height: auto;
                .rems(16);
                line-height: 24px;
                font-weight: normal;
                color: inherit;
                max-width: 640px;
				margin: 0 auto;

                @media all and (max-width: 800px) {
                    text-align: center;
                }

                p, ul, ol {
                    margin: 0;
                    color: inherit;
                }

            }

            .bannerMeta {
                width: 100%;
                opacity: 0.64;
                padding: 4px 0 0;

                @media all and (max-width: 800px) {
                    text-align: center;
                }

                p {
                    margin: 0;
                }
            }

        }

        .buttonWrapper {
            width: 100%;


            button {
                margin: 0;

                i {
                    margin: 0 0 0 8px;
                }
            }
        }

    }

    &.aboutCTA {


        .innerCtaBanner {

            .titleWrapper {

                min-width: 564px;

                @media all and (max-width: 1100px) {
                    min-width: 0;
                }

            }

            .contentWrapper {

                .contentContainer {

                    max-width: 413px;

                    @media all and (max-width: 1100px) {
                        max-width: 100%;
                    }

                }

            }

        }

    }

}
