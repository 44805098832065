/*
 * Zoom Plugin
 */

.zoomContainer {
    position: relative;
    margin: 0 auto;

    .disableThisItemZooming.zooming & {

        .zoom {

            &:before { // prevent hiding original image if zoom is disableThisItemZooming
                display: none;
            }
        }
    }

    .zoom {
        line-height: 0;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -ms-touch-action: none;
        user-select: none;
        position: relative;
        display: block;
        overflow: hidden;
        border: 0;
        margin: 0 auto;
        background: #fff;

        .zooming {
            position: relative;

            &:before {
                opacity: 1;
            }
        }

        &:before { // used to hide original image when zoomed
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 9;
            background: inherit;
            opacity: 0;
            .transition(~'opacity .4s ease-in-out 0s');
        }

        img {
            margin: 0 auto;
            display: block;
            max-width: 100%;
            z-index: 8;
        }

        .portal {
            z-index: 10;

            img {
                max-width: none;
            }
        }
    }

    .loader {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background : rgba(255,255,255,0.5);

        img {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -8px;
            margin-top: -8px;
            display: block;
        }

    }

    .clickToLoad {
        display: block;
        background: #fff;
        text-transform: uppercase;
        text-align: left;
        position: absolute;
        bottom: 10px;
        left: 0px;
        pointer-events: none;
        z-index: 8;

        i {
            display: inline-block;
            margin-left: 4px;
            font-size: 10px;
            line-height: 15px;
            vertical-align: middle;
        }
    }
}
