.ctaTabs {
    position: relative;
    float: none;
    clear: both;
    padding: 40px 0;
    background: @light_color;

    &.blogTabs {
        background: transparent;
        padding: 96px 0 40px;

        @media all and (max-width: 1200px) {
            padding: 40px 0 40px;
        }
    }

    .ctaTabsWrap {

        width: 100%;

        .ctaTabsNav {

            width: 100%;
            min-height: 46px;

            & > ul, .leftNav > ul {

                width: 100%;
                display: flex;
                border-bottom: 1px solid @border_color;

                @media @flyout {
                    display: none;
                }

                &:before, &:after {
                    display: none;
                }

                & > li {
                    width: auto;
                    flex: 0 0 auto;
                }
            }

            button, .buttonLink {
                position: relative;
                background: transparent;
                letter-spacing: 0.02px;
                .rems(14);
                line-height: 16px;
                color: fade(@text_color, 50%);
                padding: 12px 12px 17px 12px;
                font-weight: 500;
                cursor: pointer;
                transition: color 200ms linear;
                display: inline-block;

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -1px;
                    width: 100%;
                    height: 1px;
                    background: @secondary_color;
                    opacity: 0;
                    transition: opacity 200ms linear;

                }

                &.activeTab {
                    color: fade(@secondary_color, 100%);
                    transition: color 200ms linear;

                    &:after {
                        opacity: 1;
                        transition: opacity 200ms linear;
                    }
                }

                &:hover, &:active, &:focus {
                    outline: 0;
                    color: fade(@secondary_color, 90%);
                    transition: color 200ms linear;
                }

            }

            .selectWrap {

                display: none;

                @media @flyout {
                    display: block;
                }

                &.row {
                    float: none;
                    clear: both;
                    width: 100%;
                    margin: 0;
                }

                .inputWrap {

                    @media @flyout {
                        min-height: 56px;
                    }

                }
            }

            &.selectNav {

                & > ul {
                    display: none;
                }

                .selectWrap {
                    display: block;
                }
            }

            &.navGrid {

                display: flex;
                flex-wrap: wrap;
                border-bottom: 1px solid @border_color;

                @media @flyout {
                    padding-bottom: 24px;
                }

                .leftNav {
                    flex: 1 1;
                    flex-basis: ~"calc(100% - 180px)";
                    padding-right: 24px;

                    @media @mobile {
                        width: 100%;
                        padding-right: 0;
                        justify-content: center;
                        flex: 0 0;
                        flex-basis: 100%;
                    }


                    & > ul {
                        border: none;
                    }

                    .selectWrap {

                        .inputWrap {

                            select, .chosen-container {
                                @media @flyout {
                                    width: 200px;
                                    min-width: 200px;
                                    max-width: 200px;
                                }

                                @media @mobile {
                                    width: 100%;
                                    min-width: 100%;
                                    max-width: 100%;
                                }
                            }

                        }

                    }
                }

                .rightNav {
                    width: 180px;
                    display: flex;
                    align-items: center;

                    @media @mobile {
                        width: 100%;
                        padding-top: 24px;
                        justify-content: center;
                    }

                }
            }
        }


    }

    &.membersTabs {

        .wrapper {
            padding: 0 !important;
        }

        .ctaTabsWrap {

            .ctaTabsNav {

                .chosen-container {
                    width: 100% !important;
                }

                button {
                    padding: 12px 20px 17px 20px;

                    @media all and (max-width: 1050px) {
                        padding: 12px 12px 17px 12px;
                    }
                }
            }

        }

    }


    .tabContentWrapper {

        padding: 40px 0 0;

        @media @flyout {
            padding: 24px 0 0;
        }

        .ctaTabContent {

            display: none;

            &.showBox {
                display: block;
            }

            .innerCtaBoxes  {

                margin-bottom: -24px;

                .boxLeft, .boxRight {
                    margin-bottom: 24px;
                }


            }

        }

    }


}
