/*
 * Mixins
 */

.clearfix() {
    *zoom: 1;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }
}

.box(@float: left; @z_index: 1; @display: block; @position: relative) {
    float: @float;
    z-index: @z_index;
    display: @display;
    position: @position;
}

.fullBox(@width: 100%; @float: left; @z_index: 1; @display: block; @position: relative) {
    .box(@float; @z_index; @display; @position);
    width: @width;
}

.placeholder(@color: @secondary_color) {
    &::-webkit-input-placeholder {
        color: @color;
    }

    &:-moz-placeholder { /* Firefox 18- */
        color: @color;
    }

    &::-moz-placeholder {  /* Firefox 19+ */
        opacity: 1;
        color: @color;
    }

    &:-ms-input-placeholder {
        color: @color;
    }
}

.font-face(@font_name, @font_file, @font_weight: normal, @font_style: normal) {
    font-family: "@{font_name}";
    src: url("@{font_file}.eot");
    src: url("@{font_file}.eot?#iefix") format("embedded-opentype"),
         url("@{font_file}.woff2") format("woff2"),
         url("@{font_file}.woff") format("woff"),
         url("@{font_file}.ttf") format("truetype"),
         url("@{font_file}.svg#@{font_name}") format("svg");
    font-weight: @font_weight;
    font-style: @font_style;
}

.rems(@value) {
    @rem_value : (@value / @base_font_size);
    font-size: ~"@{rem_value}rem";
}

.ems(@value: @base_font_size, @context: @base_font_size) {
    @em_value: (@value / @context);
    font-size: ~"@{em_value}em";
}

.fa(@unicode, @color: inherit, @style: 'regular', @is_brand: false) {
    color: @color;
    content: "\@{unicode}";

    & when (@is_brand = false) {
        font-family: "Font Awesome 5 Pro";
    }

    & when (@is_brand = true) {
        font-family: "Font Awesome 5 Brands";
    }

    & when (@style = 'light') and (@is_brand = false) {
        font-weight: 300;
    }

    & when (@style = 'regular') and (@is_brand = false) {
        font-weight: 400;
    }

    & when (@style = 'solid') and (@is_brand = false) {
        font-weight: 900;
    }

    //Force regular font
    font-weight: 400 !important;
}

/*
 * Converts letter-spacing pixel values into ems
 * For consistent letter-spacing values throughout and equal to those in designs
 */

.letterSpacing(@pixels) {
    letter-spacing: (@pixels / 1000) * 1em;

    .return() when (@pixels = 0) {
        letter-spacing: normal;
    }

    .return();
}

/*
 * Navigation Style
 */

.nav() {
    .list();

    li {

        > ul {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            margin: 0;
            padding: 0;
            z-index: 400;
            background: @highlight_color;

            @media @flyout {
                position: relative;
            }

            > li {

                > ul {
                    position: absolute;
                    top: 0;
                    left: 100%;

                    @media @flyout {
                        position: relative;
                    }
                }
            }
        }
        ul {

            li {
                float: left;
                display: block;
                width: 100%;
                white-space: nowrap;
            }
        }

        &:hover {

            > ul {
                @media all and (min-width: (@flyout_breakpoint + 1)) {
                    display: block;
                }
            }
        }
    }
}

/*
 * Edge to edge navigation
 */

.edgeNav() {
    .clearfix();
    display: table;
    margin: 0;
    padding: 0;
    width: 100%;

    li {
        display: table-cell;
        text-align: center;

        a {
            padding: 5px 15px;
            display: block;
        }

        > .relative {

            > ul {
                position: absolute;
                display: none;
                top: 100%;
                left: 0;
                margin: 0;
                padding: 0;
                z-index: 400;
                background: #fff;
            }
        }

        ul {
            display: none;

            li {
                float: left;
                display: block;
                width: 100%;
                white-space: nowrap;
            }
        }
    }

    li:hover {

        > ul {
            display: block;
        }
    }
}

/*
 * Fade Dropdown Hover
 */

.fadeNav() {
    .clearfix();

    li {

        ul {
            display: block;
            opacity: 0;
            visibility: hidden;
            transition: opacity 400ms linear 0s, visibility 0s linear 400ms;
        }

        &:hover {

            > ul {
                opacity: 1;
                visibility: visible;
                transition-delay: 0s;
            }
        }
    }
}

/*
 * List styles
 */

.ul(@color: @text_color, @icon: 'f111', @icon_font_size: 6, @margin_left: 30) {
    @margin: (@margin_left / @base_font_size);
    @left: (@margin_left / @icon_font_size);
    margin-left: ~"@{margin}em";

    li {
        position: relative;

        &:before {
            .fa(@icon, @color);
            position: absolute;
            top: 0;
            left: ~"-@{left}em";
            width: ~"@{left}em";
            .rems(@icon_font_size);
            text-align: center;
        }
    }
}

.ol(@color: @text_color, @margin_left: 30) {
    @margin: (@margin_left / @base_font_size);
    &:extend(ul all);
    counter-reset: count;

    li {
        padding: 0;

        &:before {
            counter-increment: count;
            content: counters(count, '.') '.';
            margin: ~"0 "(@margin / 3em) ~ " 0";
            color: @color;
        }

        ol {
            margin-left: ~"@{margin}em";
        }
    }
}

/*
 * Column Based Grid
 *
 * To use this grid you need a colGroup to surround the grid
 * items.  You can call this simply by using .col(12); in your
 * css within your media queries but classes have been autogenerated
 * for the desktop, tablet and mobile media queries that will allow
 * you to control layout with helper classes prefixed with your desired
 * media query.
 *
 * Using the classes "desktop_grid_23 tablet_grid_23 mobile_grid_23"
 * means you can set the desired amount of columns at each breakpoint
 * within the markup.
 */

@column_width: 100% / @column_count;

.colGroup(@gutter_width: @gutter_width) {
    float: none;
    clear: both;
    display: flex;
    flex-wrap: wrap;
    width: ~'calc(100% + @{gutter_width})';
    margin-right: -@gutter_width / 2px;
    margin-left: -@gutter_width / 2px;
}

/*
.colGroup {
    .colGroup();
}
 */

.col(@i, @gutter_width: @gutter_width, @margin: true) {
    float: none;
    clear: both;
    width: 100% / @i;
    min-height: 1px;
    padding-right: @gutter_width / 2px;
    padding-left: @gutter_width / 2px;

    & when (@margin = true) {
        margin-bottom: @gutter_width * 1px;
    }

    .noBottomMargin & {
        margin-bottom: 0;
    }
}

.columns (@i) when (@i > 0) {

    .desktop_grid_@{i} {

        @media @desktop {
            .col(@i);
        }
    }

    .tablet_grid_@{i} {

        @media @tablet {
            .col(@i);
        }
    }

    .mobile_grid_@{i} {

        @media @mobile {
            .col(@i);
        }
    }

    .columns(@i - 1);
}

.columns(0) {}
.columns(@column_count);

/*
 * Equal width grid helper
 *
 * To use this grid helper you simply need to use .grid(4);
 * in your CSS which will make all elements the same width, removing
 * margin on the number of elements specified and clearing the first
 * element of the next row.  You can optionally specify an override
 * of the default gutter width.
 */

.grid (@grid_columns, @margin: @gutter_width) {
    float: left;
    margin: 0 @margin @margin 0;
    width: ~"calc((100% - (@{margin} * (@{grid_columns} - 1))) / @{grid_columns})";

    &:nth-of-type(@{grid_columns}n) {
        margin-right: 0;
    }

    &:nth-of-type(@{grid_columns}n + 1) {
        clear: both;
    }
}


//Generate keyframes for usp banner slider
.fadeSliderKeyframes(@n) {

    @perc: 100 / @n;
    @perc_buffer: 3;
    @start: @perc_buffer;
    @stop: @perc - @perc_buffer;
    @end: @perc;

    //Animations
    @keyframes fadeslider {

        0% {
            opacity: 0;
            visibility: hidden;
        }

        @selector1: e("@{start}%");
        @{selector1} {
            opacity: 1;
            visibility: visible;
        }

        @selector2: e("@{stop}%");
        @{selector2} {
            opacity: 1;
            visibility: visible;
        }

        @selector3: e("@{end}%");
        @{selector3} {
            opacity: 0;
            visibility: hidden;
        }

        100% {
            opacity: 0;
            visibility: hidden;
        }

    }

}

//Generate animations for usp banner slider
.fadeSlider(@n, @speed, @i: 0, @delay: 0) when (@i =< @n) {

    @key: @i + 1;
    @duration: @speed * @n;
    @thedelay: (@duration / @n) * @i;

    &:nth-child(@{key}) {

        & when (@key = 1) {
            opacity: 1;
            visibility: visible;
        }

        & when (@key > 1) {
            opacity: 0;
            visibility: hidden;
        }

        animation: ~"fadeslider @{duration}ms infinite";
        animation-timing-function: linear;
        animation-delay: ~"@{thedelay}ms";

    }
    .fadeSlider(@n, @speed, (@i + 1), @delay);
}


//Add custom icons to bullet lists
.iconList(@color: #000, @icon: f00c) {

    margin-left: 12px !important;

    & > li {

        position: relative;
        list-style: none !important;
        padding-left: 10px !important;

        &:before {
            .fa(@icon);
            color: @color;
            .rems(14);
            line-height: 14px;
            font-weight: 300;
            position: absolute;
            left: -12px;
            top: 5px;
        }
    }



}


/*
 * Deprecated Mixins - DON'T USE THESE
 */

.transitionPrefix(@prop, @time, @ease) {
    transition: @prop @time @ease;
}

.transition(@transition) {
    transition: @transition;
}

.transitionDelay(@delay) {
    transition-delay: @delay;
}

.transitionDuration(@duration) {
    transition-duration: @duration;
}

.transform(@string) {
    transform: @string;
}

.translate(@translate) {
    transform: translate(@translate);
}

.rotate(@degrees) {
    transform: rotate(@degrees);
}

.transformStyle(@string) {
    transform-style: @string;
}

.transformOrigin(@string) {
    transform-origin: @string;
}

.keyframes(@name; @arguments) {
    @keyframes @name { @arguments(); }
}

.animation(@arguments) {
    animation: @arguments;
}

.opacity(@opacity: 1) {
    opacity: @opacity;
}

.gradient(@start_color: #2170b5, @end_color: #164b91) {
    background: linear-gradient(to bottom, @start_color 0%, @end_color 100%);
}




