.boxSlider {

    position: relative;
    float: none;
    clear: both;
    width: 100%;
    padding: 40px 0;
    background: @light_color;


    .sliderList {

        display: flex;
        overflow: hidden;
        flex-wrap: wrap;

        .slick-list {
            width: 100%;
        }

        .slick-track {

            display: flex;

            &:before, &:after {
                display: none;
            }
        }

        & > li, .slick-track > li {

            display: none;
            width: 100%;

            &:first-of-type {
                display: block;
            }

            &.slick-slide {

                display: block;

            }

        }

    }


    .innerSlider {

        position: relative;
        background: @light_color;

        .sliderContent {

            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            max-width: 1048px;
            z-index: 2;
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;

            .innerContent {
                display: block;
                width: 100%;
                max-width: 500px;
                padding: 0 0 48px;
                color: #fff;

                @media all and (max-width: 1200px) {
                    padding: 40px;
                }

                @media all and (max-width: 440px) {
                    padding: 24px;
                }

                &.darkText {
                    color: @text_color;
                }
            }

            .sliderTitle {
                .rems(62);
                line-height: 71px;
                font-weight: normal;
                letter-spacing: -0.03px;
                margin: 0 0 29px;
                color: inherit;

                @media @flyout {
                    .rems(40);
                    line-height: 48px;
                    margin: 0 0 16px;
                }

                @media all and (max-width: 440px) {
                    .rems(24);
                    line-height: 32px;
                }
            }

            .sliderBodyContent {
                color: inherit;
                max-width: 354px;
                .rems(14);
                line-height: 20px;

                p, ul, ol {
                    color: inherit;
                    letter-spacing: 0;
                    font-size: inherit;
                    line-height: inherit;

                }
            }

        }

        .sliderImage {

            position: relative;
            z-index: 1;
            width: 100%;
            height: auto;
            padding-bottom: 40.158%;

            @media all and (max-width: 800px) {
                padding-bottom: 61.171%;
            }

            @media @mobile {
                padding-bottom: 100%;
            }

            &.darkOverlay {

                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: block;
                    z-index: 2;
                    background: #000;
                    opacity: 0.24;
                }

            }

            img {
                position: absolute;
                width: 100%;
                height: auto;
                max-width: 100%;
                z-index: 1;
            }

        }

    }


}
