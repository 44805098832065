/*
 * Introduction text block
 */

.introductionTextBlock {
    .clearfix();
    padding: 60px 20px;
    text-align: center;

    @media @mobile {
        padding: 30px 0;
    }

    .innerText, .title {
        max-width: 900px;
        margin: 0 auto @gutter_width;
    }
}
