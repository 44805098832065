@mobile_slider_small_size: 450px;

.ctaHero {

    padding: 41px 0 24px 0;
    background: @light_color;
    background: linear-gradient(184.28deg, #FFFFFF 0%, #E8EDEE 100%), linear-gradient(192.59deg, #2E8BD6 0%, #005EB8 100%);
    border-bottom: 1px solid @border_color;


    @media @flyout {
        padding: 32px 0 16px;
    }

    @media @mobile {
        padding: 24px 0 12px;
        min-height: 220px;
    }

    @media all and (max-width: @mobile_slider_small_size) {
        padding: 0 0 12px;
        min-height: 250px;
    }

    .innerHero {

        max-width: 1442px;
        margin: 0 auto;

        .innerHeroBox {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            color: #001A33;
            .rems(14);
            line-height: 24px;
            text-align: center;
            text-decoration: none;
            padding: 8px 32px 0 32px;
            position: relative;

            @media @flyout {
                padding: 8px 16px 8px 16px;
            }

            @media all and (max-width: @mobile_slider_small_size) {
                padding: 24px 24px 8px 24px;
            }

            &:after {
                content: "";
                height: auto;
                width: 0;
                display: block;
                position: absolute;
                top: 0;
                bottom: 16px;
                right: 0;
                border-right: 2px dashed @border_color;

                @media @flyout {
                    bottom: 0;
                }

                @media all and (max-width: @mobile_slider_small_size) {
                    display: none;
                }
            }



            .imageWrap {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    max-width: 244px;
                    width: 100%;
                    height: auto;

                    @media @flyout {
                        max-width: 180px;
                    }

                    &[data-lazy-img-src][data-lazy] {
                        max-width: 32px;
                    }

                    &.desktopImage {

                        display: block;
                        visibility: visible;

                        @media @flyout {
                            display: none;
                            visibility: hidden;
                        }
                    }

                    &.mobileImage {
                        display: none;
                        visibility: hidden;

                        @media @flyout {
                            display: block;
                            visibility: visible;
                        }
                    }

                }
            }

            .boxTitle {
                margin: 0;
                padding-bottom: 20px;
                .rems(32);
                line-height: 36px;
                color: @text_color;

                @media @flyout {
                    padding-bottom: 4px;
                    .rems(24);
                    line-height: 32px;
                }

            }

            .innerContent {

                padding-bottom: 24px;

                @media @flyout {
                    padding-bottom: 16px;
                }

                @media all and (max-width: @mobile_slider_small_size) {
                    max-width: 300px;
                    margin-left: auto;
                    margin-right: auto;
                }

                p {

                    margin-bottom: 8px;

                    &:last-child {
                        margin: 0;
                    }
                }


            }

            .buttonWrapper {

                text-align: center;

            }

            .button {
                display: inline-block;
                width: 100%;
                max-width: 200px;
                margin: 0;
                .rems(12);
                line-height: 12px;
                padding: 10px 12px 9px 12px;

                i {
                    .rems(10);
                    line-height: 12px;
                    margin-left: 8px;
                    height: auto;
                    width: auto;

                    &:before {
                        font-size: inherit;
                        line-height: inherit;
                    }
                }
            }

        }


        .heroBoxTop {
            order: 1;
            padding-bottom: 28px;

            @media all and (max-width: @mobile_slider_small_size) {
                padding-bottom: 8px;
            }

        }

        .heroBoxBottom {
            order: 2;

            @media all and (max-width: @mobile_slider_small_size) {
                display: none;
                visibility: hidden;

                img {
                    display: none;
                    visibility: hidden;
                }
            }
        }

        .slick-track{
            display: flex !important;

            &:before, &:after {
                display: none;
            }
        }

        .slick-slide {
            height: inherit !important;
        }

        & > ul {

            &:before, &:after {
                display: none;
            }

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            & > li, .slick-slide {
                width: 25%;

                @media @flyout {
                    width: 33.3333%;
                }

                @media @mobile {
                    width: 50%;
                }

                @media all and (max-width: @mobile_slider_small_size) {
                    width: 100%;
                }

                @media all and (min-width: (@mobile_slider_small_size + 1px)) {
                    &:nth-child(2n) {

                        .heroBoxTop {
                            order: 2;
                            padding-bottom: 0;
                            padding-top: 24px;
                        }

                        .heroBoxBottom {
                            order: 1;

                        }
                    }
                }

                &:last-child {
                    a.innerHeroBox {
                        &:after {
                            display: none;
                        }
                    }
                }

            }

            .slick-dots {

                @media all and (max-width: @mobile_slider_small_size) {
                    margin-top: 0;
                }

            }

            & > li {
                @media all and (max-width: @mobile_slider_small_size) {
                    display: none;

                    &:first-child {
                        display: block;
                    }
                }

            }

            .slick-slide {
                @media all and (max-width: @mobile_slider_small_size) {
                    display: block;
                }
            }
        }

    }

}
