/*
 * Mini Basket
 */


.miniBasket {
    position: relative;
    float: none;
    clear: both;
    width: auto;
    z-index: 1;
	
}
