/*
 * Layout Browser
 */

.patternWrap {
    .wrapper();

    .patternSidebar {
        width: 200px;
        float: left;
        margin: 0 20px 20px 0;

        @media @mobile {
            width: 100%;
            margin: 0 0 20px;
        }
    }

    .patternContent {
        float: left;
        width: ~"calc(100% - 220px)";

        @media @mobile {
            width: 100%;
            margin: 0 0 20px;
        }

        section {
            clear: both;
        }
    }
}

/*
 * Fixed menu
 */

.patternSidebar {
    position: relative;

    .patternMenuWrap {
        .clearfix();
        position: absolute;
        top: 0;
        left: 0;
    }
}

/*
 * Patterns for chop styling
 */

.pattern {
    float: left;
    width: 100%;
    margin: 0 0 20px;
    padding: 0 0 30px;
    border-bottom: 3px solid #fff;
    clear: both;
    .clearfix();

    .display {
        margin: 30px 0;
        .clearfix();
    }

    .source {
        clear: both;
        .clearfix();
        position: relative;
        display: block;
        background: @background_color;
        border: 1px solid @border_color;
        padding: 15px;
        margin-top: 20px;

        .patternFilename {
            position: absolute;
            top: -20px;
            right: 5px;
            background: @dark_color;
            border: 3px solid @light_color;
            text-decoration: none;
            color: #fff;
            display: block;
            padding: 2px 10px;
        }

        textarea {
            width: 100%;
            border: 1px solid #fff;
            height: 200px;
        }

        .row {
            margin-bottom: 0;
        }
    }
}
