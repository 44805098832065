/* Page Header */

.pageHeader {
  width: 100%;
  background-color: @primary_color;
  @white: #f9f9f9;

  //.contentPageWrap {
	.clearfix();

	.pageHeaderContent {
	  width: 50%;
	  float: left;

	  h1 {
		color: @white;
		margin: 60px 0 50px;
		font-weight: 400;

		b {
		  font-weight: 600;
		}
	  }

	  p {
		color: @white;
	  }

	  h2 {
		color: @white;
		font-size: @base_font_size + 2px;
		margin-top: 40px;
		font-weight: 400;
	  }

	  .pageHeaderMore {
		a.button {
			margin-right: 10px;
		}

		p {
		  display: inline-block;
		  font-size: @base_font_size - 2px;

		  a {

		  }
		}
	  }
	}

	.pageHeaderImage {
	  width: 50%;
	  float: left;
	}
  //}
}
