/*
 * Full width callout
 */

.fullWidthCalloutWrap {
    .clearfix();
    text-align: center;
    background: @primary_color;
    position: relative;
    margin: 0 0 20px;
    padding: 80px 0 60px;

    @media @tablet, @desktop {
        padding: 40px 0 20px;
    }

    .fullWidthCallout {
        position: relative;
        display: inline-block;
        padding: 0 20px;

        .button, .buttonAlt, .fullWidthCalloutTitle {
            float: left;
            margin: 0;
        }

        .fullWidthCalloutTitle {
            color: #fff;
            line-height: 1.1;
            margin: 0 0 20px;

            @media @desktop {
                margin-right: 80px;
            }

            @media @tablet {
                margin-right: 40px;
            }

            @media @mobile {
                display: inline-block;
                float: none;
                margin-bottom: 20px;
            }
        }

        .button, .buttonAlt {
            background: #fff;
            color: @text_color;

            @media @mobile {
                display: inline-block;
                float: none;
            }
        }
    }
}
