/*
 * Callout blocks
 */

.calloutBlock {
    background: @highlight_color;
    color: #fff;
    text-align: center;
    padding: 90px 0;

    @media @mobile {
        padding: 45px 0;
    }

    .calloutTitle, .calloutSubTitle {
        color: #fff;
        margin: 0 0 10px;
    }

    .button, .buttonAlt {
        margin: 0;
    }
}

/*
 * Call out blocks (2 across in desktop)
 */

.calloutBlocksWrap {
    padding: 0 20px;

    ul {
        .clearfix();

        li {

            @media @desktop, @tablet {
                .grid(2);
            }

            @media @mobile {
                margin-bottom: 20px;
            }
        }
    }
}

/*
 * Call out blocks grid
 */

.calloutBlocksGridWrap {
    padding: 0 20px;

    ul {
        .clearfix();

        li {

            @media @desktop {
                .grid(4);
            }

            @media @tablet {
                .grid(2);
            }

            @media @mobile {
                margin-bottom: 20px;
            }
        }
    }
}

