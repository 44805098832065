/*
 * Jobs Pages Styling
 */

/* Jobs listing page styles */

.jobGrid {


    .colGroup(24px);

    &:before, &:after {
        display: none;
    }



    & > li {
        .col(3, 24px);
        margin-bottom: 80px;

        @media @flyout {
            .col(2, 24px);
            margin-bottom: 40px;
        }

        @media @mobile {
            .col(1, 24px);
            margin-bottom: 40px;
        }
    }

    .articleWrap {

        color: @text_color;
        text-decoration: none;
        padding: 24px;
        background: @light_color;


        .location {
            .rems(14);
            line-height: 24px;
            color: inherit;
            letter-spacing: 0.01px;
            margin: 0 0 4px;
        }

        .title {
            .rems(25);
            line-height: 29px;
            color: inherit;
            letter-spacing: 0.01px;
            margin: 0 0 16px;
            font-weight: 400;
        }

        .summary {
            .rems(14);
            line-height: 16px;
            color: inherit;
            letter-spacing: 0;
            margin: 0;
        }

        .buttonWrap {

            padding-top: 24px;
            margin-top: auto;

            button {
                margin: 0;
                width: 100%;
            }
        }

    }

    .imageWrap {
        width: 100%;
        padding-bottom: 46.43%;
        position: relative;
        overflow: hidden;
        background: darken(@light_color, 5%);

        img {
            width: 100%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%) scale(1);
            transition: transform 1000ms;
        }
    }

    a {

        &:hover, &:active {

            .imageWrap {

                img {
                    transform: translate(-50%,-50%) scale(1.1);
                    transition: transform 1000ms;
                }

            }

        }
    }


}

.jobWrapper {

    padding: 120px 0 94px;


    @media all and (max-width: @wrapper_width) {
        padding: 80px 0 94px;
    }

    @media @flyout {
        padding: 56px 0;
    }

    @media @mobile {
        padding: 40px 0;
    }


    .articlesWrap {
        .wrapper();
    }


    .paginationWrap {
        padding: 0;
    }

}

.emptyJobs {

    padding: 80px 0;

    h2 {
        .rems(32);
        line-height: 40px;
        margin: 0 0 8px;
        text-align: center;
        font-weight: 400;
    }

    p {
        margin: 0;
        text-align: center;
    }
}

/* Jobs full page styles */

.jobPageFull {

    display: flex;


    @media @flyout {
        flex-wrap: wrap;
    }

    &:before, &:after {
        display: none;
    }

    .jobDetails {
        width: 100%;
        flex: 1 1;
        flex-basis: 100%;
        float: none;
        clear: both;
        padding-right: 96px;

        @media all and (max-width: 1200px ) {
            padding-right: 48px;
        }

        @media @flyout {
            width: 100%;
            flex: 0 0 100%;
            padding-right: 0;
        }

        .topDetails {
            width: 100%;
            float: none;
            clear: both;
            margin: 0 0 24px;
            display: block;

            .headerWrapper {
                float: none;
                clear: both;
                display: flex;
                width: 100%;
                padding: 0 0 24px;
                margin: 0 0 24px;
                border-bottom: 1px solid @border_color;

                .titleWrap {
                    margin: 0;
                    padding: 0;

                    .innerText {
                        padding: 0;
                    }
                }

            }

            .metaData {
                float: none;
                clear: both;
                width: 100%;
                padding: 0 0 24px;
                margin: 0 0 24px;
                border-bottom: 1px solid @border_color;

                ul {

                    display: block;
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    li {

                        list-style: none;
                        padding: 0;
                        display: block;
                        width: 100%;
                        display: flex;


                        h6, h5 {
                            .rems(14);
                            line-height: 24px;
                            color: @text_color;
                            opacity: 1;
                            width: 100%;
                            margin: 0;
                            font-weight: 400;
                        }

                        h6 {
                            margin-bottom: 4px;
                            font-weight: 500;
                            width: 200px;
                        }

                        h5 {
                            margin: 0;
                            width: ~"calc(100% - 200px)";
                            padding-left: 16px;
                        }
                    }
                }
            }

            .imageWrap {
                width: 95px;
                float: left;
                margin: 0 20px 0 0;
            }


        }

        .jobContent {
            padding: 24px 0;

            @media @mobile {
                padding: 0;
            }
        }


    }

    .jobFormWrap {
        width: 500px;
        flex: 0 0 500px;
        float: none;
        clear: both;
        padding-top: 12px;

        @media all and (max-width: 1200px ) {
            width: 400px;
            flex: 0 0 400px;
        }

        @media @flyout {
            width: 100%;
            flex: 0 0 100%;
            padding-top: 40px;
        }


    }

    .buttonWrapper {
        padding-top: 24px;
        margin-top: 24px;
        border-top: 1px solid @border_color;
        width: 100%;

        .button {
            margin: 0;
        }

        &.desktopBackButton {

            display: block;

            @media @flyout {
                display: none;
            }
        }

        &.mobileBackButton {

            display: none;
            margin-top: 40px;

            @media @flyout {
                display: block;
            }
        }
    }
}


/*
* Banner
*/
.jobsBanner {

    padding: 88px 0 80px;
    background: #fff;
    float: none;
    clear: both;
    width: 100%;

    &.darkBg {
        background: @primary_color;
    }

    .introBlock .introTitle + .introContentWrap {
        max-width: 100%;
        width: 100%;
    }

    .noListings {

        width: 100%;
        max-width: 640px;
        text-align: center;
        margin: 0 auto 40px auto;
        opacity: 0.64;


        h4 {
            .rems(16);
            line-height: 24px;
            margin: 8px;
            text-align: inherit;
        }

        p {
            .rems(14);
            line-height: 16px;
            opacity: 0.64;
            text-align: inherit;
        }
    }

}

.jobsListBannerWrap {

    float: none;
    clear: both;
    width: 100%;
    position: relative;

    ul {

        display: block;
        float: none;
        clear: both;
        width: 100%;
        max-width: 1156px;
        margin: 0 auto;

        &:before, &:after {
            display: none;
        }

        li {
            width: 100%;


            a {
                display: flex;
                width: 100%;
                align-items: center;
                color: @text_color;
                .rems(14);
                line-height: 16px;
                padding: 40px 0;
                border-bottom: 1px solid rgba(255,255,255,0.1);
                transition: color 200ms linear, background 200ms linear;

                @media @mobile {
                    flex-wrap: wrap;
                }

                .darkBg & {
                    color: #fff;
                }

                &:hover {
                    color: @secondary_color;
                    transition: color 200ms linear, background 200ms linear;

                    .darkBg & {
                        background: rgba(255,255,255,0.08);
                    }
                }

                &:active {
                    color: darken(@secondary_color, 10%);
                    transition: color 200ms linear, background 200ms linear;

                    .darkBg & {
                        background: rgba(255,255,255,0.1);
                    }
                }
            }

            h6 {
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
                letter-spacing: inherit;
                margin: 0;
                opacity: 0.64;
            }

            h5 {
                font-weight: 500;
                .rems(16);
                line-height: 18px;
                margin: 0;
            }

            .title {
                padding: 0 40px;
                width: 100%;
                flex: 1 1 100%;
                display: flex;
                align-items: center;

                @media @mobile {
                    padding: 0 24px 24px 24px;
                    width: 100%;
                }
            }

            .location {
                padding: 0 24px;
                width: auto;
                flex: 0 0 auto;
                display: flex;
                align-items: center;

                @media @mobile {
                    width: 50%;
                    flex: 0 0 50%;
                    justify-content: flex-start;
                }
            }

            .actions {
                padding: 0 40px;
                width: auto;
                flex: 0 0 auto;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                @media @mobile {
                    padding: 0 24px;
                    width: 50%;
                    flex: 0 0 50%;
                    justify-content: flex-end;
                }

                span {
                    i {
                        margin-left: 10px;
                    }
                }
            }
        }

    }

}
