
/*
 * Site Map Styling
 */

.siteMapWrap {
    .wrapper();
    .clearfix();
    clear: both;

    .siteMapColumn {
        margin: 0 20px 20px 0;
        float: left;

        @media all
        and (min-width: (@medium_width + 1)) {
            width: ~"calc((100% - (20px * 2)) / 3)";
            margin-right: 20px;

            &:nth-child(3n) {
                margin-right: 0;
            }

            &:nth-child(3n + 1) {
                clear: both;
            }
        }

        @media all
        and (min-width: @mobile_width)
        and (max-width: @medium_width) {
            width: ~"calc(50% - 20px)";

            &:nth-child(2n) {
                margin-right: 0;
            }

            &:nth-child(2n + 1) {
                clear: both;
            }
        }

        @media @mobile {
            margin: 0 0 20px;
            width: 100%;
        }
    }

    ul {
        padding: 0 0 0 20px;

        li {
            list-style-type: square;
        }
    }
}
