/*
 * Contact Page Styling
 */

.contactWrap {
    .wrapper();
    .clearfix();
    margin: 26px auto 20px;
}

.contactFormWrap, .contactDetails {
    .clearfix();
    float: left;
    width: 100%;
    margin: 0 0 20px;

    @media @desktop, @tablet {
        width: ~"calc(50% - 10px)";
        margin-right: 20px;

        &:nth-child(2) {
            margin-right: 0;
        }
    }

    .innerText {
        margin: 0 0 20px;
    }

    .introText {

        p {
            margin: 0 0 20px;
        }
    }
}

.contactForm {
    background: @background_color;
    padding: 40px 30px;
    .clearfix();

    .button {
        float: left;
        margin: 0;
    }

    textarea {
        min-height: 200px;
    }

    @media @mobile {
        padding: 15px;
    }
}

.contactDetails {
    .clearfix();

    .mapCoordsList {
        width: 100%;
        float: left;

        .address {
            float: left;

            @media @desktop, @tablet {
                .grid(2);
                margin-bottom: 15px;
            }

            @media @mobile {
                .grid(1);
            }

            .title {
                margin: 0 0 8px 0;
                letter-spacing: -0.8px;

                a {
                    .rems(16);
                    color: @text_color;
                    text-decoration: none;
                    margin-bottom: 5px;
                }
            }
        }
    }

    /*
     * Contact Meta (phone, email and opening hours)
     */

    .contactMeta {
        .clearfix();
        margin: 0 0 4px;

        li {
            float: left;
            position: relative;
            padding: 0 0 0 35px;
            margin: 0 0 10px 0;
            width: 100%;

            i {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
                text-align: center;
            }

            a {
                color: @text_color;
                text-decoration: none;
            }
        }
    }

    /*
     * Map Styling
     */

    .mapWrap {
        position: relative;
        margin-bottom: 17px;
        .clearfix();

        @media @mobile {
            margin: 0 0 20px;
        }

        .map {
            width: 100%;
            height: 505px;
            background: @background_color;
        }

        .mapLink {
            position: absolute;
            left: 0;
            bottom: 0;
            background: @highlight_color;
            color: #fff;
            padding: 5px 10px;
            text-decoration: none;
        }
    }

    .detailsWrap {

        h4 {
            .rems(16);
            margin-bottom: 8px;
        }

        .gridItem {
            @media @desktop, @tablet {
                .grid(2);
                margin-bottom: 15px;
            }

            @media @mobile {
                .grid(1);
            }
        }
    }
}

.multiMap {

     .mapMultiple {
         height: 400px;
         width: 100%;
     }
 }
