/*
* Brands
*/
.partnersWrap {

	position: relative;
	float: none;
	clear: both;
	width: 100%;
	text-align: center;
    padding: 0 0 100px;
	z-index: 2;


	@media @mobile {
        padding: 0 0 40px;
	}



	.partners {
		position: relative;
		float: none;
		clear: both;
		width: 100%;
        padding: 22px;
        background: #EDF0F4;
        border-radius: 16px;
		border-bottom: none;

        @media @mobile {

        }


		.introBlock {

			float: none;
			clear: both;
			margin: 0 0 32px 0;

			@media @mobile {
				margin: 0 0 24px 0;
				text-align: center;
				display: none;
			}

			.introTitle {
				font-weight: normal;
			}
		}

		.slick-list {
			@media @mobile {
				min-height: 60px;
			}
		}

		.slick-slider {
			@media @mobile {
				padding: 0;
			}
		}


		ul {
			float: none;
			clear: both;
			position: relative;
			width: 100%;
			margin: 0;
			padding: 0;
			display: flex;
			justify-content: space-between;

			@media @mobile {
				flex-wrap: wrap;
				max-width: 336px;
				margin: 0 auto;
			}


			li {
				float: none;
				clear: both;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0;
				width: auto;
				flex: 0 0;
				flex-basis: ~"calc(100% / 6)";
				max-width: 168px;

				@media @mobile {
					width: 33.33%;
					flex-basis: 33.33%;
				}

				a, span {

					pointer-events: none;
					cursor: default;
					display: block;
					width: 100%;
					height: auto;

					.imageWrap {

						background: transparent;
						width: 100%;
						padding-bottom: 50%;
						position: relative;
						margin: 0 auto;

						img {
							width: 100%;
							height: auto;
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%,-50%);
						}
					}
				}
			}
		}
	}

}
