.popularProducts {
    float: none;
    clear: both;
    width: 100%;
    padding: 120px 0;


    @media @mobile {
        padding: 56px 0;
    }

    & > .wrapper {
        max-width: 100%;
        width: 100%;
        position: relative;
        float: none;
        clear: both;

        @media @mobile {
            padding: 0 !important;
        }
    }

    .featuredItemsWrap {
        margin: 0;
        width: 100%;
    }


}
