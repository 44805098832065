//Breakpoints
@product_small_desktop: 1250px;
@product_small_tablet: 800px;
@product_mobile: 580px;





/*
 * Item Full
 */
.itemWrap {

    width: 100%;
    float: none;
    clear: both;
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    z-index: 10;

    @media @product_breakpoint {
        padding-top: 0;
    }


    /*
     * Item Pricing
     */
    .itemPriceWrap {
        text-transform: uppercase;
        margin: 0 0 24px 0;

        @media all and (max-width: @product_small_tablet) {
            margin: 0 0 8px 0;
        }

        &.outOfStock {
            opacity: 0.32;
        }

        &:before, &:after {
            display: none;
        }

        .priceWas, .priceNow {

            .rems(24);
            font-weight: bold;
            line-height: 32px;
            margin: 0;
            color: @text_color;

            strong {
                font-weight: inherit;
            }


            .priceText {

                display: inline-block;
                margin-right: 8px;
                .rems(16);
                line-height: 24px;
                color: #55606A;
            }

            .multiPriceBlock {
                .rems(32);
                line-height: 40px;
                font-weight: bold;

                @media @mobile {
                    .rems(24);
                    line-height: 32px;
                }
            }
        }

        .priceWas {

            .rems(14);
            line-height: 24px;
            font-weight: normal;
            text-decoration: line-through;
            color: @text_color;
            opacity: 0.8;
            display: inline-block;
            margin-left: 8px;


        }

        &.sale {



            .percentageOff {
                color: @alert_color;
                .rems(24);
                font-weight: 700;
                line-height: 22px;
            }


            .percentageOff {
                .rems(16);
                line-height: 24px;
                margin: 0;
                display: block;
                width: 100%;
                margin-bottom: 2px;
            }
        }


    }


    .product {

        float: none;
        clear: both;
        padding: 75px 0;
        position: relative;
        display: flex;
        width:~"calc(100% + 108px)";
		margin: 0 -54px;
        z-index: 10;


        @media @product_breakpoint {
            flex-wrap: wrap;
            padding: 35px 0 60px;
			width: 100%;
			margin: 0;
        }


        .productLeft {
			flex: 0 0 46.626%;
			padding: 0 54px;
            //display: flex;
            order: 1;
            background: transparent;
            position: relative;
			min-height: 613px;


            @media @product_breakpoint {
                width: 100%;
                padding: 0;
                margin: 0 auto 37px auto;
                order: 1;
				max-width: 500px;
				border-radius: @border_radius;
				flex: 0 0 auto;
				min-height: 0;
            }


			.itemImages {

				position: relative;
				height: auto;
				margin: 0 auto;
				max-width: 100%;



				.largeImage {
					margin: 0 !important;
					position: relative;


					@media @product_breakpoint {
						position: relative;
					}
				}

			}

			product-images {
				display: block;
				width: 100%;
				padding-bottom: 100%;
			}

        }

        .productRight {

            width: 50%;
			flex: 1 1 50%;
            padding: 0 54px;
            order: 2;


            @media @product_breakpoint {
                width: 100%;
                padding: 0;
                margin: 0;
                order: 2;
            }
        }
    }

    .itemImages {
        float: none;
        clear: both;
        width: 100%;
        padding: 0;
        max-width: 100%;
        margin: 0;


        .largeImage {
            position: relative;
            float: none;
            clear: both;
            width: 100%;
            height: auto;
            max-height: none;
            margin: 0 0 48px 0;
            padding-bottom: 100%;
            border: none;
            background: transparent;
            border-radius: @border_radius;
            overflow: hidden;
            pointer-events: none;

            @media @flyout {
                margin: 0 0 48px;
            }

            & > img {
                width: 100%;
                height: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }


            .zoomContainer {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 2;

                .zoom {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: transparent;

                    .clickToLoad {

                        width: 48px;
                        height: 48px;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        z-index: 15;
                        opacity: 0.7;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        display: none !important;

                        i {
                            .rems(16);
                            line-height: 16px;
                            color: @text_color;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin: 0;

                            &:before {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }

                    }

                    & > img {
                        width: 100%;
                        height: auto;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                    }
                }
            }

        }

        .thumbsWrap {
            position: relative;
            float: none;
            clear: both;
            width: 100%;
            max-width: 100%;
            margin: 0 auto;
			padding: 10px 0 0;
        }

        .thumbs {
            width: ~"calc(100% + 10px)";
            margin: 0 -5px;
			padding: 0 0 -10px 0;
            text-align: center;
            float: none;
            clear: both;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

            &:before, &:after {
                display: none;
            }


            li {
                float: none;
                clear: both;
                flex: 0 0 20%;
                height: auto;
                padding: 0 5px;
                margin: 0 0 10px 0;

                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    border: none;
                    border-radius: @border_radius;
                    overflow: visible;
                    position: relative;
                }

                .imageWrap {
                    width: 100%;
                    height: auto;
					padding-bottom: 100%;
                    position: relative;
					border-radius: 10px;
					overflow: hidden;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                        width: auto;
                        height: auto;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
						backface-visibility: hidden;
						z-index: 1;
                    }

					&:after {
						content: "";
						display: block;
						width: ~"calc(100% - 2px)";
						height: ~"calc(100% - 2px)";
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						border: 1px solid fade(@highlight_color, 0%);
						z-index: 2;
						border-radius: 10px;
						transition: border-color 200ms linear;
					}
                }

                &.active {

                    a {
						.imageWrap {
							&:after {
								border-color: fade(@highlight_color, 100%);
								transition: border-color 200ms linear;
							}
						}
                    }
                }

				a {
					&:hover, &:active {

						.imageWrap {
							&:after {
								border-color: fade(@highlight_color, 50%);
								transition: border-color 200ms linear;
							}
						}
					}

					&:focus {
						.imageWrap {
							&:after {
								border-color: fade(@highlight_color, 100%);
								transition: border-color 200ms linear;
							}
						}
					}
				}

            }

        }


    }

    .productHeader {
        float: none;
        clear: both;
        width: 100%;
        text-align: left;
		padding: 0 0 50px;

        &.mobileProduct {
            text-align: center;
            max-width: 566px;
            margin: 0 auto 24px;
            display: none;

            @media @product_breakpoint {
                display: block;
            }

            .itemTitle {
                @media @product_breakpoint {
                    display: block;
					text-align: center;
                }

                @media @mobile {
                    display: none;
                }

            }

        }

        &.desktopProduct {
            display: block;

            @media @product_breakpoint {
                display: none;
            }

            @media @mobile {
                display: none;
            }

        }


        &.headerTop {
            .itemTitle {
                @media @mobile {
                    display: block;
                }
            }
        }

    }

    .itemTitle {

        margin-bottom: 0;
        text-align: inherit;

        @media @product_breakpoint {
            margin-bottom: 12px;
			text-align: center;
        }


        h1, h2  {
            .rems(20);
            line-height: 25px;
            font-weight: 400;
            letter-spacing: normal;
            color: @text_color;
            margin: 0;

            @media @product_breakpoint {
				.rems(20);
				line-height: 25px;
            }

            &.mainTitle {

				@media all and (max-width: 1400px) {
					.rems(28);
					line-height: 1.2em;
				}

				@media @product_breakpoint {
					.rems(28);
					line-height: 1.2em;
                }
            }
        }

        .itemSubtitle {
            .rems(24);
            line-height: 1.2em;
            font-weight: 600;
            color: fade(@text_color, 50%);
            letter-spacing: normal;
            margin: 0;
			padding: 8px 0 0;

			@media @product_breakpoint {
				.rems(16);
				line-height: 1.5em;
				text-align: center;
			}

        }

        .productBreadcrumbs {

            padding: 0 0 40px;

            @media @mobile {
                padding: 0 0 24px;
            }

            .breadcrumbs {

                &:before, &:after {
                    display: none;
                }

                display: flex;
                width: 100%;

                & > li {

                    display: none;
                    .rems(12);
                    line-height: 19px;
                    text-transform: uppercase;
                    padding: 0 16px 0 0;
                    position: relative;

                    a {
                        font-size: inherit;
                        line-height: inherit;
                        text-transform: inherit;
                        text-decoration: underline;

                        &:hover {
                            text-decoration: none;
                        }
                    }

                    &:before {
                        content: "/";
                        position: absolute;
                        left: ~"calc(100% - 9px)";
                        top: 0;
                        bottom: 0;
                        display: inline-block;
                        color: inherit;
                    }



                    &:nth-last-child(-n+2) {
                        display: flex;
                    }

                    &:last-child {
                        padding: 0;

                        &:before {
                            display: none;
                        }

                        a {
                            color: @text_color;
                            text-decoration: none;

                            &:hover, &:active {
                                color: @text_color;
                                text-decoration: none;
                            }
                        }

                    }
                }
            }

        }

    }

    .stockWrap {

        margin: 0;
		padding: 25px 0 0;
        text-align: inherit;
        width: 100%;

        @media @product_breakpoint {
         	display: flex;
			justify-content: center;
			padding: 22px 0 0;
        }


    }

    .itemDescription {

        margin: 0;
		padding: 25px 0;
        .rems(14);
        line-height: 22px;
        text-align: inherit;
        font-weight: 500;
        color: @text_color;

        @media @product_breakpoint {
			text-align: center;
			padding: 20px 0 0;
        }

		.descriptionLink {
			padding-top: 25px;
		}

        a.moreDetails {
            .rems(14);
			line-height: 1.2em;
			font-weight: 700;
			text-transform: uppercase;
			letter-spacing: 0.1em;
			text-decoration: underline;
			text-underline-offset: 6px;
			text-decoration-color: @highlight_color;
			text-decoration-thickness: 2px;
			color: @text_color;
        }

        p {
            margin: inherit;
            color: inherit;
            font-size: inherit;
			line-height: inherit;
            letter-spacing: 0;

			@media @product_breakpoint {
				font-size: inherit;
				line-height: inherit;
			}

            &:last-of-type {
                margin: 0 !important;
            }
        }

        li {
            color: inherit;

			@media @product_breakpoint {
				font-size: inherit;
				line-height: inherit;
			}
        }
    }

    .productWarningsDanger {
        & > div {
            margin-bottom: 24px;
        }

        & > .productWarning {
            @media @mobile {
                margin-top: 24px !important;
                margin-bottom: 0 !important;
            }
        }
    }

    .priceQuantity {

        float: none;
        clear: both;
        width: 100%;
        padding: 0;

        @media @product_breakpoint {

            padding: 0;

            .mobileOverlayDisabled & {
                padding: 0;
            }

        }

        .addToBasketWrap {
            float: none;
            clear: both;
            width: 100%;
            margin: 0;
            padding: 0;
        }

        .innerAddToBasket {
            float: none;
            clear: both;
            width: 100%;

        }

        .innerForm {

            float: none;
            clear: both;
            width: 100%;

        }

        .sizeQuantity {
            position: relative;
            padding-bottom: 0;

            .hiddenRow {
                display: none;
            }
        }

        .addToBasketButtonWrap {
            padding: 0;
            background: transparent;
            margin: 0;
            border-radius: 0;

        }

        .buttonWrapper {

            float: none;
            clear: both;
            width: 100%;
            display: block;

            @media @product_breakpoint {
                .mobileOverlayEnabled {
                    display: flex;
                    flex-wrap: nowrap;
                }
            }


            .priceRow {

                width: 100%;
                padding: 35px 0 0 0;
                border-bottom: none;
                position: relative;

                @media @product_breakpoint {
                    .mobileOverlayEnabled {
                        width: 50%;
                        flex: 1 1 50%;
                        padding: 0;
                        border: none;
                        display: flex;
                        align-items: center;
                    }
                }

                @media @mobile {
                    padding: 0 0 24px;
                }

                &.productPrices {
                    @media @product_breakpoint {
                        .mobileOverlayEnabled {
                            flex-wrap: wrap;
                            flex-direction: column;
                            padding: 9px 0;
                        }
                    }

                    .itemPriceWrap, .shippingInfo {
                        @media @product_breakpoint {
                            .mobileOverlayEnabled {
                                width: 100%;
                            }
                        }
                    }

                    .shippingInfo {
                        display: none;
                        padding: 2px 0 0;

                        &, & > span {
                            .rems(11);
                            line-height: 14px;
                            text-color: @text_color;
                            font-weight: 600;
                        }


                        @media @product_breakpoint {
                            .mobileOverlayEnabled {
                                display: block;
                            }
                        }
                    }
                }

                .priceText {
                    display: none;
                }

                .itemPriceWrap {

                    margin: 0;
                    text-transform: none;

                    @media @product_breakpoint {
                        .mobileOverlayEnabled {
                            line-height: 14px;
                        }
                    }

                    .fromPrefix {
                        padding-right: 4px;
                        display: inline-block;
                        font-weight: bold !important;
                    }

                    .priceNow {
                        .rems(28);
                        line-height: 40px;
                        font-weight: bold;

                        @media @mobile {
                            .rems(20);
                            line-height: 32px;
                        }

                        @media @product_breakpoint {
                            .mobileOverlayEnabled {
                                .rems(14);
                                line-height: 14px;
                            }
                        }



                        * {
                            font-weight: bold;
                        }
                    }

                    .priceWas {
                        opacity: 0.64;

                        @media @product_breakpoint {
                            .mobileOverlayEnabled {
                                .rems(11);
                                line-height: 14px;
                            }
                        }

                        &.fromPrefixed {
                            @media @flyout {
                                .mobileOverlayEnabled {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                .nhsLogo {
                    width: 140px;
                    height: auto;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    z-index: 2;
                    display: flex;
                    justify-content: flex-end;
                    padding-bottom: 24px;

                    @media @mobile {
                        display: none;
                    }

                    .imageWrap {
                        width: 100%;
                        max-width: 121px;
                        padding-bottom: 22.86%;
                        position: relative;

                        img, svg{
                            width: 100%;
                            height: auto;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                        }
                    }
                }
            }

            .warningArea {

                @media @product_breakpoint {
                    .mobileOverlayEnabled {
                        display: none;
                    }
                }


                .productWarningsDanger {

                    opacity: 1;

                    .productWarning {

                        padding: 0 0 0 32px !important;
                        margin: 24px 0 8px !important;
                        background: transparent;

                        p {
                            color: fade(@text_color, 64%);
                            margin: 0;
                            letter-spacing: 0;
                            font-weight: 600;
                            .rems(12);
                            line-height: 20px;
                        }

                        &:after {
                            left: 0 !important;
                            top: 6px !important;
                            color: @alert_color !important;
                        }
                    }

                }


            }

            .buttonArea {
                min-height: 96px;
                padding: 24px 0 0;

                @media @product_breakpoint {
                    .mobileOverlayEnabled {
                        width: 50%;
                        flex: 1 1 50%;
                        padding: 0;
                        min-height: 0;
                    }

                }

                @media @mobile {
                    padding: 24px 0 0;
                    min-height: 0;
                }
            }

            .buttonRow {
                width: 100%;
                text-align: center;
                padding: 0;

                button, .button, .buttonAlt {
                    margin: 0;
                    width: 100%;
                    .rems(17);
                    line-height: 27px;
                    font-weight: 600;

                    @media @mobile {
                        min-height: 0;
                        .rems(14);
                        line-height: 24px;
                    }
                }

            }

            .highlights {

                padding: 24px 0 0;

				@media @flyout {
					padding: 16px 0 0;
				}

				@media @mobile {
					padding: 24px 0 0;
				}

                ul {
                    &:before, &:after {
                        display: none;
                    }
                    display: flex;
					flex-wrap: wrap;

					@media @mobile {
						justify-content: flex-start;
						flex-wrap: wrap;
					}

                    & > li {
                        width: 50%;
                        align-items: center;
                        justify-content: center;

						@media @mobile {
							width: 100%;
						}

                        .iconText {
                            display: flex;
                            align-items: center;
                            justify-content: center;

							@media @mobile {
								justify-content: flex-start;
							}

                            .icon {
                                width: 18px;
                                height: 18px;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                svg {
                                    width: 100%;
                                    height: auto;
                                }
                            }

                            & > span {
                                padding-left: 12px;
                                .rems(14);
                                line-height: 32px;
                            }
                        }
                    }
                }
            }

        }

        .loading {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: #fff;
            z-index: 3;
            display: flex;
            align-items: center;
            justify-content: center;

            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            transition: all 200ms linear;


            &.isLoading {
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
            }

            .loadingIcon {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                width: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                z-index: 9999;
                margin-top: -4px;


                .imageWrap {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    transform: scale(1);
                    animation: pulse 4000ms infinite;
                    transform-origin: center;
                }

            }

            .loadingText {
                width: 100%;
                .rems(14);
                line-height: 24px;
                color: @secondary_color;
                margin: 8px 0 0 ;
                text-align: center;
                font-weight: 600;
            }
        }


        &.mobileProductMenu {

            .overlayHeader {

                width: 100%;
                background: #fff;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                padding: 24px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                display: none;
                z-index: 999999;

                @media @product_breakpoint {
                    padding: 12px 24px;
                }

                .headerLeft {
                    width: 50%;
                    padding-right: 12px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .imageWrap {
                        width: 48px;
                        height: 48px;

                        @media @flyout {
                            display: flex;
                        }

                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }

                .headerRight {
                    width: 50%;
                    padding-left: 12px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    .closeButton {
                        .toggleButton {

                            width: 48px;
                            height: 48px;
                            margin: 0;

                            &:before {
                                .fa(F00D);
                                .rems(18);
                                line-height: 18px;
                            }
                        }
                    }

                }

                .mobileOverlayEnabled & {
                    display: none;
                }
            }

            .mobileOverlayEnabled & {
                @media @flyout {

                    padding: 0;
                    position: fixed;
                    z-index: 999999;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: transparent;
                    pointer-events: none;

                    .overlayHeader {
                        display: flex;
                    }

                    .mobileProductMenuWrap {
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;

                        .innerForm {
                            height: 100vh;
                            position: relative;
                        }

                        .mobileOverlay {
                            padding: 73px 24px 80px 24px;
                            height: 100vh;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            background: @light_color;
                            opacity: 0;
                            visibility: hidden;
                            pointer-events: none;
                            transition: opacity 400ms linear, visibility 400ms linear;

                            &.visible {
                                opacity: 1;
                                visibility: visible;
                                pointer-events: auto;
                                transition: opacity 400ms linear, visibility 400ms linear;
                            }
                        }

                        .productHeader {
                            max-width: 392px;
                            margin: 0 auto;
							padding: 0 0 56px 0;

                            .validationWrap {
                                margin-bottom: 24px;
                            }

                            .itemTitle {
                                h1 {
                                    .rems(28);
                                    line-height: 40px;
                                }
                            }

                        }

                        .sizeQuantity {
                            max-width: 400px;
                            margin: 0 auto;
                            width: 100%;
                            min-height: 223px;

                            &.hiddenSizes {
                                display: none;
                            }
                        }

                    }

                    .addToBasketButtonWrap {
                        background: #fff;
                        border-top: 1px solid @border_color;
                        color: @text_color;
                        padding: 16px 24px;
                        height: 80px;
                        position: fixed;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        z-index: 12;
                        pointer-events: auto;

                        .itemPriceWrap {
                            span {
                                color: @text_color;
                            }
                        }

                        .buttonRow {

                            .button, .buttonAlt {
                                min-width: 100%;
                                width: 100%;
                                height: 48px;
                                min-height: 48px;
                                max-width: 48px;
                                padding: 12px 24px;
                                .rems(13);
                                line-height: 21px;

                            }
                        }


                    }

                    .selectionRows {

                        padding-top: 0;
						padding-bottom: 16px;

                        .row {

                            .inputWrap {

                                & > label {
                                    .rems(14);
                                    line-height: 24px;
                                    margin: 0 0 8px;
                                }

                                .selectWrapper {

                                    select {
                                        padding-top: 16px;
                                        padding-bottom: 16px;
                                        padding-left: 16px;
                                        .rems(16);
                                        line-height: 24px;
                                    }

                                    .strengthFrom {
                                        .rems(16);
                                        line-height: 24px;
                                    }
                                }
                            }

                        }

                        .quantityExplaination {

                            text-align: center;
                            padding-top: 8px;

                            & > a {
                                .rems(12);
                                line-height: 24px;
                                text-align: center;
                            }

                        }

                    }

                }

                @media all and (max-width: 400px) {
                    .addToBasketButtonWrap {

                        .buttonRow {

                            .button, .buttonAlt {
                                padding: 12px;
                                .rems(12);
                                line-height: 21px;
                            }

                        }

                    }
                }
            }
        }


    }

    .complimentedWrapper {
        float: none;
        clear: both;
        width: 100%;
        padding: 72px 0;

        @media @flyout {
            padding-top: @content_padding_top_flyout;
            padding-bottom: @content_padding_bottom_flyout;
        }

        @media @mobile {
            padding-top: @content_padding_top_mobile;
            padding-bottom: @content_padding_bottom_mobile;
        }
    }

    .itemOptions {

        float: none;
        clear: both;
        width: 100%;
        margin: 0;
		display: block;

		.sizeQuantity {
			width: ~"calc(100% + 16px)";
			margin: 0 -8px;
			display: flex;
			flex-wrap: wrap;
			min-height: 0;
			padding: 0;

			@media @product_breakpoint {
				flex-wrap: wrap;
			}
		}

        .selectionRows {

            float: none;
            clear: both;
            width: 50%;
			flex: 0 0 50%;
            padding: 0 8px;
            border: none;
            position: relative;
            z-index: 1;


            &:first-of-type {
                padding-top: 0;
            }

            .row {
                float: none;
                clear: both;
                width: 100%;
                margin: 0;

                &:before, &:after {
                    display: none;
                }

                label:not(.radioButton) {
                    float: none;
                    clear: both;
                    .rems(18);
                    line-height: 26px;
                    text-align: left;
                    font-weight: bold;
                    color: @text_color;
                    padding: 0;
                    margin: 0 0 12px;
                    width: 100%;
                    display: block;

                    @media @mobile {
                        .rems(16);
                        line-height: 24px;
                    }

                }
            }

            .inputWrap {
                float: none;
                clear: both;
                width: 100%;

                &.radioRow {
                    display: block;
                }


                .inputTitle {

                    width: 100%;
                    margin: 0;
                    float: none;
                    clear: both;
					display: none !important;
                }

				.fieldLabels {
					display: none !important;
				}


                .radioButtons {

                    width: 100%;
                    display: flex;

                    .buttonList {

                        display: flex;
                        flex-wrap: wrap;
                        height: auto;
                        width: ~"calc(100% + 16px)";
                        margin: 0 -8px -8px -8px;

                        & > li {
                            width: 33.333%;
                            padding: 0 8px;
                            height: auto;
                            margin: 0 0 16px;

                            @media @mobile {
                                width: 50%;
                            }

                            .radioButton {
                                border-radius: 16px;
                                border-width: 1px;
                                width: 100%;
                                height: 100%;
                                padding: 18px 32px;
                                .rems(18) !important;
                                line-height: 32px !important;
                                font-weight: bold;
                                //background: transparent !important;
                                flex-direction: column;
                                white-space: normal;
                                display: flex;
                                flex-wrap: wrap;
                                align-items: flex-start;
                                justify-content: center;
                                text-align: center;

                                @media @mobile {
                                    .rems(16) !important;
                                    line-height: 24px !important;
                                    padding: 22px 16px;
                                }

                                & > span {
                                    width: 100%;
                                    display: block;
                                }


                                .radioSizePrice {
                                    font-weight: normal;
                                    .rems(12) !important;
                                    line-height: 16px !important;
                                    margin: 0;
                                    color: @text_color !important;

                                    & > span {
                                        display: inline-block;
                                    }

                                    @media @mobile {
                                        margin-top: 4px;
                                    }

                                    .sizeSalePrice {
                                        opacity: 0.4;
                                        padding-left: 8px;
                                        text-decoration: line-through;
                                    }
                                }
                            }

                        }

                        &.btns-2 {
                            & > li {
                                width: 50%;

                                @media @mobile {
                                    width: 50%;
                                }
                            }
                        }

                        &.btns-1 {

							& > li {
								width: 100%;

								@media @mobile {
									width: 100%;
								}

								.radioButton {

									display: flex;
									flex-wrap: nowrap;
									flex-direction: row;
									align-items: center;
									padding-top: 26px;
									padding-bottom: 26px;

									& > span {
										width: 50%;

										&.radioTitle {
											text-align: left;
										}

										&.radioSizePrice {
											text-align: right;

											.rems(18) !important;
											line-height: 32px !important;

											@media @mobile {
												.rems(16) !important;
												line-height: 32px !important;
											}

											@media all and (max-width: 400px) {
												.rems(14) !important;
												line-height: 24px !important;
											}
										}
									}
								}
							}

                        }
                    }

                }
            }

            .selectWrapper {

                position: relative;
                width: 100%;

                select {

                    border-radius: 5px;
                    .rems(12);
                    line-height: 12px;
                    font-weight: 700;
                    padding: 15px 32px 15px 20px;
                    position: relative;
                    z-index: 1;
					letter-spacing: 0.1em;
					color: @text_color;


                }

                .strengthFrom {
                    position: absolute;
                    right: 24px;
                    top: 50%;
                    transform: translateY(-50%);
                    .rems(18);
                    line-height: 24px;
                    font-weight: 600;
                    z-index: 2;
                    pointer-events: none;
                }

                &:after {
                    .fa(f107);
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    transform: translateY(-50%);
                    color: @text_color;
                    z-index: 2;
                    .rems(12);
                    line-height: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 12px;
                    height: 12px;
                    font-weight: 400;
                }

                &.hasPrice {
                    &:after {
                        display: none;
                    }
                }

            }
        }

        .quantityExplaination {

            padding: 4px 0 0;

            & > a {
                .rems(14);
                line-height: 24px;
                letter-spacing: 0;
                color: @tertiary_color;

                &:hover {
                    color: darken(@tertiary_color, 10%);
                }

                &:active {
                    color: darken(@tertiary_color, 5%);
                }
            }

            .quantityDescription {
                display: none;
            }

        }

        .productHeader {

            &.mobileProduct {
                display: none !important;


                .mobileOverlayEnabled & {

                    @media @flyout {
                        display: block !important;
                    }
                }
            }
        }

    }

    .validationWrap {

        margin: -48px 0 48px;

		@media all and (max-width: 1400px) {
			margin: -48px 0 24px;
		}

        @media @product_breakpoint {
            margin: 0 -24px;
            width: ~"calc(100% + 48px)";
        }

		.wrapper {
			padding: 0;

			@media @product_breakpoint {
				padding: 0 24px 24px 24px;
			}
		}


    }

    .stickyMobileCTA {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9999;
        background: rgba(255,255,255,0.8);
        backdrop-filter: blur(20px);
        padding: 16px 24px;
        display: flex;

        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: opacity 200ms linear, visibility 200ms linear;

        display: none;

        @media @flyout {
            display: flex;
        }

        &:not([v-cloak]) {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
            transition: opacity 200ms linear, visibility 200ms linear;
        }

        .ctaWrapper {
            display: flex;
            align-items: center;
            width: 100%;
        }

        .priceRow {
            width: 50%;
            flex: 1 1 50%;
            padding-right: 8px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            .itemPriceWrap {
                margin: 0;
                width: 100%;

                .priceWas, .priceNow {

                    .rems(18);
                    line-height: 24px;
                    font-weight: bold;

                    @media @mobile {
                        .rems(14);
                        line-height: 16px;
                    }

                    .fromPrefix {
                        text-transform: none !important;
                    }
                }
            }

            .shippingInfo {
                .rems(11);
                line-height: 16px;
                width: 100%;
            }
        }

        .buttonArea {
            width: 50%;
            flex: 0 0 auto;
            padding-left: 8px;
            display: flex;
            align-items: center;
            min-width: 164px;

            .buttonRow {

                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;

                .button {
                    margin: 0;
                    .rems(13);
                    line-height: 19px;
                    padding: 16px 24px;
                    white-space: nowrap;
                }
            }
        }
    }
}



/*
 * Add this default
 */

.defaultShareWrap {
    .clearfix();
    margin: 0 0 20px;
}

.brandedGenericSelection {

    .inputWrap {
        margin-bottom: 0 !important;
    }

}

.hiddenOverlayTriggers {
    display: none;
}

.productWarningWrap {

    margin: 0 0 32px;
    width: 100%;
    float: none;
    clear: both;

    .productWarning {
        margin: 0;
    }

}

/*
* Side effects banner
*/
.sideEffectsBanner {

    position: relative;
    float: none;
    clear: both;
    width: 100%;
    background: @light_color;
    padding: 0;
    text-align: left;
    z-index: 2;

    @media @flyout {
        padding: 24px 0 0;
    }

    @media @mobile {
		padding: 24px 0 0;
    }

    .rowOdd &, .rowEven & {
        background: transparent;
    }

    .sectionContent & {
        text-align: left;
        padding: 0;

        @media @flyout {
            padding: 0;
        }

        @media @mobile {
            padding: 0;
        }
    }

    .innerSideEffects {

        .sideEffectsListWrap {
            width: 100%;
            max-width: 1056px;
            margin: 0 auto;

            & + .sideEffectsListWrap {
                padding-top: 40px;

                @media @flyout {
                    padding-top: 40px;
                }

                @media all and (max-width: 640px) {
                    padding-top: 24px;
                }
            }

            .sideEffectInfo {
                margin: 0 0 44px;
                .rems(16);
                line-height: 32px;
                color: @text_color;

                @media @flyout {
                    margin: 0 0 40px;
                }

                @media @mobile {
                    margin: 16px 0 24px;
                    .rems(14);
                    line-height: 24px;
                }
            }
        }

        .sideEffectsList {
            display: flex;
            width: ~"calc(100% + 32px)";
            margin: 0 -16px -24px -16px;
            flex-wrap: wrap;
            text-align: left;
            justify-content: flex-start;

            &:before, &:after {
                display: none !important;
            }

            .sectionContent & {
                justify-content: flex-start;
            }

            & > li {
                width: 33.3333%;
                padding: 0 16px;
                margin: 0 0 24px;

                @media @flyout {
                    width: 50%;
                }

                @media @mobile {
                    width: 100%;
                    margin: 0 0 16px;
                }

                .sideEffectBox {
                    background: #fff;
                    padding: 20px 24px 20px 64px;
                    .rems(14);
                    line-height: 1.2em;
					font-weight: 500;
                    color: @text_color;
                    position: relative;
					border-radius: 10px;

                    .rowOdd & {
                        background: @light_color;
                    }

					p {
						color: inherit;
						font-size: inherit;
						font-weight: inherit;
						line-height: inherit;
						margin: 0;
					}

                    &:before {

						content: url("data:image/svg+xml; utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' %3E%3Cpath d='M493.3 128l-22.6 22.6-256 256L192 429.3l-22.6-22.6-128-128L18.7 256 64 210.7l22.6 22.6L192 338.7 425.4 105.4 448 82.7 493.3 128z' fill='%235ABF01'/%3E%3C/svg%3E");
                        position: absolute;
                        left: 24px;
                        top: 20px;
                        width: 16px;
                        height: 16px;
						display: block;

                    }
                }
            }
        }

    }

}


/*
* Section Nav
*/
.itemNavPlaceholder {
    position: relative;
    float: none;
    clear: both;
    display: block;

    @media @flyout {
        height: 65px;
    }
}


.itemSectionNavWrap {

    float: none;
    clear: both;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    //box-shadow: 0 2px 6px rgba(0,0,0,0.16);
    z-index: 9999998;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-124px);
    transition: transform 400ms linear, opacity 400ms linear, visibility 400ms linear;
    border-bottom: none; //1px solid fade(#252A48, 8%);
    background-color: rgba(255,255,255,0.8);
    backdrop-filter: blur(20px);



    @media @flyout {
        opacity: 1;
        visibility: visible;
        transform: none;
        transition: none;
        position: relative;
        background-color: rgba(255,255,255,1);
        backdrop-filter: none;
        transition: background 200ms linear, backdrop-filter 200ms linear;

    }


    &.isVisible {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        transition: transform 400ms linear, opacity 400ms linear, visibility 400ms linear;


        @media @flyout {
            opacity: 1;
            visibility: visible;
            transform: none;
            transition: none;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            background-color: rgba(255,255,255,0.8);
            backdrop-filter: blur(10px);
            transition: background 200ms linear, backdrop-filter 200ms linear;
        }
    }


    .sectionNav {
        display: block;

        @media @flyout {
            display: none;
        }

        ul {

            display: flex;
            width: ~"calc(100% + 40px)";
            margin: 0 -20px;

            &:before, &:after {
                display: none;
            }

            & > li {

                padding: 0 20px;

                a {
                    .rems(16);
                    line-height: 24px;
                    font-weight: bold;
                    letter-spacing: 0;
                    color: @text_color;
                    padding: 28px 0;
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    position: relative;

                    &:after {
                        content: '';
                        width: 100%;
                        height: 4px;
                        background: @tertiary_color;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        opacity: 0;
                        display: block;
                        transition: opacity 200ms linear;
                    }

                    &:hover {
                        color: @tertiary_color;
                    }

                    &:active {
                        color: darken(@tertiary_color, 10%);
                    }

                    &.active {
                        color: @tertiary_color !important;

                        &:after {
                            opacity: 1;
                            transition: opacity 200ms linear;
                        }
                    }
                }
            }

        }
    }

    .sectionDropDown {
        display: none;

        @media @flyout {
            display: block;
        }

        .navOptions {
            display: flex;
            width: ~"calc(100% + 24px)";
            margin: 0 -12px;
            height: 64px;
            padding: 12px 0;

            .navLeft {
                width: 50%;
                flex: 1 1;
                flex-basis: ~"calc(100% - 200px)";
                padding: 0 12px;
                display: flex;
                align-items: center;

                @media all and (max-width: 420px) {
                    //display: none;
                }

                .navTitle {
                    height: auto;
                    width: 100%;
                    margin: 0;
                    .rems(16);
                    line-height: 24px;
                }
            }

            .navRight {
                width: 200px;
                flex: 0 0 200px;
                padding: 0 12px;

                @media all and (max-width: 420px) {
                    width: 142px;
                    flex: 0 0 142px;
                    padding: 0 12px;
                }

                .row {
                    float: none;
                    clear: both;
                    margin: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;

                    &:before, &:after {
                        display: none;
                    }

                    .inputWrap {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        float: none;
                        clear: both;

                        select {
                            height: 40px;
                            max-height: 40px;
                            min-height: 40px;
                            padding: 11px 44px 13px 16px;
                            border-radius: 100px;
                            box-shadow: 0 2px 6px rgba(0,0,0,0.08);
                            border: none;
                            .rems(13);
                            line-height: 19px;
                        }

                        &.mobileChosen {
                            select {
                                height: 40px;
                                max-height: 40px;
                                min-height: 40px;
                                padding: 10px 44px 14px 16px;
                                border-radius: 100px;
                                box-shadow: none;
                            }

                        }

                        select, .chosen-container {
                            @media all and (max-width: 420px) {
                                width: 118px;
                                min-width: 118px;
                                max-width: 118px;
                            }
                        }

                        .chosen-container {

                            .rems(13);
                            line-height: 19px;
                            font-weight: 600;

                            .chosen-single {
                                height: 40px;
                                max-height: 40px;
                                min-height: 40px;
                                padding: 10px 44px 14px 16px;
                                font-size: inherit;
                                line-height: inherit;
                                background: #fff;
                                border: 1px solid #fff;
                                box-shadow: 0 2px 6px rgba(0,0,0,0.08);
                                border-radius: 100px;
                                font-weight: inherit;


                                & > div {
                                    & > b {
                                        color: @text_color;
                                    }
                                }

                            }


                            .chosen-drop {
                                border-radius: 0 0 20px 20px;
                                border-color: #fff;

                                .chosen-results {

                                    & > li {
                                        .rems(13);
                                        line-height: 19px;
                                        padding: 12px 16px;

                                        &.result-selected {
                                            background: #fff !important;
                                            color: @highlight_color !important;
                                        }
                                    }
                                }
                            }

                            &.chosen-with-drop {

                                .chosen-single {
                                    border-radius: 20px 20px 0 0;
                                    border-color: @highlight_color;
                                }

                                .chosen-drop {
                                    border-color: @highlight_color;
                                }
                            }

                        }
                    }
                }
            }
        }
    }

    .productDetailsCTA {

        padding: 24px 0;

        @media @flyout {
            display: none;
        }

        .productNavCTA {
            display: flex;
            min-height: 64px;

            .ctaLeft {
                width: auto;
                flex: 1 1 auto;
                display: flex;
                align-items: center;
                padding-right: 16px;

                .navCtaTitle {
                    .rems(24);
                    line-height: 32px;
                    margin: 0;
                }
            }

            .ctaRight {
                width: auto;
                flex: 1 1 auto;
                display: flex;
                align-items: center;
                padding-left: 16px;
                max-height: 64px;

                opacity: 0;
                visibility: hidden;
                pointer-events: none;
                transition: opacity 200ms linear, visibility 200ms linear;

                &.showBar {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: auto;
                    transition: opacity 200ms linear, visibility 200ms linear;
                }
            }

            .ctaPrices {

                width: auto;
                flex: 1 1 auto;
                padding-right: 32px;

                .priceRow {
                    display: flex;
                    justify-content: flex-end;

                    .itemPriceWrap {
                        width: auto;
                        text-align: right;

                        .itemPricesWrap {

                            opacity: 1;

                            &.outOfStock {
                                opacity: 0.64;
                            }
                        }
                    }


                }

                .deliveryTimer {
                    background: transparent;
                    padding: 0;
                    margin: 4px 0 0 0;
                    text-align: right;
                    .rems(14);
                    line-height: 20px;

                    .wrapper {
                        text-align: right;
                        padding: 0 !important;
                        color: fade(@text_color, 64%);

                        &:before, &:after {
                            display: none;
                        }
                    }
                }

            }

            .ctaButtons {

                width: 207px;

                .button {
                    margin: 0;
                    width: 100%;
                    min-height: 64px;
                    white-space: nowrap;
                    display: block;
                    position: relative;
                    top: 0;
                }
            }

            .itemPriceWrap {

                .rems(17);
                line-height: 23px;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .priceSaving, .priceNow, .priceWas  {
                    padding: 8px 0;
                }

                .priceSaving {
                    font-weight: normal;
                    color: @alert_color;
                    font-size: inherit;
                    line-height: inherit;
                    padding-right: 16px;
                    margin: 0;
                    border-right: 2px solid fade(#252A48, 8%);
                }

                .priceNow {
                    font-weight: bold;
                    color: @text_color;
                    font-size: inherit;
                    line-height: inherit;
                    padding-left: 16px;
                }

                .priceWas {
                    font-weight: bold;
                    color: @text_color;
                    font-size: inherit;
                    line-height: inherit;
                    opacity: 0.4;
                    padding-left: 12px;
                }

            }

        }

    }

}

/*
* Mobile warnings
*/
.productWarningsMobile {

    display: none;
    float: none;
    width: 100%;
    clear: both;
    margin: 0 0 40px;

    @media @flyout {
        display: block;
    }

}

.deliveryTimer.productTimer {
    background: rgba(#8497B5, 0.1);
    color: @text_color;
    border-radius: 15px;
    margin: 0 0 25px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    padding: 25px;
    gap: 36px;

    span {
        color: inherit;
    }

    .timerLabel {
        .rems(12);
        line-height: 14px;
        font-weight: 400;
    }

    .timer {
        .countdown {
            &-row {
                display: flex;
                flex-flow: row nowrap;
                gap: 15px;
            }

            &-section {
                display: flex;
                flex-flow: column nowrap;
                gap: 5px;
            }

            &-amount {
                display: block;
                text-align: left;
                .rems(22);
                line-height: 25px;
                font-weight: 400;
            }

            &-period {
                display: block;
                text-align: left;
                .rems(10);
                line-height: 12px;
                font-weight: 400;
            }
        }
    }
}

.sidebarAccordionWrap {
    background: #F5ECEE;
    padding: 35px;
    border-radius: 15px;

    .faqWrap, .itemDesc, .itemDosage  {
        margin: 0 0 35px;

        &:last-child {
            margin: 0;
        }

        h4 {
            .rems(14);
            line-height: 22px;
        }

        .wysiwyg {
            .rems(12);
            line-height: 12px;

            p {
                font-size: inherit;
                line-height: inherit;
            }
        }
    }
}



