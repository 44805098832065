.featuredItemsWrap {
    clear: both;
    .clearfix();
    margin: 0 -10px 20px -10px;
    width: ~"calc(100% + 20px)";

    .itemDisplayTitle {
        text-align: center;
        position: relative;
        margin: 0 0 35px;

        span {
            position: relative;
        }

        @media @mobile {
            margin: 0 0 20px;
            .headingStyle(@selector: h4);
        }

        .closeArrows();
    }

    .featuredItems {
        .clearfix();
        .itemsList();
        margin: 0;

        li {
            float: left;
            margin: 0 10px 20px 10px;

            a {
                text-decoration: none;
            }

            @media @desktop, @tablet {

                li {
                    width: auto;
                }
            }
        }

        .itemImageWrap {

            img {
                max-height: 100%;
                vertical-align: top;
            }
        }
    }
}
