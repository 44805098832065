.newsletterPopupOverlay {
    position: fixed;
    bottom: -170px;
    left: 0;
    padding: 20px;
    width: 100%;
    background: #f1f1f1;
    box-shadow: 0 -2px 23px rgba(0, 0, 0, .44);
    overflow: visible;
    z-index: 10000;

    .newsletterPopupOverlayCloseButton {
        position: absolute;
        left: 50%;
        bottom: 100%;
        margin: 0 -20px 0 0;
        padding: 0 10px;
        background: #f1f1f1;
        box-shadow: 0 -2px 23px rgba(0, 0, 0, .44);

        &:after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            margin: 0 0 0 -30px;
            width: 60px;
            height: 20px;
            background: #f1f1f1;
        }

        a {
            color: #0f55c4;
            font-size: 2em;
        }
    }

    .overlayContent {
        float: left;
        width: 48%;
        margin: 0 2% 0 0;
    }

    .newsletterFormWrap {
        float: left;
        width: 100%;
        margin: 0;
    }

    .row {

        &:last-of-type {
            margin: 0;
        }
    }
}

.newsletterPopupButton {
    display: none;
}

.newsletterFormWrap {

    .buttonInline {
        width: 100%;

        .inputWrap {
            width: 70%;

            @media @mobile {
                width: 100%;
                margin: 0 0 15px;
            }
        }

        .button {
            padding: 12px 10px;

            @media @mobile {
                width: 100%;
            }
        }
    }
}
