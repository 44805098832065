/**
 * Home page slider
 */

.sliderContainer {
	float: left;
	width: 100%;
	overflow: hidden;
	margin: 0 0 20px;

	&.boxed {
		max-width: @wrapper_width;
		margin: 20px auto 20px;
		float: none;
	}
}

.sliderWrap {
	position: relative;
	max-width: 100%;
	.clearfix();

	@media @mobile {
		margin-bottom: 15px;
	}

	.slider {
		.clearfix();
		width: 100%;

		@media @mobile {
			width: auto;
			margin: 0;
		}

		.slick-slide, > li {
			position: relative;
			min-height: 600px;
			overflow: hidden;

			a {
				display: block;
			}

			@media @mobile {
				min-height: initial;
			}
		}

		img {
			position: absolute;
			top: 0;
			left: 50%;
			width: 2000px;
			max-width: 2000px;
			transform: translate(-50%, 0);

			@media @mobile {
				position: relative;
				width: auto;
				top: auto;
				left: auto;
				min-height: initial;
				max-width: 100%;
				transform: translate(0, 0);
			}
		}
	}

	.innerText {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		text-align: center;
		background: rgba(0, 0, 0, 0.1);
		padding: 20px;

		.slideshowPosition {
			display: table;
			height: 100%;
			width: 100%;
		}

		.slideshowText {
			display: table-cell;
			vertical-align: middle;

			h2 {
				margin-bottom: 30px;
				.rems(50);
				color: #fff;
				font-family: @primary_font;
				font-weight: bold;

				@media @tablet {
					.rems(35);
				}

				@media @mobile {
					margin-bottom: 10px;
					.rems(25);
				}
			}

			h3 {
				margin-bottom: 40px;
				.rems(22);
				color: #fff;
				text-transform: none;
				letter-spacing: 0;

				@media @mobile {
					margin-bottom: 20px;
					.rems(22);
				}
			}

			.button {
				clear: both;
				margin: 0;
				.rems(17);

				@media @mobile {
					.rems(14);
				}
			}
		}
	}
}


/*
* Splide
*/
.splide__list {
	&:before, &:after {
		display: none;
	}
}
.splide__arrows {

	.splide__arrow {
		background: transparent !important;
		color: #fff;
		width: 48px;
		height: 48px;
		opacity: 1;

		&:after {
			content: "";
			width: 34px;
			height: 34px;
			border-radius: 50%;
			background: #ffffff;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			z-index: 1;
			transition: background-color 200ms linear;
		}

		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			z-index: 2;
			width: 16px;
			height: 16px;

			path {
				fill: @text_color;
			}
		}

		&:hover, &:active, &:focus {
			&:after {
				background: @tertiary_gradient;
				transition: background-color 200ms linear;
			}
		}

		&.splide__arrow--prev {

			svg {
				transform: translate(-50%,-50%) rotate(-180deg);
			}

		}
	}
}

/*
* Grid slider
*/

.gridSliderBlock {

	float: none;
	clear: both;
	width: 100%;
	position: relative;
	background: #fff;

	ul {
		&:before, &:after {
			display: none;
		}
	}

	@media all and (min-width: 801px) {

		.splide__track {
			width: 100%;
			display: flex;
		}

		.gridSlider {

			&:not(.splide), .splide__list {
				width: 100%;
				min-height: 500px;

				display: grid;
				grid-gap: 25px;
				grid-template-columns: 50% 1fr;
				grid-template-rows: 1fr 1fr;
				grid-template-areas:
								"large small"
								"large small2";

			}

			.splide__track {
				display: flex;
				width: 100%;
			}


			.gridItem {
				display: flex;

				&.gridItem1 {
					grid-area: large;
				}

				&.gridItem2 {
					grid-area: small;
				}

				&.gridItem3 {
					grid-area: small2;
				}
			}


		}

	}

	@media all and (max-width: 800px) {

		.wrapper {
			padding: 0 !important;
		}

		.gridSlider {
			display: flex;
			flex-wrap: nowrap;
			width: 100%;

			.gridItem {
				width: 100%;
				height: 430px;

				&:not(.splide__slide) {
					flex: 0 0 100%;
				}
			}
		}

	}


}


.gridSliderBox {

	display: flex;
	width: 100%;
	position: relative;
	overflow: hidden;
	border-radius: 20px;
	background: @dark_color;
	max-width: 100%;
	overflow: hidden;

	@media all and (max-width: 800px) {
		border-radius: 0;
		height: 100%;
	}



	&:after {
		content: "";
		position: absolute;
		top: 0;
		right: auto;
		bottom: 0;
		left: 0;
		width: 75%;
		height: 100%;
		background: linear-gradient(270deg, rgba(102, 102, 102, 0) 0%, #666666 100%);
		mix-blend-mode: multiply;
		pointer-events: none;
		z-index: 1;

		@media all and (max-width: 800px) {
			width: 100%;
			right: 0;
			background: linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #666666 100%);
		}

		.gridItem1 & {
			width: 100%;
			right: 0;
			background: linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #666666 100%);
			z-index: 2;
		}
	}

	&:before {

		content: '';
		position: absolute;
		top: 0;
		right: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.24);
		mix-blend-mode: multiply;
		pointer-events: none;
		z-index: 3;
		display: none;

		@media all and (max-width: 800px) {
			display: block;
		}

		.gridItem1 & {
			display: none;

			@media all and (max-width: 800px) {
				display: none;
			}
		}

	}

	.backgroundImage {
		display: block;
		width: 56.523%;
		background: transparent;
		position: absolute;
		right: -4px;
		top: 0;
		bottom: 0;
		z-index: 2;
		padding: 0 0 44% 0;

		@media all and (max-width: 800px) {
			width: 100%;
			height: 100%;
			right: 0;
			padding: 35px;
		}

		.gridItem1 & {
			padding: 0 0 83% 0;
			width: 100%;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1;

			@media all and (max-width: 800px) {
				padding: 0;
			}
		}

		img {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			z-index: 1;
			backface-visibility: hidden;
			width: 100%;
			height: auto;

			@media all and (max-width: 800px) {
				object-fit: contain;
				width: 100%;
				height: 100%;
				padding: 35px;
			}

			.gridItem1 & {
				width: 100%;
				height: 100%;
				object-fit: cover;

				@media all and (max-width: 800px) {
					width: 100%;
					height: 100%;
					object-fit: cover;
					padding: 0;
				}
			}
		}


	}

	.boxContentWrap {
		display: flex;
		align-items: flex-end;
		flex: 0 0 53%;
		padding: 25px;
		position: relative;
		z-index: 3;


		@media all and (max-width: 800px) {
			padding: 35px;
			flex: 0 0 100%;
		}

		.gridItem1 & {
			padding: 44px 50px;
			flex: 0 0 100%;

			@media all and (max-width: 800px) {
				padding: 35px;
			}
		}

		.boxContent {

			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;
			flex: 0 0 100%;
			position: relative;
			color: #fff;
			height: 100%;

			@media all and (max-width: 800px) {
				text-align: center;
				flex-direction: column;
				align-items: flex-end;
			}

			.gridItem1 & {
				height: auto;
				flex-wrap: nowrap;
			}

			.boxTitle {

				padding: 0;
				margin: 0;
				color: inherit;
				flex: 0 0 100%;

				@media all and (max-width: 800px) {
					display: flex;
					flex-wrap: wrap;
					flex: 0 0 auto;
					width: 100%;
					margin-top: auto;
				}

				.boxTitleText {
					color: inherit;
					.rems(28);
					line-height: 1.2em;
					font-weight: 600;
					margin: 0;
					padding: 0;
					width: 100%;

					@media all and (max-width: 800px) {
						.rems(42);
						line-height: 1em;
						order: 2;
						padding: 0 35px;
					}

					@media all and (max-width: 450px) {
						.rems(24);
						line-height: 1em;
					}
				}

			}

			.brandLogo {
				color: inherit;
				flex: 0 0 100%;
				padding: 15px 0 0;

				@media all and (max-width: 800px) {
					order: 1;
				}

				img {
					width: auto;
					height: 56px;
				}
			}

			.buttonWrapper {
				margin-top: auto;
				padding-top: 16px;
				color: inherit;
				flex: 0 0 100%;

				@media all and (max-width: 800px) {
					margin-top: 0;
					flex: 0 0 auto;
					width: 100%;
				}
			}

			.button {

				margin: 0;
				color: #fff;
				min-width: 152px;
				padding-left: 20px;
				padding-right: 20px;

				&:hover, &:active, &:focus {
					color: #fff;
				}

				@media all and (max-width: 1000px) {
					width: 100%;
				}

			}


			.gridItem1 & {
				display: flex;
				align-items: flex-end;
				flex: 0 0 100%;
				position: relative;
				color: #fff;

				@media all and (max-width: 1000px) {
					flex-wrap: wrap;
				}

				.boxTitle {

					flex: 1 1 100%;
					padding: 0 19px 0 0;
					margin: 0;
					color: inherit;

					.boxTitleText {
						color: inherit;
						.rems(50);
						line-height: 1.2em;
						font-weight: 600;
						margin: 0;
						padding: 0;
						width: 100%;

						@media all and (max-width: 800px) {
							.rems(42);
							line-height: 1em;
						}

						@media all and (max-width: 450px) {
							.rems(24);
							line-height: 1em;
						}
					}

					@media all and (max-width: 1000px) {
						flex: 0 0 100%;
					}

				}

				.brandLogo {
					color: inherit;
				}

				.buttonWrapper {
					display: flex;
					align-items: flex-end;
					justify-content: flex-end;
					flex: 0 0 148px;
					color: inherit;

					@media all and (max-width: 1000px) {
						flex: 0 0 100%;
						padding: 15px 0 0;
					}
				}

				.button {

					margin: 0;
					color: #fff;
					min-width: 152px;
					padding-left: 20px;
					padding-right: 20px;
					//white-space: nowrap;

					&:hover, &:active, &:focus {
						color: #fff;
					}

				}

			}




		}

	}


}

