@tabletMedium: ~"all and (min-width: 640px) and (max-width: 800px)";

.overlayBox.trustpilotPopup {
    padding: 0;
    background: #f1f1f1;
}

.trustpilotOverlayHeader {
    padding: 20px 0;
    background: #FFF;
    border-bottom: 1px solid #CCC;
    text-align: center;

    h2 {
        font-size: 30px;
        color: #333;

        @media (max-width: 950px) {
            padding-top: 30px;
            font-size: 20px;
        }
    }
}

.trustpilotOverlayLeft {
    float: left;
    width: 30%;
    margin: 2% 0 0 2%;

    @media (max-width: 950px) {
        float: none;
        width: 96%;
        margin: 20px 2% 0 2%;
    }
}

.trustpilotOverlayImage {
    clear: left;
    padding: 20px 20px 0 20px;
    background: #FFF;
}

.trustpilotOverlayDetails {
    background: #FFF;
    padding: 20px;
    margin-bottom: 20px;

    .name {
        padding-top: 20px;
        border-top: 1px solid #EEE;
    }

    .name, .colour, .price {
        display: block;
        line-height: 25px;
    }

    .priceWas {
        font-size: 15px;
        text-decoration: line-through;
        margin-right: 10px;
        display: inline-block;
    }

    .priceNow {
        font-size: 15px;
        color: #d02a21;
    }
}

.trustpilotAverage {
    background: #FFF;
    padding: 20px;
    margin-bottom: 20px;
    text-align: center;

    h5 {
        margin-bottom: 20px;
    }
}

.trustpilotOverlayPowered {
    background: #FFF;
    padding: 20px;
    float: left;
    width: 30%;
    margin: 0 0 2% 2%;
    text-align: center;
    clear: left;

    @media (max-width: 950px) {
        float: none;
        width: 100%;
        margin: 0;
    }
}

.trustpilotProductReviews, .trustpilotProductReviews2 {
    float: right;
    padding: 2%;
     width: 65%;
    .clearfix();

    @media (max-width: 950px) {
        width: 96%;
        margin: 20px 2% 0 2%;
        width: auto;
        float: none;
        padding: 0;
    }

    .trustpilotProductReviewWrapper {
        margin: 0 0 20px 0;
        width: 100%;

        .trustpilotProductReview{
            background: #fff;
            padding: 20px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            position: relative;

            .reviewHeader {
                padding-bottom: 10px;
                margin-bottom: 10px;
                border-bottom: 1px solid #F1F1F1;
                .clearfix();
            }

            h3 {
                font-size: 14px;
                float: left;
                margin: 0;
                line-height: 24px;
                text-transform: none;
                display: none;

                i {
                    color: #FFF;
                    margin-right: 5px;
                }
            }

            img {
                float: left;
            }

            .date {
                color: #999;
                font-style: italic;
                font-size: 12px;

                @media @tablet, (max-width: 950px) {
                    position: relative;
                    top:0;
                    right:0;
                }
            }

            .trustpilotProductDescription {
                padding: 15px 0;
                font-style: normal;

                 @media @tablet, (max-width: 950px) {
                    padding-top: 7px;
                }
            }
        }
    }

    .prevArrow, .nextArrow {
        position: absolute;
        top: 50%;
        display: block;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        padding: 0;
        cursor: pointer;
        font-size: 24px;
    }

    .nextArrow {
        right: -25px;
    }

    .prevArrow {
        left: -25px;
    }
}

.itemWrap .item {
    @media (max-width: 950px) {
        margin: 0px;
    }
}

.trustpilotProductReviewsHeader {
    float: left;
    width: 78%;
    text-align: left;
    margin: 20px 0;
    background: #F1F1F1;
    padding: 30px;

    @media (max-width: 950px), @tabletMedium  {
        clear: both;
        float: none;
        width: 100%;
        text-align: center;
    }

    @media @tablet {
        width: 100%;
    }

    p {
        margin: 0px;
    }

    h5 {
        padding-bottom: 5px;
        font-size: 16px;
    }

    .innerReviewWrap {
        padding: 20px 0 0 0;
        .clearfix();

        .left {
            float: left;
            width: 60%;

            @media (max-width: 950px), @tabletMedium  {
                width: 100%;
                float: none;
            }
        }
    }

    .trustpilot_logo {
        clear: both;
        float: right;
        height: 18px;

        @media (max-width: 950px), @tabletMedium  {
            float: none;
            display: block;
            margin: 0 auto 20px;
        }
    }

    .trustpilotOverlay {
        float: right;
        display: block;
        padding: 13px 17px;
        background: @button_color;
        color: #FFF;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;

        @media (max-width: 950px) {
            float: none;
            width: auto;
            clear: both;
            margin: 20px auto 0;
        }

        @media @tabletMedium {
            margin-bottom: 0;
            text-align: center;
        }
    }
}

.trustpilotOverlayWrap {
    display: none;
    background: #F1F1F1;
    padding: 20px;
}