/*
 * Tables
 */

@table_border_color: transparent;
@table_text_color: @text_color;
@table_text_header: @text_color;
@table_bg_color: @light_color;
@table_header_bg_color: transparent;
@table_dark_color: rgba(255,255,255,0.08);

table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 4px;
    margin: 0 0 24px;
    border: none;
    table-layout: fixed;


    td, th {
        padding: 19px 24px;
        text-align: left;
        border: none;
        .rems(14);
        line-height: 16px;
        letter-spacing: 0;

        &.opts {
            width: 80px;
            text-align: center;
        }

        .row {
            margin: 0;
        }
    }


    td {

        background: @table_bg_color;
        color: @table_text_color;

        .darkBg & {
            background: @table_dark_color;
            color: #fff;
        }

        &:first-of-type {
            border-left: none;
            border-radius: 4px 0 0 4px;
        }

        &:last-of-type {
            border-right: none;
            border-radius: 0 4px 4px 0;
        }

    }

    tr {
        &:last-of-type {
            td {
                border-bottom: none;
            }
        }
    }


    th {
        .rems(14);
        line-height: 14px;
        color: @table_text_header;
        background: @table_header_bg_color;
        padding-top: 8px;
        padding-bottom: 8px;
        text-align: left;
        font-weight: 600;

        .darkBg & {
            color: #fff;
        }

    }
}


table.alternating {

    tr:nth-child(even) {
        td {
            background: fade(@table_bg_color, 80%) !important;

            .darkBg & {
                background: fade(@table_dark_color, 8%) !important;
            }
        }
    }

    tr:nth-child(odd) {
        td {
            background: fade(@table_bg_color, 60%) !important;

            .darkBg & {
                background: fade(@table_dark_color, 4%) !important;
            }
        }
    }
}

table.responsiveTitles {

    tr {

        @media @mobile {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 8px;
        }

        th {
            @media @mobile {
                display: none;
            }
        }

        td {

            @media @mobile {
                width: 100%;
                padding: 8px 16px;
            }

            .mobileTitle {
                .rems(12);
                line-height: 14px;
                margin: 0 0 6px 0;
                font-weight: normal;
                width: 100%;
                text-align: inherit;
                color: @secondary_color;
                display: none;

                @media @mobile {
                    display: block;
                }
            }



        }
    }
}

@media @mobile {

    .deliveryTable {

        tr {
            display: block;
            width: 100%;

            th {
                display: none;
            }

            td:nth-child(1) {
                display: block;
                width: 100% !important;
                max-width: 100% !important;
                font-weight: bold;
            }

            td:nth-child(2) {
                position: relative;
                padding-left: 0;
                padding-right: 0;

                &:before {
                    display: block;
                    float: left;
                    width: 100%;
                    content: 'Price';
                    font-weight: bold;
                }
            }

            td:nth-child(3) {
                position: relative;
                padding-left: 0;
                padding-right: 0;

                &:before {
                    display: block;
                    float: left;
                    width: 100%;
                    content: 'Description';
                    font-weight: bold;
                }
            }
        }
    }

}


/* -- STYLED TABLES -- */

.treatmentComparisonWrap {

	h3 {
		float: left;
		width: 100%;
		text-align: center;
		color: @primary_color;
		.rems(28);
		font-weight: normal;
	}

	table.comparison {
		border: none;

		td {
			border: none;
			.rems(13);
			color: @light_text_color;
		}

		tr:nth-child(even) {
			background-color: @tertiary_color;
		}

		tr:first-child {

			td {
				.rems(15);
				font-weight: 500;
				color: @text_color;
			}
		}

		tr td:first-child {
			color: @text_color;
			font-weight: 500;
		}
	}

}

.innerWrap {
	float: left;
	width: 100%;

	@media all and (max-width: 520px) {
		overflow-x: auto;

		table.comparison {
			width: 520px;
		}
	}
}

table.priceComparison {
	border: none;
	width: 100%;
	table-layout: unset;
	margin: 0;

	td {
		border: none;
		.rems(14);
		line-height: 24px;
		color: @text_color;
	}

	tr:nth-child(even) {
		background-color: @light_color;

		th.ours, td.ours {
			background: lighten(@light_primary, 2%);
		}
	}

	th.ours, td.ours {
		background: lighten(@light_primary, 4%);
	}

	.multiPriceBlock {
		&, strong {
			font-weight: 500;
		}
	}
}
