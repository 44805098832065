#nhs-prescriptions {

    .rems(12);
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 2px solid #F4F9FA;


    .chosen-container {
        &>a {
            height: 100%;
            display: flex;
            align-items: center;
        }
    }

    table {

        .button {
            .rems(13);
            line-height: 16px;
            padding: 12px;
            height: auto;
            width: auto;

            &.buttonDelete {
                background: @alert_color;
                border-color: @alert_color;
                margin: 0;
                width: 100%;

                @media all and (max-width: 1070px) {
                    width: 100%;
                }

                i {
                    margin: 0;
                }


                &:hover {
                    background: darken(@alert_color, 10%);
                    border-color: darken(@alert_color, 10%);
                }
            }
        }
    }

    input, select {
        padding: 12px;
        border: 2px solid #E0E7EA;
        line-height: normal;
        border-radius: 0;
        width: 100%;
        border-radius: @border_radius;

    }

    select {

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;


        & + input {
            margin-top: 8px;
        }
    }

    .inputWrap {
        & + input {
            margin-top: 8px;
        }

        &.selectWrap {

            position: relative;
            width: 100%;

            &:after {
                .fa(f107);
                position: absolute;
                right: 12px;
                top: 50%;
                transform: translateY(-50%);
                color: @primary_color;
            }

        }
    }

    table {

        margin-bottom: 24px;

        td {
            .rems(13);
            line-height: 16px;

            @media all and (max-width: 1070px) {
                .rems(14);
                line-height: 16px;
            }
        }
    }

    td.delete {
        text-align: right;

        button {
            width: 100%;



        }
    }

    .calculatedExpiry, .prescriptionEnded {

    }

    .red {
        color: #FF0000;
    }

    .prescriptionSeparator {
        border-left: 2px solid rgba(0,0,0,0.032);
        padding-left: 32px;
    }

    .right, .button, .buttonAlt {
        @media @mobile {
            width: 100%;
        }
    }

    .right {

    }
}

.nhsNotification {

    button.buttonAlt {
        float: right !important;
        margin-right: 20px;
    }

    button.button {
        float: right !important;

        @media @mobile {
            width: 100%;
        }
    }

    input {
        width: 50% !important;
        padding: 15px 10px !important;
    }

    i {
        font-size: 2em;
        line-height: 45px;
        padding-left: 10px;
        color: #287bb3;
    }
}
