/*
 * Service List
 */

.serviceListWrap {
    .clearfix();
    padding: 0 20px;
    clear: both;

    .serviceListTitle {
        &:extend(.mainTitle all);
    }

    ul {
        .clearfix();

        li {
            text-align: center;

            @media @desktop {
                .grid(4);
            }

            @media @tablet, @mobile {
                .grid(2);
            }

            .imageWrap {
                margin-bottom: 20px;
            }
        }
    }
}
