/*!
 * Font Awesome Pro 5.11.2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@import "_variables.less";

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: @fa-font-display;
  src: url('@{fa-font-path}/fa-regular-400.eot');
  src: url('@{fa-font-path}/fa-regular-400.eot?#iefix') format('embedded-opentype'),
    url('@{fa-font-path}/fa-regular-400-min.woff2') format('woff2'),
    url('@{fa-font-path}/fa-regular-400.woff') format('woff'),
    url('@{fa-font-path}/fa-regular-400.ttf') format('truetype'),
    url('@{fa-font-path}/fa-regular-400.svg#fontawesome') format('svg');
}

.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}
