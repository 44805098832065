/*
 * Notification Styles
 */
@alert_border_radius: 15px;

.success, .error, .alert, .alertBlue, .info {
    margin: 0 0 16px 0;
    padding: 25px !important;
    clear: both;
    text-align: left;
    position: relative;
    border-radius: @alert_border_radius;
    border: 1.5px solid @border_color;
    box-shadow: none;
    background: #ffffff;

    .transparent {
        background: transparent !important;
    }

    @media @mobile {
		padding: 25px 25px 25px 60px  !important;
    }

    .darkBg & {
        color: @text_color;
    }



    &, p {
        .rems(14);
        line-height: 22px;
        font-weight: 500 !important;
        color: @text_color;

        @media @mobile {
            .rems(14);
            line-height: 22px;
        }



        .darkBg & {
            color: @text_color;
        }
    }
	
	p {
		margin: 0 0 8px;
	}

    a {

        .rems(14);
        line-height: 22px;
        text-decoration: underline;
        transition: color 200ms linear;
		color: @primary_color;

        &:hover, &:active {
			color: darken(@primary_color,8%);
            text-decoration: none;
        }


        .darkBg & {
            color: @primary_color;

            &:hover, &:active {
                color: darken(@primary_color,8%);
            }
        }

    }

    h4 {
        .rems(14);
        line-height: 22px;
        font-weight: 400;
        margin-bottom: 8px;
		margin-top: -4px;
        color: @text_color;

        .darkBg & {
            color: @text_color;
        }

        &:before {
            display: inline;
            height: 17px;
        }
    }


    &:after {
        position: absolute;
        top: 24px;
        left: 25px;
        .rems(25);
        line-height: 25px;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
		color: @text_color !important;
    }
	
	small {
		.rems(11);
	}
}

.success {

    border-color: @success_color;

    .darkBg & {
		border-color: @success_color;
    }

    &, p {
        //color: #fff !important;
    }

    //&:after {
    //    .fa(f058, @success_color);
    //}

    a {
        //color: #fff;

        &:hover, &:active {
            text-decoration: none;
        }
    }

    h4 {
        color: @success_color;

		&:before {
			.fa(f058, @success_color, 'solid');
			margin: 0 8px 0 0;
		}
    }
}

.error {

	border-color: @alert_color;

    .darkBg & {
		border-color: @alert_color;
    }

    &, p {
        //color: #fff  !important;
    }

    a {
        //color: #fff;

        &:hover, &:active {
            text-decoration: none;
        }
    }

    h4 {
        color: @alert_color!important;

        &:before {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16' fill='none'%3E%3Cpath d='M16.7424 12.8887L10.2141 1.02325C9.85489 0.388872 9.20347 0 8.50003 0C7.79658 0 7.14517 0.388855 6.7859 1.02325L0.257655 12.8887C-0.0890762 13.5316 -0.085656 14.3188 0.266709 14.9583C0.618999 15.5978 1.26763 15.9941 1.97175 16H15.0283C15.7324 15.9941 16.381 15.5979 16.7333 14.9583C17.0857 14.3188 17.0891 13.5315 16.7424 12.8887H16.7424ZM7.56994 12.4934C7.56994 12.1448 7.74724 11.8229 8.03495 11.6486C8.32267 11.4743 8.67724 11.4743 8.96495 11.6486C9.25267 11.8229 9.42996 12.1448 9.42996 12.4934C9.42996 12.8419 9.25267 13.1639 8.96495 13.3382C8.67724 13.5125 8.32267 13.5125 8.03495 13.3382C7.74724 13.1639 7.56994 12.8419 7.56994 12.4934ZM9.10779 4.74689V10.1345C9.10779 10.3623 8.99192 10.5728 8.80387 10.6867C8.61581 10.8006 8.38408 10.8006 8.19602 10.6867C8.00796 10.5728 7.8921 10.3623 7.8921 10.1345V4.74689C7.8921 4.51909 8.00797 4.30862 8.19602 4.19471C8.38408 4.08081 8.61581 4.08081 8.80387 4.19471C8.99193 4.30861 9.10779 4.51907 9.10779 4.74689Z' fill='%23F34040'/%3E%3C/svg%3E");
            margin: 0 8px 0 0;
        }
    }

}

.alert {

	border-color: @information_color;

    .darkBg & {
		border-color: @information_color;
    }

    &, p {
        //color: #fff !important;
    }

    a {
        //color: #fff;

        &:hover, &:active {
            text-decoration: none;
        }
    }

    h4 {
        color: @information_color!important;

        &:before {
            .fa(f06a, @information_color, 'solid');
            margin: 0 8px 0 0;
        }
    }

}

.alertBlue, .info {

	border-color: @note_color;

    .darkBg & {
		border-color: @note_color;
    }

    &, p {
        //color: @primary_color !important;
    }

    a {
        //color: @primary_color;

        &:hover, &:active {
            text-decoration: none;
        }
    }

    h4 {
        color: @note_color!important;

        &:before {
            .fa(f05a, @note_color, 'solid');
            margin: 0 8px 0 0;
        }
    }

}


/*
 * Site wide messages
 */

.siteWideMessageWrap {
	.clearfix();
	background: @highlight_color;
	border-top: 2px solid #fff;

	.siteWideMessage {

		.slick-track {
			perspective: none;
		}

		li {
			width: 100%;
			font-family: @primary_font;
			.rems(16);
			color: #fff;
			float: left;
			text-align: center;
			font-weight: 400;
			-webkit-font-smoothing: subpixel-antialiased;

			a {
				color: #fff;
				text-decoration: none;
				display: block;
				line-height: 20px;
				padding: 10px 15px;

				&:hover {
					text-decoration: underline;
				}
			}

			&:focus {
				-webkit-appearance: none;
				outline: none;
			}

			@media @desktop, @tablet {
				width: 33.33%;
				border-right: 2px solid #fff;

				&:nth-child(3n) {
					border-right: 0;
				}

				@media @medium {
					border: 0;
				}
			}
		}
	}
}
