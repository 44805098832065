.headerTopBar {
    background: @secondary_gradient;
    border-bottom: none;
    padding: 12px 0;
    float: none;
    clear: both;
    position: relative;

    color: #ffffff;
    .rems(12);
    line-height: 1;
    font-weight: 400;
    letter-spacing: 0.02em;

    .innerTopBar {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        gap: 13px;

        a {
            color: inherit;
            transition: opacity .3s ease;

            &:hover {
                opacity: 0.7;
            }

            @media @mobile {
                font-weight: 700;

                .actionText.mobile {
                    margin: 0 0 0 5.5px;
                }
            }
        }

        .crossSiteNotice {
            text-align: center;
            font-weight: 700;

            @media @mobile {
                font-weight: 400;
                flex-shrink: 1;
            }
        }
    }

    @media all and (min-width: @mobile_width + 1) {
        .mobileIcon, .mobile {
            display: none;
        }
    }

    @media @mobile {
        .desktopText {
			clip: rect(0 0 0 0);
			clip-path: inset(50%);
			height: 1px;
			overflow: hidden;
			position: absolute;
			white-space: nowrap;
			width: 1px;
		}
		
		.desktop {
            display: none;
        }

        .loginLinkWrap, .headerContactWrap {
            flex-shrink: 0;
        }
    }
}
