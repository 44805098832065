/*
 * Title Wrap
*/

.titleWrap {

    float: none;
    clear: both;
    background: transparent;
    padding: 120px 0;
    text-align: center;
    position: relative;

    @media @flyout {
        padding: 80px 0;
    }

    @media @mobile {
        padding: 40px 0;
    }

    &.lightBg {
        background: @light_primary;
    }

    &.bottomButton {
        @media @flyout {
            padding: 80px 0 96px;
        }

        @media @mobile {
            padding: 40px 0 56px;
        }
    }

    &.faqsTitleBanner {

        display: none;

        @media @flyout {
            display: block;
        }
    }


    &.darkBg {
        background: @light_primary;
        color: @text_color;

        .innerText {
            .titleContent {
                color: @text_color;
            }
        }
    }

    .innerText {
        .wrapper();
        text-align: inherit;
        position: relative;

        h1 {
            .rems(67);
            line-height: 77px;
            font-weight: normal;
            margin: 0;

            @media all and (max-width: 1380px) {
                .rems(48);
                line-height: 56px;
            }

            @media @flyout {
                .rems(32);
                line-height: 40px;
            }
        }

        .titleContent {

            margin: 20px 0 0 0;
            margin-bottom: -24px !important;
            max-width: 100%;
            .rems(14);
            line-height: 20px;
            color: fade(@text_color, 80%);


            ul, ol, p {
                font-size: inherit;
                line-height: inherit;
            }

            p {

                color: inherit;
                &:last-child {
                    margin-bottom: 24px;
                }
            }

            strong {
                font-weight: 600;
                color: @highlight_color;
            }

        }

        h4 {

            .rems(18);
            line-height: 28px;
            margin: 0 0 14px;
            opacity: 1;
            font-weight: 600;

            @media @mobile {
                .rems(14);
                line-height: 24px;
            }
        }
    }

    &.largeTitle {

        .innerText {

            h1 {
                .rems(48);
                line-height: 56px;

                @media @flyout {
                    .rems(48);
                    line-height: 56px;
                }

                @media @mobile {
                    .rems(18);
                    line-height: 20px;
                }
            }
        }

    }

    .buttonWrap {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        padding: 25px 0 0 0;
        margin-bottom: 0;


        @media all and (max-width: 1380px) {
            padding: 25px 0 0;
        }


        .leftAlign & {
            justify-content: flex-start;
        }


        & > a {
            margin: 0 24px 0 0;
            width: auto;
            flex: 0 0 auto;


            @media @mobile {
                width: 100%;
                margin: 16px 0 0;
            }

            &:last-of-type {
                margin-right: 0;
            }


        }
    }



    .floatingButtonWrap {
        .wrapper();
        width: auto;
        display: flex;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        margin-top: -32px;
        align-items: center;
        justify-content: center;
        height: 64px;
        width: 100%;

        &:before, &:after {
            display: none;
        }

        .button, .buttonAlt {
            margin: 0;
            width: auto;
        }
    }
}
