.trustPilotBanner {

    float: none;
    clear: both;
    padding: 0 0 24px 0;

    &.browseTrustPilot {
        background: #fff;
    }

    .wrapper {
        position: relative;
    }

    .iconWrapper {

        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: relative;
        z-index: 1;

        .icon {
            width: 81px;
            height: 49px;
            background: transparent;
            opacity: 1;
            position: relative;

            img {
                width: 100%;
                height: auto;
                position: absolute;
                bottom: 0;
            }
        }
    }

    .innerWrapper {

        display: flex;
        width: 100%;
        background: @primary_color;
        color: #fff;
        padding: 40px;
        position: relative;
        z-index: 2;

        @media all and (max-width: 800px) {
            flex-wrap: wrap;
        }

        .trustPilotWidget {
            width: ~"calc(100% - 200px)";
            flex: 1 1;
            flex-basis: ~"calc(100% - 200px)";
            display: flex;
            align-items: center;
            justify-content: flex-start;

            @media all and (max-width: 800px) {
                width: 100%;
                flex: 0 0 100%;
                margin: 0 0 24px;
                align-items: flex-start;
                justify-content: center;
            }

            .trustpilot-widget {

                margin-left: -35px;
                width: 420px !important;

                @media all and (max-width: 800px) {

                    margin-left: 0;
                    width: 100% !important;
                    margin-bottom: 16px;

                }

                @media all and (max-width: 530px) {
                    margin-bottom: 8px;
                }

                & > a {
                    color: @primary_color !important;
                }

                iframe {
                    @media all and (max-width: 530px) {
                        height: 53px !important;
                    }
                }
            }
        }

        .buttonWrapper {
            width: 200px;
            flex: 0 0;
            flex-basis: 200px;
            padding: 0 0 0 16px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            @media all and (max-width: 800px) {
                width: 100%;
                flex: 0 0 100%;
                align-items: flex-start;
                justify-content: center;
                padding: 0;
            }
        }

        .button {
            margin: 0;
        }

    }


}
