img {

	&.lazy {
		opacity: 0;
		transition: opacity 200ms linear;

		&.loaded {
			opacity: 1;
			transition: opacity 200ms linear;
		}
	}
}

.svgIcon {
	

		display: inline-block;
		width: 18px;
		height: 12px;
		position: relative;

		svg {
			width: 16px;
			height: 16px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%)
		}
	
}
