.testimonialsBanner {

    float: none;
    clear: both;
    position: relative;
    padding: 40px 0;
    background: @light_color;


    .innerTestimonial {

        margin: 0;
        position: relative;
        z-index: 2;

        @media @mobile {
            margin: 0;
        }

        .testimonialContent {
            background: @primary_color;
            padding: 24px 24px 120px;
            position: relative;
            z-index: 2;

            @media @flyout {
                padding-bottom: 80px;
            }

            @media @mobile {
                padding: 24px 24px 80px;
            }

        }

        .testimonialHeader {

            margin: 0;

            .imageWrap {

                position: relative;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                width: 232px;
                height: 232px;
                margin: 0 auto;

               img {
                   width: 100%;
                   height: auto;
                   position: absolute;
                   left: 0;
                   top: 0;
               }

            }

        }


        .contentBox {
            display: block;
            width: 100%;
            color: #fff;
            text-align: center;
            max-width: 514px;
            margin: 0 auto;
            padding: 48px 0 0;

            & > h4 {
                .rems(35);
                line-height: 40px;
                font-weight: normal;
                margin: 0 0 24px;
                color: inherit;

                @media @mobile {
                    margin: 0 0 16px;
                    .rems(24);
                    line-height: 32px;
                }
            }

            .contentContainer {
                color: inherit;
                .rems(14);
                line-height: 16px;
                font-weight: normal;
                letter-spacing: 0;

                @media @mobile {
                    line-height: 24px;
                }

                p, ul, ol {
                    font-size: inherit;
                    line-height: inherit;
                    font-weight: inherit;
                    letter-spacing: inherit;
                    margin-bottom: 16px;
                }
            }

            .quoteBy {
                color: inherit;
                .rems(14);
                line-height: 24px;
                opacity: 0.64;
                padding: 8px 0 0;
                font-style: normal;
            }
        }

    }

}
