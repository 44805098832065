.ctaBoxes {

    position: relative;
    float: none;
    clear: both;
    padding: 160px 0;
    background: @light_color;

    @media @tablet, @flyout {
        padding: 80px 0;
    }

    @media @flyout {
        padding: 40px 0;
    }

    @media @mobile {
        padding: @top_bottom_gutter_mobile 0;
    }


    .innerCtaBoxes {

        display: flex;
        flex-wrap: wrap;
        width: ~"calc(100% + 24px)";
        margin: 0 -12px;

        @media all and (max-width: 900px) {
            width: 100%;
            margin: 0;
        }

        .boxLeft, .boxRight {
            width: 50%;
            padding: 0 12px;

            @media all and (max-width: 900px) {
                width: 100%;
                padding: 0;
                margin: 0 0 24px;
            }
        }

        .boxRight {
            @media all and (max-width: 900px) {
                margin: 0;
            }
        }

        .ctaBox {

            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            color: #fff;
            position: relative;
            background: darken(@light_color, 10%);

            &.darkText {
                color: @text_color;
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                color: inherit;
                position: relative;
            }

            .imageWrap {
                position: relative;
                z-index: 1;
                width: 100%;
                height: auto;
                padding-bottom: 70%;
                overflow: hidden;

                @media all and (max-width: 900px) {
                    padding-bottom: 47%;
                }

                @media @mobile {
                    padding-bottom: 82%;
                }

                @media all and (max-width: 390px) {
                    padding-bottom: 100%;
                }

                img {
                    position: absolute;
                    z-index: 1;
                    width: 100%;
                    height: auto;
                    max-width: 100%;
                    max-height: none;
                }
            }

            .contentBox {

                text-align: center;
                padding: 40px;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 2;


                .innerContentBox {
                    width: 100%;
                    height: auto;
                    margin: 0 auto;
                    max-width: 424px;
                }

                .boxTitle {
                    .rems(49);
                    line-height: 56px;
                    font-weight: normal;
                    margin: 0 0 24px;
                    color: inherit;

                    @media @flyout {
                        .rems(32);
                        line-height: 40px;
                        margin: 0 0 12px;
                    }
                }

                p {
                    .rems(16);
                    line-height: 24px;
                    margin: 0 0 24px;
                    color: inherit;

                    @media @flyout {
                        margin: 0 0 16px;
                    }

                    @media all and (max-width: 450px) {
                        .rems(14);
                    }
                }

                .buttonWrap {
                    padding: 8px 0 0;

                    @media @flyout {
                        padding: 0;
                    }

                    button {
                        margin: 0;

                        i {
                            margin: 0 0 0 8px;
                        }
                    }
                }

            }
        }

    }

}


.innerCtaBoxes {

    display: flex;
    flex-wrap: wrap;
    width: ~"calc(100% + 24px)";
    margin: 0 -12px;

    @media all and (max-width: 900px) {
        width: 100%;
        margin: 0;
    }

    .boxLeft, .boxRight {
        width: 50%;
        padding: 0 12px;

        @media all and (max-width: 900px) {
            width: 100%;
            padding: 0;
            margin: 0 0 24px;
        }
    }

    .boxRight {
        @media all and (max-width: 900px) {
            margin: 0;
        }
    }

    .ctaBox {

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: #fff;
        position: relative;
        background: darken(@light_color, 10%);

        &.darkText {
            color: @text_color;
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            color: inherit;
            position: relative;
        }

        .imageWrap {
            position: relative;
            z-index: 1;
            width: 100%;
            height: auto;
            padding-bottom: 70%;
            overflow: hidden;

            @media all and (max-width: 900px) {
                padding-bottom: 47%;
            }

            @media @mobile {
                padding-bottom: 82%;
            }

            @media all and (max-width: 390px) {
                padding-bottom: 100%;
            }

            img {
                position: absolute;
                z-index: 1;
                width: 100%;
                height: auto;
                max-width: 100%;
                max-height: none;
            }

            &.darkOverlay {

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background: @primary_color;
                    opacity: 0.42;
                    mix-blend-mode: multiply;
                    z-index: 2;
                }
            }
        }

        .contentBox {

            text-align: center;
            padding: 40px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 2;


            .innerContentBox {
                width: 100%;
                height: auto;
                margin: 0 auto;
                max-width: 424px;
            }

            .boxTitle {
                .rems(49);
                line-height: 56px;
                font-weight: normal;
                margin: 0 0 24px;
                color: inherit;

                @media @flyout {
                    .rems(32);
                    line-height: 40px;
                    margin: 0 0 12px;
                }
            }

            p {
                .rems(16);
                line-height: 24px;
                margin: 0 0 24px;
                color: inherit;

                @media @flyout {
                    margin: 0 0 16px;
                }

                @media all and (max-width: 450px) {
                    .rems(14);
                }
            }

            .buttonWrap {
                padding: 8px 0 0;

                @media @flyout {
                    padding: 0;
                }

                button {
                    margin: 0;

                    i {
                        margin: 0 0 0 8px;
                    }
                }
            }

        }
    }

}
