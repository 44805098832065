
//Breadcrumbs
@breadcrumb_border: none;
@breadcrumb_padding: 9px 0 11px;

/*
 * Breadcrumb Styling
 */

.breadcrumbWrap {

	float: none;
    clear: both;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
	padding: 0;

    &:before, &:after {
        display: none;
    }

    .breadcrumbs {
        float: none;
		clear: both;
        margin: 0;
        padding: 0;
        white-space: nowrap;
        color: #fff;

        .darkBg & {
            color: #fff;
        }

		&:before, &:after {
			display: none;
		}

        li {
            display: inline-block;
            position: relative;
            padding: 0 34px 0 0;
            list-style-type: none;
			font-weight: 700;
			.rems(12);
			line-height: 12px;
			text-decoration: none;
			transition: opacity 200ms linear, color 200ms linear;
			opacity: 1;
			letter-spacing: 0.1em;
			color: #fff;
			
			i {
	
				position: absolute;
				top: 50%;
				left: 100%;
				transform: translate(0, -50%);
				margin-left: -17px;
				color: #fff;
				opacity: 1;
				width: 4px;
				height: 7px;

				.darkBg & {
					color: #fff;
				}
				
				svg {
					width: 100%;
					height: 100%;
				}
			}
			
			&:last-child {
				
				padding-right: 0;
				
				i {
					display: none;
				}
			}
			
            a {
                font-weight: inherit;
                font-size: inherit;
                line-height: inherit;
                text-decoration: none;
                transition: opacity 200ms linear, color 200ms linear;
                opacity: 1;
                letter-spacing: inherit;
				color: #fff;

                &:active, &:hover {
                    color: fade(#fff, 80%);
                    opacity: 1;
                    transition: opacity 200ms linear, color 200ms linear;
                }

                .darkBg & {
                    color: #fff;

                    &:active, &:hover {
						color: fade(#fff, 94%);
						opacity: 1;
						transition: opacity 200ms linear, color 200ms linear;
                    }
                }
            }

        }

        @media all and (max-width: 640px) {
            display: none;
        }
    }
}

.innerBreadcrumbs {
    float: none;
    clear: both;
    width: 100%;
	.wrapper();
}

/*
* Breadcrumbs
 */
.breadcrumbsWrapper {
    margin-bottom: 0;
    float: none;
    clear: both;
	background: @primary_color;
	text-transform: uppercase;
	

    @media @mobile {
        display: none;
    }
	
	&:before, &:after {
		display: none;
	}

    & > .innerBreadcrumbs {
        border-bottom: @breadcrumb_border;
        padding: @breadcrumb_padding;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
	

        &:before, &:after {
            display: none;
        }
    }

    .breadcrumbWrap {
        width: auto;
        flex: 1 1 auto;

        .breadcrumbs {
            width: 100%;
			display: flex;
			justify-content: center;
        }
    }

    .breadcrumbLogos {

        width: auto;
        margin-left: auto;
        flex: 0 0 auto;


        .iconsRight {
            float: none;
            clear: both;
            margin: 0;
            width: auto;
            display: flex;

            &:before, &:after {
                display: none;
            }

            li {
                float: none;
                clear: both;
                display: flex;
                align-items: center;
            }

        }

    }




}

/*
 * Pagination
 */

.paginationWrap {
	
	display: flex;
	justify-content: center;
	padding: 50px 0;
	float: none;
	clear: both;
	
	.innerPagination {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	.paginationButtons {
		display: flex;
		align-items: center;
		
		a {
			padding: 12px;
			border-radius: 5px;
			color: @text_color;
			background: transparent;
			transition: color 200ms linear;
			
			
			&:hover, &:active {
				color: @highlight_color;
				transition: color 200ms linear;
			}
			
			svg {
				width: 6px;
				height: 10px;
				position: relative;
				//top: -2px;
			}
		}

		&.buttonPrevious {
			justify-content: flex-start;
			padding: 0 6px 0 0;
		}

		&.buttonPrevious {
			justify-content: flex-end;
			padding: 0 0 0 6px;
		}
	}
}



.pagination {
    float: none;
    clear: both;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
	margin: 0;


    &:before, &:after {
        display: none;
    }

    li {
        padding: 0 6px;
        margin: 0;
        position: relative;
        overflow: hidden;
        text-align: center;
        cursor: default;
        outline: 0;
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        .rems(18);
        line-height: 20px;
        font-weight: 700;
		text-transform: uppercase;
        color: @text_color;


        .darkBg & {
            color: #fff;
        }
		

        &.dots, &.pageXOfY {
            height: auto;
            width: auto;
            background: 0;
            border: 0;
        }

        &.pageXOfY {
            padding: 8px;
        }

        &.dots {
            cursor: default;
            padding: 0 5px;

            i {
                padding: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 25px;
            }
        }


        &.next, &.prev {

            a {

                width: auto;

                &:after, &:before {
                    position: relative;
                    align-items: center;
                    justify-content: center;
                    .fa(f105);
                    display: none;
                }


            }

            &.arrows {
                a {
                    min-width: 34px;
                    min-height: 34px;

                    &:after, &:before {
                        display: flex;
                    }
                }
            }
        }

        &.next {

            padding: 0 0 0 8px;

            a:before {
                display: none !important;
            }
            a:after {
                .fa(f105);
                margin-left: 8px;
            }

            &.arrows {
                a:after {
                    margin-left: 0;
                }
            }
        }

        &.prev {

            padding: 0 8px 0 0;

            a:after {
                display: none !important;
            }
            a:before {
                .fa(f104);
                margin-right: 8px;
            }

            &.arrows {
                a:before {
                    margin-right: 0;
                }
            }
        }



        a {
            text-decoration: none;
            background: transparent;
            border: none;
            width: 43px;
            height: 43px;
            opacity: 1;
            transition: all 200ms linear;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: inherit;
            line-height: inherit;
            font-weight: inherit;
            color: @text_color;
            padding: 12px;
			border-radius: 5px;

            .darkBg & {
                color: #fff;
            }

            &.pageButtonDisabled {
                opacity: 0.64;
                pointer-events: none;
            }

            @media @mobile {
                min-height: 48px !important;
                min-width: 48px !important;
            }

            i, &:after, &:before {
                width: auto;
                height: auto;
                font-size: inherit;
                line-height: inherit;
                font-weight: 400 !important;
                display: flex;
                align-items: center;
                justify-content: center;
                color: @text_color !important;
				background: transparent;
                transition: all 200ms linear;

                .darkBg & {
                    color: #fff !important;
                }
            }

            &:hover, &:active {
                opacity: 1;
                background: transparent;
				color: #fff !important;
				background: @highlight_color;
				transition: all 200ms linear;

                i, &:after, &:before {
                    color: @secondary_color !important;
                    transition: all 200ms linear;

                    .darkBg & {
                        color: @secondary_color !important;
                        transition: all 200ms linear;
                    }
                }

                .darkBg & {
                    color: @secondary_color;
                }
            }


        }


        &.active {

            a {

				color: #fff !important;
				background: @highlight_color;

                &, &:hover, &:active {
					color: #fff !important;
					background: @highlight_color;
                }

            }
        }


    }
}


