.bundleItemsWrapper {
	padding: 0;
}

.bundleCreator {
    background: @light_color;
    padding: 120px 0;

    @media @flyout {
        padding: 80px 0;
    }

    @media @mobile {
        padding: 56px 0;
    }

	.itemsBrowse.landingItems {
		
		.innerItem {
			
			.itemImageWrap {
				display: none;
			}
			
			.innerTextBox {
				padding-top: 0;
			}
			
			.productDescription {
				padding: 16px 0 0;
			}
			
			.bundleButtons {
				padding: 16px 0 0;
			}
			
			.itemHeader {
				padding: 0;
				min-height: 0;
			}
			
			.itemFooter {
				
				.itemPriceWrap {
					width: 76px;
					font-weight: 600;
				}
				
				.itemLinkWrap {
					width: ~"calc(100% - 76px)";
				}
				
				.bundleButtons {
					.button {
						padding: 18px 16px;
					}
				}
				
			}
			
		}
		
	}

    .bundleLists {

        position: relative;

        .wrapper {
            position: relative;
        }

        &.bundleItems {
            padding: 0;
            margin: 0;

        }

		.mobileFilterToggle {
			display: none;
		}

        &.bundleItemsSelected {
            position: fixed;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            width: 100%;
            height: 100vh;
            z-index: 999999;
            padding: 0;
            background: rgba(255, 255, 255, 0.88);
            backdrop-filter: blur(10px);

            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            transition: opacity 300ms linear, visibility 300ms linear;

            &.showOverlay {
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
                transition: opacity 300ms linear, visibility 300ms linear;
            }

            & > .container {
                padding: 0;
                width: 100%;
                height: 100%;
                position: relative;

                & > .wrapper {
                    position: relative;
                }
            }
			

            .innerBunderWrapper {
                height: 100%;
                overflow-y: auto;
            }

            .bundlesContain {
                padding: 64px 0 80px;
                min-height: 100%;
            }

            .closeButton {
                width: 48px;
                height: 48px;
                background: @secondary_color;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                cursor: pointer;
                opacity: 0.8;
                transition: all 200ms linear;
                position: absolute;
                top: 24px;
                right: 24px;
                padding: 0;
                border: none;
                margin: 0;
				z-index: 999999;


                &:hover, &:active {
                    background: darken(@secondary_color, 8%);
                }



                i {
                    width: 48px;
                    height: 48px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    .rems(28);
                    line-height: 28px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0;

                    &:before {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        width: 48px;
                        height: 48px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                    }
                }
            }
			
			.filterContainer {
				
				display: flex;

				@media all and (max-width: 750px) {
					flex-wrap: wrap;
				}
				
				.filterSidebar {
					flex: 0 0 200px;
					padding-right: 24px;
					border-right: 1px solid rgba(0,0,0,0.08);

					@media all and (max-width: 750px) {
						flex: 0 0 100%;
						padding-right: 0;
						border-right: 0;
						padding-bottom: 24px;
						margin-bottom: 32px;
						border-bottom: 1px solid rgba(0,0,0,0.08);
					}

					& + .filterContent {
						padding-left: 24px;

						@media all and (max-width: 750px) {
							padding-left: 0;
						}
					}
					
					.mobileFilterToggle {
						display: none;
						width: 100%;
						margin: 0;

						@media all and (max-width: 750px) {
							display: block;
							margin: 0;
						}
						
						& + .filterList {

							@media all and (max-width: 750px) {
								margin-top: 32px;
							}
							
						}
					}
					
				}
				
				.filterContent {
					flex: 1 1;
					flex-basis: ~"calc(100% - 200px)";

					@media all and (max-width: 750px) {
						flex: 0 0 100%;
					}
				}
				
				
				.filterContent {
					
					.itemsBrowse.landingItems {
						width: ~"calc(100% + 16px)";
						margin: 0 -8px -32px -8px;
						
						& > li {
							width: 25%;
							padding: 0 8px;
							
							@media all and (max-width: 1300px) {
								width: 33.333333%;
							}

							@media all and (max-width: 1000px) {
								width: 50%;
							}

							@media all and (max-width: 530px) {
								width: 100%;
							}
							
						}
					}
					
				}
			}
			
			.filterList {
				
				& > li {
					
					display: flex;
					padding: 0 0 16px;
					
					
					.iconWrap {
						width: 20px;
						height: 20px;
						position: relative;
						
						img, svg {
							width: 20px;
							height: 20px;
							position: absolute;
							top: 50%;
							left: 0;
							transform: translateY(-50%);
						}
					}
					
					button {
						background: transparent;
						flex: 1 0;
						flex-basis: ~"calc(100% - 24px)";
						padding-left: 12px;
						.rems(14);
						line-height: 20px;
						text-align: left;
						font-weight: 400;
						cursor: pointer;
						color: @text_color;
						transition: color 200ms linear;
						
						&:hover, &:focus {
							color: @secondary_color;
							transition: color 200ms linear;
						}
						
					}

					&.active {
						
						button {
							color: @secondary_color;
							font-weight: 600;
							transition: color 200ms linear;
						}
					}

					&.resetFilters {
						padding: 0 0 12px;
						margin: 0 0 16px;
						border-bottom: 1px solid rgba(0,0,0,0.08);
						
						button {
							padding-left: 0;
							width: 100%;
						}
					}
				}
				
			}
			
			.introBlock {
				@media all and (max-width: 750px) {
					margin-bottom: 8px;
				}
			}
			
			.filterButton {
				position: relative;
				padding-right: 24px;
				
				i {
					.rems(12);
					line-height: 12px;
					width: 12px;
					height: 12px;
					position: absolute;
					top: 50%;
					right: 0;
					transform: translate(-50%,-50%);
				}
			}
        }

    }

    .emptyMessage {
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        padding: 80px 0;

        @media @flyout {
            padding: 80px 0;
        }

        @media @mobile {
            padding: 40px 0;
        }

        p {
            margin: 0;
            width: 100%;
            .rems(18);
            line-height: 28px;
            color: fade(@text_color, 32%);
            font-weight: bold;
        }
    }
}
